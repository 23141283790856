import {FC} from "react"
import {Col, Row} from "react-bootstrap"
import { GrClose } from "react-icons/gr"


//@ts-ignore
import { db } from "../firebase1.ts";
import { getFunctions, httpsCallable } from "firebase/functions";
//@ts-ignore
import { app } from "../firebase1.ts";

import {
  collection,
  DocumentData,
  getDocs,
  query,
  doc,
  updateDoc,
} from "firebase/firestore";


export default function Checklist ({
    // @ts-ignore
    tasks,
    // @ts-ignore
    markTaskAsDone,
    // @ts-ignore
    admin,
    // @ts-ignore
    deleteTask,
   
}) {


    return (
        <>
          {tasks.length > 0 && (
            <div className="space-y-4 max-w-xl mx-auto">
              {tasks.map((task) => (
                <label // Make this a label to be able to click on it
                  key={task.task}
                  htmlFor={`task${task.task}`} // Associate label with the input checkbox
                  className={`p-4 rounded-lg transition-colors duration-200 ease-in ${
                    task.done ? "bg-gray-200" : "bg-white hover:bg-gray-50"
                  } shadow-md flex justify-between items-center cursor-pointer`} // Add cursor-pointer to indicate it's clickable
                >
                  <div className="flex items-center space-x-4">
                    <div className="relative">
                      <input
                        type="checkbox"
                        id={`task${task.task}`}
                        checked={task.done}
                        onChange={() => markTaskAsDone(task)}
                      />
                    </div>
                    <span
                      className={`${
                        task.done ? "line-through text-gray-500" : ""
                      }`}
                    >
                      {task.task}
                    </span>
                    {task.category && (
                      <span className="text-sm text-gray-500 italic">
                        {task.category}
                      </span>
                    )}
                  </div>
                  {admin && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent label click when deleting
                        deleteTask(task);
                      }}
                      type="button" // Specify button type to avoid any form submission
                      className="text-gray-800 hover:text-red-600 transition-colors duration-200 ease-in p-2"
                    >
                      <GrClose />
                    </button>
                  )}
                </label>
              ))}
            </div>
          )}
        </>
      );
}

 