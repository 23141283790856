//@ts-nocheck
import { Row, Col, Nav, Navbar } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import logo from "../img/LogoUtenTekst.png";
import { GiCheckMark } from "react-icons/gi";
import { TbTruckDelivery } from "react-icons/tb";

import { collection, DocumentData, getDocs, query } from "firebase/firestore";

import { db } from "../firebase1.ts";



const NavBar = () => {
const [flagDescription, setFlagDescription] = useState([]);
const [flagPromocode, setFlagPromocode] = useState([]);

const fetchData = async () => {
  const q = query(collection(db, "dynamicPages"));
  try {
    const docSnapshot = await getDocs(q);
    docSnapshot.forEach((doc) => {
      if (doc.id === "newsbanner") {
        const data = doc.data();
        console.log("Active: ",data.active)
        if(data.active === true){
          setFlagDescription(data.description);
          setFlagPromocode(data.promocode);
        }
      }
    });
  } catch (e) {
    console.log(e);
  }
};


  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      
      <div style={{ minHeight: "28px" }} className="bg-[#534439] text-white py-1 grid text-sm items-end align-right content-end lg:pr-7 lg:pl-6 font-josefin  lg:pt-0.5">
              <>
                  
                    <Row className="flex justify-content-center">
                      <Col lg={3} className="d-none d-lg-block">
                        <div className="flex">
                          <GiCheckMark className="mr-1.5" /> Lei festutstyr over
                          hele Norge
                        </div>
                      </Col>
                      <Col
                        lg={6}
                        className="lg:mx-0 mx-auto text-center"
                      >
                        <div className="lg:flex font-josefin">
                          <div className="text-white font-medium tracking-wider px-2">
                            {flagDescription}{" "}
                          </div>
                          <div className="text-white text-lg font-extrabold tracking-wider pl-2 lg:mr-0 mr-4">
                            {flagPromocode}
                          </div>
                        </div>
                      </Col>
                      <Col lg={{ span: 3 }} className="d-none d-lg-block">
                        {/* <div className="flex">
                          <TbTruckDelivery className="mr-1.5 mt-0.5" /> Gratis
                          levering i Oslo og Omegn
                        </div> */}
                      </Col>
                    </Row>
                 
              </>
          <div className="text-white font-medium tracking-wider"></div>
        </div>
      <Navbar
        collapseOnSelect
        expand="xl"
        bg="dark"
        variant="dark"
        className="px-2 w-100 gap-3 px-4"
      >
       <Navbar.Brand href={"/"}>
    {window.innerWidth < 960 ? (
        <Row>
            <Col>
                <img
                    alt="logo_mobil"
                    className="h-16 -mt-3 -mb-3 -ml-5 w-20 transform transition-transform duration-300 hover:scale-105"
                    src={logo}
                />
            </Col>
            <Col className="g-0 -ml-8">
                <div className="font-bold text-md -mt-1.5 font-josefin transform transition-transform duration-300 hover:-translate-y-1">
                    PartyLyd.no
                </div>
                <div className="font-normal text-sm font-josefin">
                    Lyd og Lysutleie
                </div>
            </Col>
        </Row>
    ) : (
        <div>
            <Row>
                <Col>
                    <img
                        alt="logo_mobil"
                        className="h-16 -mt-2.5 -mb-3 -ml-4 w-20 transform transition-transform duration-300 hover:scale-105"
                        src={logo}
                    />
                </Col>
                <Col className="g-0 -ml-4">
                    <div className="font-bold text-md -mt-1.5 font-josefin transform transition-transform duration-300 hover:-translate-y-1">
                        PartyLyd.no
                    </div>
                    <div className="font-normal text-sm font-josefin">
                        Lyd og Lysutleie
                    </div>
                </Col>
            </Row>
        </div>
    )}
</Navbar.Brand>
        {window.innerWidth < 960 ? (
          <Nav.Link
            className="bg-[#e57e22] text-white font-josefin rounded-md px-2 h-10 pt-2 w-29 -ml-1"
            href={"/finn-min-bestilling"}
            style={{ fontWeight: 400 }}
          >
            Min side
          </Nav.Link>
        ) : null}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto justify-content-end flex-grow-1 pe-4 gap-3 px-3 font-josefin">
            <Nav.Link href={"/pakker"}>Våre pakker </Nav.Link>
            <Nav.Link href={"/finn-riktig-pakke"}>Finn riktig pakke </Nav.Link>
            <Nav.Link href={"/hvor-finner-du-oss"}>Her finner du oss</Nav.Link>
            <Nav.Link href={"/leiebetingelser"}>Leiebetingelser</Nav.Link>
            <Nav.Link href={"/faq"}>FAQ</Nav.Link>
            <Nav.Link href={"/kontakt"}>Kontakt oss</Nav.Link>
            {window.innerWidth > 700 ? (
              <Nav.Link
                className="bg-[#e57e22] text-white rounded-md px-3 h-10 font-josefin pt-2 float-right"
                href={"/finn-min-bestilling"}
                style={{ fontWeight: 500 }}
              >
                Min side
              </Nav.Link>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
