// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Breadcrumb,
  Container,
  Form,
  Card,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  getFunctions,
  httpsCallable
} from "firebase/functions";
import {
  doc,
  getDoc,
  addDoc,
  collection
} from "firebase/firestore";
import DatePicker, { registerLocale } from "react-datepicker";
import { useParams, useNavigate } from "react-router-dom";
import { addDays } from "date-fns"; 
import nb from "date-fns/locale/nb";

import AddOnsForPackage from "../components/AddOnsForPackage.tsx";
import ModalComp from "../components/ModalComp.tsx";

import "react-datepicker/dist/react-datepicker.css";

// Firebase setup
import { app, db } from "../firebase1.ts";

const SupplierSale = () => {
  let { supplierId, categoryName, idPackage } = useParams();
  const category = categoryName || "";
  const supplier = supplierId || "";
  const id = idPackage || "";

  const navigate = useNavigate();

  // Norwegian date format
  registerLocale("nb", nb);

  // Functions for firebase
  const functions = getFunctions(app, 'europe-west1');
  const verifyPhoneNumber = httpsCallable(functions, "verifyNumber");
  const alertSupplierSale = httpsCallable(functions, "alertSupplierSale"); 
  // Fetch data from firebase
  const [supplierData, setSupplierData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  // Data about package
  const [titlePackage, setTitlePackage] = useState("");
  const [startPrice, setStartPrice] = useState(0);
  const [extraDayPrice, setExtraDayPrice] = useState(0);
  const [weekendDayPrice, setWeekendDayPrice] = useState(0);
  const [holidayPrice, setHolidayDayPrice] = useState(0);
  const [holidays, setHolidays] = useState<string[]>([]);
  const [descriptionPackage, setDescriptionPackage] = useState("");
  const [imagePackage, setImagePackage] = useState([]);
  const [addonEquiptmentPackage, setAddonEquiptmentPackage] = useState([]);
  const [fullAddonEquiptmentPackage, setFullAddonEquiptmentPackage] = useState(
    []
  );

  // Data about supplier
  const [supplierName, setSupplierName] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  const [supplierPhone, setSupplierPhone] = useState("");
  const [supplierZip, setSupplierZip] = useState("");
  const [supplierCity, setSupplierCity] = useState("");

  // Data about order
  const [totalPrice, setTotalPrice] = useState(0);
  const [extraCostPerDay, setExtraCostPerDay] = useState(0);
  const [extraPriceForAddons, setExtraPriceForAddons] = useState(0);
  const [phonenumber, setPhonenumber] = useState();
  var number = new RegExp(/^[0-9]{8}$/i);
  // Datepicker - dato håndtering
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [daysBetween, setDaysBetween] = useState(0);
  const [areDateRangeFilled, setAreDateRangeFilled] = useState(false);
  // Approval of terms when ordering
  const [terms, setTerms] = useState(false);
  const [termsNotChecked, setTermsNotChecked] = useState(false);

  // Verify phonenumber
  const [verifyInputCode, setVerifyInputCode] = useState(0);
  const [verifyCode, setVerifyCode] = useState(0);
  const [areFieldsFilledVerifyCode, setAreFieldsFilledVerifyCode] =
    useState("disabled");

  // Modaler
  const [showCompleteOrderModal, setShowCompleteOrderModal] = useState(false);
  const [validatePhonenumberModal, setValidatePhonenumberModal] =
    useState(false);
  const [validatePhonenumberFalseModal, setValidatePhonenumberFalseModal] =
    useState(false);


  // compute uniqueLocations after categoryData is defined
  let uniqueLocations = [];
  if (categoryData) {
    uniqueLocations = [...new Set(categoryData.map((pkg) => pkg.location))];
  }

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  const dateMatches = (date1: Date, date2: Date): boolean => {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
};

const isHoliday = (date: Date) => {
    return holidays.some(holiday => dateMatches(date, new Date(holiday)));
};

const isWeekend = (date: Date) => {
  const day = date.getDay();
  return day === 5 || day === 6; 
};

  const fetchSuppliers = async () => {
    const suppliersList = [];
    const docRef = doc(db, "suppliers", supplier);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let data = docSnap.data();
        if (data.packages) {
          const relevantPackage = data.packages.find(
            (pck) =>
              pck.kategori === categoryName && String(pck.id) === String(id)
          );

          if (relevantPackage) {
            setTitlePackage(relevantPackage.navn);
            setStartPrice(parseInt(relevantPackage.startPris));
            setExtraDayPrice(parseInt(relevantPackage.dagsPris));
            setWeekendDayPrice(relevantPackage.helgeTillegg ? parseInt(relevantPackage.helgeTillegg) : 0);
            setHolidayDayPrice(relevantPackage.helligdagsPris ? parseInt(relevantPackage.helligdagsPris) : 0);
            if (Array.isArray(relevantPackage.helligdager)) {
              setHolidays(relevantPackage.helligdager);
          } else {
              setHolidays([]);
          }
            setDescriptionPackage(relevantPackage.beskrivelse);
            setImagePackage(relevantPackage.bilder);
            setAddonEquiptmentPackage(relevantPackage.tillegg);
          }

          // Set supplier details
          setSupplierName(data.name);
          setSupplierAddress(data.address);
          setSupplierPhone(data.phonenumber);
          setSupplierZip(data.zipcode);
          setSupplierCity(data.city);
        }
        suppliersList.push(data);
      }
      setSupplierData(suppliersList);
    } catch (e) {
      console.log(e);
    }
  };

  // Calculate extra cost per day
  function calcExtraPricePrDay(startDate: Date, endDate: Date) {
    const daysBetween = Math.round(
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    let extraCharge = 0;

    for (let i = 0; i < daysBetween; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + i);

        console.log("Weekend: "+ weekendDayPrice)
        console.log("Holiday: "+ holidayPrice)
        console.log("Holiday?? "+ isHoliday(currentDate))

        extraCharge += extraDayPrice;
        if (isHoliday(currentDate)) {
            extraCharge += holidayPrice;
        } else if (isWeekend(currentDate)) {
            extraCharge += weekendDayPrice;
        }
    }

    if (daysBetween === 1) {
      extraCharge -= extraDayPrice;
  }

    const totalExtraCost = extraCharge;
    console.log(totalExtraCost)

    setDaysBetween(daysBetween);
    setExtraCostPerDay(totalExtraCost);

    return totalExtraCost;
}

  function calculateTotalPrice() {
    const price = startPrice + extraCostPerDay + extraPriceForAddons;
    return setTotalPrice(price);
  }

  function calculateAddonPrice() {
    let addonPrice = 0;
    let dayPrice = 0;

    for (let key in fullAddonEquiptmentPackage) {
      const addon = fullAddonEquiptmentPackage[key];
      addonPrice += addon.startPris * addon.count;
      if (addon.count !== 0) {
        dayPrice += addon.dagsPris * daysBetween * addon.count;
      }
    }
    setExtraPriceForAddons(parseInt(addonPrice + dayPrice));
    return addonPrice;
  }

  function placeOrder() {
    // Add functionality of sending msg to supplier and customer
    if (!terms) {
      setTermsNotChecked(true);
    } else {
      setTermsNotChecked(false);
      verifyNumber(phonenumber);
      setShowCompleteOrderModal(false);
      setValidatePhonenumberModal(true);
    }
  }

  async function handleOrder(
    phonenumber: any,
    categoryName: any,
    startDate: any,
    endDate: any,
    supplierId: any
  ) {
    const rightFormatAddOns = Object.fromEntries(
      fullAddonEquiptmentPackage.map((a) => [a.navn, parseInt(a.count)])
    );

    let numberOfTotalDays = (endDate - startDate) / 86400000;
    let time = {
      startDate: startDate,
      endDate: endDate,
      totalDays: numberOfTotalDays,
      orderPlaced: new Date(),
    };

    await addDoc(collection(db, "supplierSales"), {
      tlf: "+47" + phonenumber,
      tid: time,
      supplierId: supplierId,
      kategori: categoryName,
      valgtTilleggsUtstyr: rightFormatAddOns,
      tilleggsPris: extraPriceForAddons,
      totalPris: totalPrice,
      betalingsStatus: "Ikke betalt",
      status: "Ikke bekreftet",
      kommentar: "",
    })
      .then(function (docRef) {

        let data = {
          client_reference_id: docRef.id
        }
        alertSupplierSale(data)

       navigate(`/order-leverandor/${docRef.id}`)
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  function checkVerifyCode() {
    if (verifyCode === verifyInputCode) {
      setValidatePhonenumberModal(false);
      handleOrder(phonenumber, categoryName, startDate, endDate, supplierId);
    } else {
      setValidatePhonenumberModal(false);
      setValidatePhonenumberFalseModal(true);
    }
  }

  function verifyNumber(number: string) {
    let phoneNumber = number;
    let data = {
      tlf: phoneNumber,
    };
    verifyPhoneNumber(data).then((response) => {
      // @ts-ignore
      setVerifyCode(response.data);
    });
  }

  const handleChange =
    (setter: (v: boolean) => void) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.checked);
    };

  // useEffect for order
  useEffect(() => {
    fetchSuppliers();
  }, []);

  useEffect(() => {
    calculateAddonPrice();
  }, [daysBetween]);

  useEffect(() => {
    if (startDate && !endDate) {
      setAreDateRangeFilled(true);
    } else {
      setAreDateRangeFilled(false);
    }
  }, [endDate, dateRange, startDate]);

  useEffect(() => {
    calculateTotalPrice();
  }, [extraCostPerDay, startPrice, extraPriceForAddons]);

  useEffect(() => {
    // Check if supplierData has been loaded
    if (supplierData.length > 0) {
      const fullAddons = supplierData[0].tillegg.filter((tillegg) =>
        addonEquiptmentPackage.includes(tillegg.navn)
      );
      setFullAddonEquiptmentPackage(fullAddons);
    }
  }, [addonEquiptmentPackage, supplierData]);

  useEffect(() => {
    if (verifyInputCode !== 0 && verifyInputCode.toString().length === 6) {
      setAreFieldsFilledVerifyCode("");
    } else {
      setAreFieldsFilledVerifyCode("disabled");
    }
  }, [verifyInputCode]);

  return (
    <div className="bg-[#EBECF0]">
      <div style={{ display: "block", width: 700, padding: 30 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="/leverandor">Alle kategorier</Breadcrumb.Item>
          <Breadcrumb.Item href={`/leverandor/${category}`}>
            {capitalize(category)}
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#">{titlePackage}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {supplierData.length > 0 ? (
        <Container>
          <Row>
            <Col lg={{ span: 6, offset: 1 }}>
              <Col>
                <div className="flex">
                  <div className="font-bold text-3xl tracking-wider pb-8 pl-4 lg:pl-0">
                    Leie {capitalize(titlePackage)}{" "}
                  </div>
                </div>
              </Col>
              <Col className="lg:px-0 px-2">
                <Card className="pt-4 mb-4 rounded-xl ">
                  <Card.Img
                    variant="top"
                    src={imagePackage[0]}
                    style={{
                      width: "90%",
                      height: "48vh",
                      objectFit: "cover",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRadius: "10px",
                    }}
                  />
                  <Card.Title className="px-6 pt-6">
                    <hr />
                  </Card.Title>
                  <Card.Body>
                    <Card.Text className="px-6 pt-1 pb-4">
                      {descriptionPackage}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              {fullAddonEquiptmentPackage && fullAddonEquiptmentPackage.length > 0 && (
  <Col className="lg:px-0 px-2">
    <div className="font-medium text-2xl tracking-wider pb-8 pt-6 pl-4 lg:pl-0">
      LEGG TIL TILLEGGSPRODUKTER
      <Row>
        <div className="pt-4 font-normal text-sm tracking-wider">
          Du kan også legge til mer utstyr på din side etter fullført bestilling.
        </div>
      </Row>
    </div>

    <div className="pb-8">
      <Row>
        <Col>
          <Card className="rounded-xl pt-2 px-1">
            {fullAddonEquiptmentPackage.map((products) => (
              <>
                {products.count === 0 ? (
                  <div key={products.id}>
                    <AddOnsForPackage
                      image={products.bilde}
                      desc={products.beskrivelse}
                      name={products.navn}
                      max={products.maks}
                      priceFrom={
                        parseInt(products.startPris) +
                        parseInt(products.dagsPris) * daysBetween
                      }
                      onChange={(e: any) => {
                        let value = parseInt(e.target.value);
                        products.count = value;
                        if (e.target.value === "Legg til") {
                          products.count = 0;
                        }
                        calculateAddonPrice();
                      }}
                    />
                  </div>
                ) : (
                  <div key={products.id}>
                    <AddOnsForPackage
                      image={products.bilde}
                      desc={products.beskrivelse}
                      name={products.navn}
                      max={products.maks}
                      priceFrom={
                        products.startPris * products.count +
                        products.dagsPris * daysBetween * products.count
                      }
                      onChange={(e: any) => {
                        let value = parseInt(e.target.value);
                        products.count = value;
                        if (e.target.value === "Legg til") {
                          products.count = 0;
                        }
                        calculateAddonPrice();
                      }}
                    />
                  </div>
                )}
              </>
            ))}
          </Card>
        </Col>
      </Row>
    </div>
  </Col>
)}
            </Col>

            <Col>
              <Row>
                <Col md={{ span: 5, offset: 1 }} xs={{ span: 6, offset: 1 }}>
                  <>
                    <div className="lg:pb-0 pb-2 font-medium text-2xl tracking-wider">
                      {" "}
                      Bestill nå
                    </div>
                  </>
                </Col>
                <Col md={{ span: 4, offset: 1 }} xs={{ span: 4, offset: 1 }}>
                  <>
                    <div className="lg:pb-0 pb-2 font-medium text-2xl tracking-wider">
                      {" "}
                      {totalPrice},-
                    </div>
                  </>
                </Col>
              </Row>
              <hr
                style={{
                  display: "block",
                  height: "2px",
                  border: "0",
                  borderTop: "1px solid #ccc",
                  width: "70%",
                  marginLeft: "3em",
                  marginTop: "15px",
                  padding: "0",
                }}
              />
              <Row>
                <Card
                  className={`text-black bg-white text-left w-8/12 ml-16 mt-3 shadow-md border-gray`}
                >
                  <Card.Body className="pt-2 pr-1 pl-1">
                    <div className="text-xs text-light pb-3 text-black">
                      LEIEPERIODE{" "}
                    </div>
                    <DatePicker
  selectsRange={true}
  locale="nb"
  dateFormat="dd/MM/yyyy"
  placeholderText="Trykk her for å velge utleieperiode"
  startDate={startDate}
  minDate={new Date()}
  endDate={endDate}
  className="form-control"
  onChange={(update) => {
    setDateRange(update);
    setExtraCostPerDay(0);
    if (update[1] !== null) {
      const start = update[0];
      const end = update[1];

      calcExtraPricePrDay(start, end);
    }
  }}
  isClearable={true}
/>
                  </Card.Body>
                </Card>
                <>
                  <Row className="w-11/12">
                    {areDateRangeFilled === true ? (
                      <div className="pl-16 pt-1 font-normal text-sm tracking-wider text-red-600">
                        Du må velge start- og sluttdato
                      </div>
                    ) : null}
                  </Row>
                </>
              </Row>
              <Row>
                <Card
                  className={`text-black bg-white text-left w-8/12 ml-16 mt-3 shadow-md border-gray`} //border-${inputStyleRentPeriode}
                >
                  <Card.Body className=" flex ">
                    <Button
                      variant="secondary"
                      className={`text-black bg-white text-left !border-none !text-left p-0 disabled`} //border-${inputStyleDelivery}
                    >
                      <div className="text-xs text-light pb-2 text-black ">
                        Utstyr må hentes hos {supplierName}
                      </div>
                      <strong>
                        {supplierZip} {supplierCity}{" "}
                      </strong>{" "}
                    </Button>
                  </Card.Body>
                </Card>
              </Row>
              <Row>
                <Card
                  className={`text-black bg-white text-left w-8/12 ml-16 mt-3 shadow-md border-gray`} //border-${inputStylePhone}
                >
                  <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                    <div className="text-xs text-light pb-3 text-black pl-2">
                      DITT TELEFONNUMMER{" "}
                    </div>
                    <input
                      className="w-100  pb-2 pl-2"
                      value={phonenumber}
                      onChange={(e) => setPhonenumber(e.target.value)}
                      placeholder="Skriv inn ditt telefonnummer"
                    ></input>
                  </Card.Body>
                </Card>
                {phonenumber && !number.test(phonenumber) ? (
                  <div className="ml-16 pl-2 pt-1 font-normal text-sm tracking-wider text-red-600">
                    Du må fylle inn telefonnummer, eksempel: <em>12345678</em>
                  </div>
                ) : null}
              </Row>
              <Row>
                <Button
                  variant="secondary"
                  className={`bg-[#24292e] text-white tracking-wider w-8/12 ml-16 mt-8 rounded-md pt-3 pb-3`}
                  onClick={() => setShowCompleteOrderModal(true)}
                >
                  NESTE
                </Button>
              </Row>
              <Row>
                <div className="ml-14 w-8/12 pt-4 font-normal text-sm tracking-wider pb-3">
                  Lurer du på noe? Sjekk ut vår FAQ-side! Har du andre spørsmål
                  om bestilling? Kontakt oss eller ta en prat med vår{" "}
                  <strong>chatbot</strong>.
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : null}

      {/** Modal for å fullføre bestilling. Velger om man ønsker forsikring eller ikke */}
      <ModalComp
        show={showCompleteOrderModal}
        closeModal={() => setShowCompleteOrderModal(false)}
        hide={() => setShowCompleteOrderModal(false)}
        title="Fullfør forespørsel"
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-center ml-16 mt-3"
            onClick={placeOrder}
          >
            SEND FORESPØRSEL
          </Button>
        }
        body={
          <>
            <div className="text-2xl tracking-wider pb-2 pt-2">Pris</div>
            <Card className="text-black bg-white text-left w-12/12 border-t-1 border-r-1 border-b-0 border-l-1 rounded-none">
              <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                <Row className="mr-4 ml-2">
                  <Col sm={10}>
                    <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                      Pris for {categoryName}
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                      {startPrice + extraCostPerDay},-
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {extraPriceForAddons !== 0 ? (
              <div>
                <Card className="text-black bg-white text-left w-12/12 border-t-1 border-r-1 border-b-0 border-l-1 rounded-none">
                  <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                    <Row className="mr-4 ml-2">
                      <Col sm={10}>
                        <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                          Tilleggspris
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                          {extraPriceForAddons},-
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            ) : null}
            <Card className="text-black bg-white text-left w-12/12 border-t-4 border-[#24292e] rounded-none">
              <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                <Row className="mr-4 ml-2">
                  <Col sm={10}>
                    <div className="text-md tracking-wider pb-2 pt-2 pl-2 text-black">
                      <div className=" font-bold pb-1 ">Totalt å betale</div>
                      <div className="text-xs">(Totalsum inkludert mva)</div>
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div className="text-md tracking-wider pb-2 pt-3 pl-2 text-black font-bold">
                      {totalPrice},-
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <div className="mt-3">
              <Form>
                <Form.Check
                  checked={terms}
                  onChange={handleChange(setTerms)}
                  type="checkbox"
                  id="default-checkbox"
                  label={
                    <div>
                      <span className="text-sm tracking-wider pb-2">
                        Ved å krysse av godtar du PartyLyd sine {""}
                        <a
                          href={"https://partylyd2022.web.app/leiebetingelser"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          Leiebetingelser
                        </a>
                        <span className="text-red">*</span>
                      </span>
                      {termsNotChecked === true ? (
                        <div className="pt-1 font-normal text-sm tracking-wider text-red-600">
                          For å legge inn en bestilling må du godta
                          Leiebetingelsene
                        </div>
                      ) : null}
                    </div>
                  }
                />
              </Form>
            </div>
          </>
        }
      />

      {/** Modal for å vertifisere mobilnummer før man fullfører bestilling */}
      <ModalComp
        show={validatePhonenumberModal}
        closeModal={() => setValidatePhonenumberModal(false)}
        title="Verifisering"
        hide={() => setValidatePhonenumberModal(false)}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16 mt-3"
            onClick={() => setValidatePhonenumberModal(false)}
          >
            Lukk
          </Button>
        }
        body={
          <>
            <InputGroup>
              <Container className="mt-6 mb-6">
                <Row>
                  <div className="text-md tracking-wider font-weight-400">
                    Vi sendte nettopp en kode til <strong>{phonenumber}</strong>
                    : (vent inntil 30 sek)
                  </div>
                  <Col
                    lg={{ span: 8, order: 0 }}
                    xs={{ span: 12 }}
                    className="mt-4"
                  >
                    <FormControl
                      placeholder="Kode (6 siffer)"
                      aria-describedby="verify-code"
                      onChange={(e) =>
                        setVerifyInputCode(Number(e.target.value))
                      }
                    />
                  </Col>
                  <Col
                    lg={{ span: 3, order: 0 }}
                    xs={{ span: 12 }}
                    className="mt-4"
                  >
                    <Button
                      className={`tracking-wide w-100 ${areFieldsFilledVerifyCode}`}
                      style={{
                        backgroundColor: "#e57e22",
                        borderColor: "#e57e22",
                        fontWeight: 400,
                      }}
                      onClick={checkVerifyCode}
                    >
                      Bekreft
                    </Button>
                  </Col>
                </Row>
              </Container>
            </InputGroup>
          </>
        }
      />

      {/** Modal hvis validering av nummer ikke var korrekt */}
      <ModalComp
        show={validatePhonenumberFalseModal}
        closeModal={() => setValidatePhonenumberFalseModal(false)}
        title="Ikke korrekt verifiseringskode"
        hide={() => setValidatePhonenumberFalseModal(false)}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16 mt-3"
            onClick={() => setValidatePhonenumberFalseModal(false)}
          >
            Lukk
          </Button>
        }
        body={
          <>
            Kode som ble skrevet inn stemte ikke overens med kode som ble sendt
            på SMS, vennligst prøv igjen.
          </>
        }
      />
    </div>
  );
};

export default SupplierSale;
