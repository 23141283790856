import { Row, Col, Container } from "react-bootstrap";

const RentalConditions = () => {
  return (
    <div>
      <Container>
        <Row className="justify-content-md-center mt-10 md:w-11/12 pb-10 font-josefin">
          <Col md={{ span: 7, offset: 1 }} className=" px-4">
            <div>
              <div className="font-bold text-3xl tracking-wider pb-4 ">
                Leiebetingelser{" "}
              </div>
              <div>
                <div className="font-normal text-xl tracking-wider pb-4">
                  A) PRIS, BETALING OG EKSTRA KOSTNADER
                </div>
                1. Alle våre priser er inkludert merverdiavgift med mindre annet
                er spesifisert. <br />
                <br />
                2. Leier kan pådra seg ekstra kostnader som er rimelige innenfor
                hendelsens natur, og fastsettes av PartyLyd dersom: Utstyr ikke
                leveres tilbake, eller leveres tilbake i merkbart dårligere
                stand enn før leieperioden. Leier ikke melder fra om skader som
                vedkommende burde hatt kjennskap til etter leieperioden.
                Tilbakelevering skjer senere enn avtalt. Leier ikke låser døren
                etter avhenting eller levering av utstyr. Andre klanderverdige
                forhold fra leiers side som medfører økonomisk tap for utleier.
                Ekstra kostnader faktureres eller betales til PartyLyd sitt
                Vippsnummer (808518). <br />
                <br />
                3. Bestillingen betales direkte med Vipps, Bankkort, eller innen
                10 dager etter leieperioden med faktura (kun for bedrifter). Ved tidlig reservasjon kan
                faktura ha forfall før leieperioden begynner. <br />
                <br />
                4. Betaling med faktura er forbeholdt bedrifter.
                Privatpersoner må betale ved bestilling. <br />
                <br />
                5. Alminnelige bestemmelser fra kjøpsloven som ikke er
                spesifisert i disse betingelsene gjelder ved leie hos PartyLyd.{" "}
                <br />
                <br />
                6. Leier plikter å betale for hele den bestilte perioden
                uavhengig om utstyret blir levert tilbake før perioden løper ut.
              </div>
              <div>
                <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                  B) UTLEVERING{" "}
                </div>{" "}
                1. Det er leiers ansvar å tilby tilstrekkelig med opplysninger
                for å muliggjøre levering. <br />
                <div className="italic pt-2">
                  1.2 Ansvar for utstyret blir overtatt av leier etter utstyret
                  blir plassert på avtalt sted, eller leier åpner døren til
                  selvbetjent lager. PartyLyd har ansvar for utstyret først
                  etter det er blitt avhentet av en representant fra PartyLyd,
                  eller utstyret blir avlevert på lager med låst dør.{" "}
                </div>
                <br />
                2. Leier har økonomisk ansvar for alt utstyr på selvbetjent
                lager fra døren låses opp til lageret er tomt for folk og døren
                er låst igjen. <br />
                <br />
                3. PartyLyd har ikke økonomisk ansvar for klanderverdige forhold
                forbundet med utleie eller levering. Leier kan kreve opp til
                full refusjon avhengig av omstendighetene.
              </div>
              <div>
                <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                  C) AVBESTILLING OG REKLAMASJON{" "}
                </div>{" "}
                1. Leier har rett på full refusjon dersom bestillingen
                avbestilles innen 5 dager før leieperioden. Leier plikter ikke å
                hente bestilt utstyr, og kan avbestille uten refusjon når som
                helst.
                <br />
                <br /> 2. Reklamasjoner må inneholde komplett informasjon om
                omstendighetene samt eventuell dokumentasjon. Den må sendes på
                mail.
                <br />
                <div className="italic pt-2">
                  2.2 Leier skal melde fra om feil og mangel så snart de
                  oppdages. Retten til å reklamasjon kan falle bort ved mistanke
                  om mangelen er forårsaket av leier. Derfor kan det være for
                  sent å melde fra etter endt leieperiode.
                </div>
              </div>
              <div>
                <div className="font-normal text-xl tracking-wider  pb-4 pt-6">
                  D) SELVBETJENT LAGER{" "}
                </div>{" "}
                1. Den ansvarlige leier har ansvar for bruk og eventuell misbruk
                av tilgang til lageret. Leier har ansvar for lageret inkludert
                alt inventar og økonomisk tap påført utleier fra enten leier
                eller en tredjepart dersom tilgang leier har fått blir benyttet
                for å gjøre dette mulig. <br />
                <br />
                2. Leier har ansvar for å gjøre seg kjent med og benytte
                relevant informasjon tilgjengelig på lageret for at utleien skal
                gå i orden. Hvis det er vesentlige uklarheter må utleier
                kontaktes for å kunne gjøre utleien mulig. <br />
                <br />
                3. Ved tilbakelevering skal leier følge instruksjonene for dette
                i henhold til utsendt informasjon. Ting må settes
                ryddig og rent på riktig plass. <br />
                <br />
                4. Leier godkjenner at lageret er kameraovervåket og at opptak
                blir lagret inntil 30 døgn. Dersom opptaket viser brudd på
                leiebetingelsene kan det lagres i rimelig tid. Opptak kan brukes
                som bevis ved eventuell juridisk konflikt. <br />
                <br />
                5. Økonomisk tap påført utleier på grunnlag av utstyr som
                tilbakeleveres senere enn avtalt erstattes av leier. <br />
                <br />
                6. Dersom skade ikke rapporteres innen et døgn etter leie kan
                utleier fakturere et ekstra gebyr på inntil 400kr. <br />
                <br />
                7. Leier kan hente når som helst og levere tilbake når som helst
                innenfor kalenderdagene som utstyret er leid. Ved sen tilbakelevering påløper ekstra leie samt administrasjonsgebyr på 400kr. 
                Dersom for sen tilbakelevering kommer i konflikt med påfølgende leie, så er leietaker økonomisk ansvarlig for tapet.
              </div>
              <div>
                <div className="font-normal text-xl tracking-wider  pb-4 pt-6">
                  E) FORSIKRING{" "}
                </div>
                1. PartyLyd tilbyr forsikring som dekker all risiko (med enkelte
                unntak) i hele utstyrslisten til leier ved kjøp. Egenandel
                gjelder per objekt. Leier må betale egenandel i tråd med punkt
                1.2. <br />
                <br />
                1.2 SATSER EGENANDEL <br />
                <br />
                <li>Skade inntil 500 kr - 0 kr </li>
                <li>Skade inntil 1000 kr - 300 kr</li>
                <li>Skade inntil 1500 kr - 500 kr </li>
                <li>Skade inntil 2500 kr - 800 kr</li>
                <li>Skade inntil 5000 kr - 1200 kr </li>
                <li>Skade over 5000 kr - 1500 kr </li>
                <br />
                2. Forsikringen gjelder kun på utstyr som er levert tilbake.
                Ikke kast eller la være å levere tilbake med mindre annet er
                avtalt. Ta kontakt ved tyveri. <br />
                <br />
                3. Dersom leier ikke kjøper forsikring plikter vedkommende å
                dekke PartyLyds fulle økonomiske tap ved skader og kan motta et
                arbeidsgebyr på 250kr. <br />
                <br />
                4. Forsikringen gjelder ikke ved grov uaktsomhet eller bevisst
                påførte skader fra leier.
              </div>
              <div>
                <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                  F) GENERELLE BESTEMMELSER{" "}
                </div>
                1. Det er ikke lov med fremleie av PartyLyd sitt utstyr. <br />
                <br />
                2. Leid utstyr er til enhver tid PartyLyds eiendom, og leier er
                ansvarlig for behandling av dette i løpet av leieperioden.{" "}
                <br />
                <br />
                3. Det leide utstyret skal ved tilbakelevering være i samme
                stand som ved henting/avlevering. All rengjøring, kveiling av
                kabler og tilbakestilling til samme stand som ved avhenting
                debiteres leier til Utleiers gjeldende timesatser. <br />
                <br />
                4. Leier skal gjøre alt vedkommende kan for å levere tilbake
                utstyr innen leieperioden er slutt. Leier må betale ekstra gebyr
                dersom utstyr blir levert tilbake for sent. <br />
                <br />
                5. PartyLyd har ikke noe ansvar for hvordan leier bruker
                utstyret som er leid. Leier skal opptre forsvarlig, i tråd med
                lover og svare til egen bruk. <br />
                <br />
                6. Både leier og utleier skal etter beste evne løse eventuelle
                problemer eller konflikter som skulle oppstå. <br />
                <br />
                7. Kunder som bestiller utstyr til redusert pris på bakgrunn av
                sponsoravtale forplikter seg til å følge opp sponsoravtalens
                fulle omfang. Dersom dette misligholdes er kunden nødt til å
                betale eventuelle avslag som har blitt gitt. <br />
                <br />
                8. PartyLyd kan når som helst endre priser, betingelser og
                gjeldende informasjon på PartyLyd.no. 
              </div>
              <div>
                <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                  G) PERSONVERNERKLÆRING{" "}
                </div>
                1. Kontaktinformasjon til PartyLyd AS <br />
                PartyLyd AS er behandlingsansvarlig for håndtering av dine
                personopplysninger. Har du spørsmål om behandlingen eller ønsker
                å komme i kontakt med oss for å utøve dine rettigheter, finner
                du kontaktinformasjonen vår nedenfor: PartyLyd AS Oscar Braatens
                vei 6 1742 Fjellhamar, Norge Organisasjonsnummer: 911978563
                E-post adresse: post@partylyd.no Mobil: 61 60 94 90 <br />
                <br />
                2. Sentrale begreper
                <br />
                Personopplysninger er informasjon som alene eller sammen med
                andre opplysninger kan brukes til å identifisere, lokalisere
                eller kontakte en person. Eksempler på personopplysninger er
                navn, telefonnummer og IP-adresse. Behandling av
                personopplysninger innebærer alle former for håndtering av
                personopplysninger som: innsamling, analyse, registrering og
                lagring. Behandlingsansvarlig er den som bestemmer formålet med
                behandlingen av personopplysninger og hvilke opplysninger som
                anmodes. Det er behandlingsansvarlig som er ansvarlig for at
                håndteringen av dine personopplysninger skjer ut i fra den
                gjeldende personopplysningsloven. <br />
                <br />
                3. PartyLyd AS er behandlingsansvarlig
                <br />
                PartyLyd AS er behandlingsansvarlig for håndteringen av dine
                personopplysninger. <br />
                <br />
                4. Hva slags informasjon er det vi samler inn?
                <br />
                Når du registrerer deg via et skjema, bestiller en tjeneste
                eller produkt, benytter deg av vår chat-funksjon eller besøker
                vår hjemmeside, kan du bli bedt om å gi fra deg informasjon.
                Avhengig av situasjonen kan vi muligens be om følgende
                personopplysninger: • Navn • E-postadresse • Telefonnummer •
                Hvilken virksomhet du jobber for <br />
                <br />
                5. Paragraf 6 f som alternativ til valgt samtykke via nettisde.{" "}
                <br />
                Samtykke kan trekkes Vi tar utgangspunkt i legitimt samtykke
                utifra Paragraf 6f i personvernloven. Hvis du har inngått et
                kundeforhold med oss tidligere, eller du er en ny kunde vil
                dette være en legitim grunn til å sende deg informasjon. Dette
                sidestilles med et samtykke i henhold til markedsføringsloven
                slik loven har vært i Norge i mange år. Du kan når som helst
                trekke dette samtykket om at vi skal behandle dine
                personopplysninger. Trekker du tilbake samtykket, vil vi ta bort
                de opplysningene vi har om deg. Merk at sletting av
                informasjonen hos oss kan påvirke leveransen av informasjon
                innenfor rammen for kunderelasjonen, samt påvirke pågående
                dialoger. <br />
                <br />
                6. Informasjon fra andre kilder <br />
                Når du samtykker til at vi behandler dine personopplysninger
                godkjenner du også at vi kan registrere andre opplysninger om
                deg som du har gitt oss ved en tidligere anledning. Basert på
                offentlig tilgjengelig informasjon kan vi også supplere dine
                registrerte opplysninger med bransje og ytterligere
                kontaktinformasjon. Hvis du er kunde hos oss kan vi også tilføye
                ytterligere kontaktinformasjon til dine opplysninger, som du har
                registrert hos oss via f.eks. telefon til kundeservice.
                Opplysninger som er nødvendige for de tjenester du skal bruke
                vil også bli lagret. <br />
                <br />
                7. Formålet med behandlingen av personopplysningene? <br />
                Informasjonen som du gir fra deg brukes til følgende formål:{" "}
                <br />
                • Salg- og markedsføringsaktiviteter i form av direkte e-post
                eller telefonkontakt
                <br />
                • Kundebehandling og informasjon om våre produkter <br />
                • For å få statistikk og opplysninger om brukeratferd for å
                forbedre både hjemmesiden og selve brukeropplevelsen <br />
                • Slik at vi kan gi deg en mer personlig opplevelse og levere
                produkter og annet innhold som interesserer deg Vi ber om dine
                personopplysninger for å: <br />
                • Svare på forespørsler <br />
                • Sende ønsket materiale, eller på andre måter kunne oppfylle
                våre forpliktelser mot at du sender inn dine opplysninger
                <br />
                • Opprette og opprettholde en salgsdialog <br />
                • Sende informasjon som kan være av interesse for deg <br />
                • Legge deg til i e-post-liste for nyheter og annet innhold{" "}
                <br />
                <br />
                8. Samtykke til e-postkorrespondanse, direkte markedsføring og
                videre kontakt <br />
                Når du samtykker til at vi behandler dine personopplysninger i
                overensstemmelse med de overnevnte formål, samtykker du til
                følgende: <br />
                • Vi behandler dine personopplysninger i henhold til denne
                personvernerklæringen <br />
                • Vi kan sende deg direkte markedsføring via e-post om våre
                produkter <br />
                • Vi kan kontakte deg via e-post eller telefon <br />
                • Abonnering på nyhetsbrev via epost. Du kan takke nei til
                ytterligere e-poster ved å følge linken som befinner seg nederst
                i våre e-poster, eller ved å kontakte oss direkte. <br />
                <br />
                9. Dine rettigheter iforhold til innsyn , sletting og eventuelt
                flytting <br />
                Du har rett til å få informasjon om hvilke opplysninger vi har
                om deg. Du kan også kreve at vi retter opp i feilaktige
                opplysninger eller sletter informasjonen din. Ønsker du å trekke
                tilbake ditt samtykke eller kreve å få oversikt over
                opplysninger, rettelse eller sletting, kontakter du oss på
                e-postadressen vi har angitt under avsnittet for
                kontaktinformasjon.
                <br /><br />
                Betingelsene er sist
                oppdatert 17.09.2023 og nye justeringer har ikke tilbakevirkende
                kraft.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RentalConditions;
