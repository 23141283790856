import React, { useState } from 'react'; // Destructure useState
import { Col, Container, Button } from "react-bootstrap";
import logo1 from "../img/LogoPL.png";
import { useNavigate } from "react-router-dom";
import {
  collection, addDoc
} from "firebase/firestore";
import { db } from "../firebase1.ts";
// @ts-ignore


const OrderComplete = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/finn-min-bestilling");
  };

  const handleSend = async () => {
    const selectedOption = document.querySelector('input[name="innsikt"]:checked');
    let answer = selectedOption ? selectedOption.value : '';
  
    // Hvis "Annet" er valgt, hent teksten fra inputfeltet
    if (answer === 'Annet') {
      answer = document.getElementById('annetInput').value;
    }
  
    // Lagre svaret i localStorage
    localStorage.setItem("innsiktSvar", answer);
  
    // Legg til svaret i Firestore
    const surveyCollection = collection(db, 'surveyResponses');
  
    try {
      await addDoc(surveyCollection, {
        answer: answer,
        timestamp: new Date()
      });
      console.log("Data lagret i Firestore");
    } catch (error) {
      console.error("Feil ved lagring i Firestore:", error);
    }
  
    // Skjul spørreskjemaet
    const surveyDiv = document.getElementById("surveyDiv");
    if (surveyDiv) {
      surveyDiv.style.display = "none";
    }
  };

  const [showButton, setShowButton] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showInput, setShowInput] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === 'Annet') {
      setShowInput(true);
      setShowButton(true);
    } else {
      setShowInput(false);
      setShowButton(false);
      handleSend(); // Only send when the option isn't "Annet"
    }
  }

  return (
    <div className="bg-[#f39c12] min-h-screen font-josefin">
      <Container>
        <Col lg={{ span: 6, offset: 4 }} xs={{ span: 12, offset: 1}}>
          <img src={logo1} className="h-80 pb-0 -mb-48 lg:pl-16" />
        </Col>

        <Col lg={{ span: 6, offset: 3 }} xs={{ span: 12}}>
          <div className="text-white g:pl-20 pt-36 font-bold text-5xl sm:text-6xl tracking-wide pb-8">
            Takk for din bestilling!
          </div>
        </Col>

        <Col lg={{ span: 8, offset: 2 }} xs={{ span: 12 }}>
          <div className="text-white font-medium text-lg lg:pl-20">
            Du vil straks motta en ordrebekreftelse på melding med informasjon
            om din bestilling.
          </div>
        </Col>
        <div id="surveyDiv" className="w-full p-8 col-span-1 justify-center justify-self-center mx-auto text-center text-md content-center italic font-montserrat">
    <h2 className="font-bold mb-4 text-3xl text-white">Hvor hørte du om oss?</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-4">
        {['Venner / Bekjente', 'Søk i Google', 'Visuell annonse', 'Bestilt tidligere', 'Annet'].map(option => (
            <div 
                key={option}
                className={`form-check p-4 ${selectedOption === option ? 'bg-gray-300' : 'bg-white'} rounded cursor-pointer`}
                onClick={() => handleOptionClick(option)}
            >
                <input 
    type="radio" 
    name="innsikt" 
    value={option} 
    id={option} 
    className="form-check-input hidden" 
    onClick={() => handleOptionClick(option)}
    checked={selectedOption === option}
/>
                <label htmlFor={option} className="form-check-label font-black">{option}</label>
            </div>
        ))}
    </div>
    {selectedOption === 'Annet' && (
   <input type="text" id="annetInput" placeholder="Hvor da?" className="form-control mt-2 mb-4" />
)}
{showButton && (
   <button onClick={handleSend} className="btn p-3 text-white" style={{backgroundColor: "#212529", borderColor: "#212529", fontWeight: 600}}>Send svar</button>
)}
</div>
        <Col lg={{ span: 3, offset: 4 }} xs={{ span: 12 }}>
          <Button
            className="tracking-wide w-100 lg:ml-16 mt-16 h-14"
            style={{
              backgroundColor: "#212529",
              borderColor: "#212529",
              fontWeight: 600,
            }}
            onClick={handleClick}
          >
            FINN DIN BESTILLING
          </Button>
        </Col>
      </Container>
      <div className="w-full p-8 col-span-1 justify-center justify-self-center mx-auto font-bold text-center text-md content-center italic">
        Trenger du catering? Sjekk ut god mat i ditt område hos{" "}
        <div
          className="font-black text-white text-xl hover:underline cursor-pointer"
          onClick={() => {
            window.location.href = `https://kasserolle.no`;
          }}
        >
          {" "}
        kasserolle.no{" "}
        </div>
      </div>
    </div>
  );
};

export default OrderComplete;
