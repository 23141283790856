
// @ts-ignore
import { Key, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';



import { getFunctions, httpsCallable } from "firebase/functions";
// @ts-ignore
import Loading from "../components/Loading.tsx";
// @ts-ignore
import { app } from "../firebase1.ts";


const VippsCallbackPage = () => { 
    const functions = getFunctions(app);

    const cookies = new Cookies()

    const [retrievedUserInfo, setRetrievedUserInfo] = useState(false)

    const [userinfo, setUserInfo] = useState({
      name: null, 
      phone_number: null, 
      email: null, 
      birthdate: null, 
    })

    const retrieveUserInfo = async (code:any) => {

      const vippsOnCallCallbackFunction = httpsCallable(functions, "vippsOnCallCallback")
      const retrieveVippsUserInfo = httpsCallable(functions, 'retrieveVippsUserInfo')
      const vippsCallback = await vippsOnCallCallbackFunction({code: code, package_type:"packageId"})
        .then( (response)  => {
          let data = JSON.parse(response.data)
          return(data)

        })
        .catch((error) => {
          console.log("CALLBACK ERROR: ", error )
        })

      const userInfo = await retrieveVippsUserInfo({token: vippsCallback['access_token']})
      .then( (response) => {
        let data = JSON.parse(response.data)
        return data

      })
      .catch((error) => {
        console.log("USER INFO ERROR: ", error)
      })

      return userInfo

    }


  const handleRedirect = async () => {
    const url = new URL(window.location.href); 
    const authorizationCode = url.searchParams.get('code')
    const userInfo = await retrieveUserInfo(authorizationCode)

    setUserInfo({
      name: userInfo.name,
      phone_number: userInfo.phone_number, 
      email: userInfo.email, 
      birthdate: userInfo.birthdate
    })
  }

  function  setCookie(cname:string, cvalue:string, exdays:number) {
    
    cookies.set(cname, cvalue, {path:"/"})
  }

  const redirectBack = async () => {

    const redirectUrl = "https://partylyd.no/invoice/"+localStorage.getItem("orderId")

    localStorage.removeItem("orderId")

    window.location.href = redirectUrl 

    

  }

  useEffect(() => {
    handleRedirect()
  }, [])

  useEffect( () => {
    setCookie("userinfo", JSON.stringify(userinfo), 0.1)

    
    if (userinfo.name){
      redirectBack()
    }
  }, [userinfo])
  
  
  return( <>
  {console.log(cookies.get("FaMiHY77d97NFkMKIJ37"))}
  <Loading></Loading></>);
};




export default VippsCallbackPage