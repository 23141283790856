import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useEffect,
  } from "react";
  
  interface RoleContextProps {
    roles: string[];
    setRoles: (roles: string[]) => void;
  }
  
  const RoleContext = createContext<RoleContextProps>({
    roles: [],
    setRoles: () => {},
  });
  
  interface RoleProviderProps {
    children: ReactNode;
  }
  
  export const RoleProvider: React.FC<RoleProviderProps> = ({ children }) => {
    const [roles, setRoles] = useState<string[]>([]);
  
    useEffect(() => {
      const updateRoles = () => {
        const storedRoles = JSON.parse(localStorage.getItem("role") || "{}");
        const rolesArray = Object.values(storedRoles) as string[];
        setRoles(rolesArray);
      };
  
      // Run updateRoles initially on mount
      updateRoles();
  
      // Listen for storage events on the rest of the page
      window.addEventListener("storage", updateRoles);
  
      // Clean up by removing the listener when the component unmounts
      return () => window.removeEventListener("storage", updateRoles);
    }, []);
  
    return (
      <RoleContext.Provider value={{ roles, setRoles }}>
        {children}
      </RoleContext.Provider>
    );
  };
  
  export const useRoles = () => {
    return useContext(RoleContext);
  };
  