// @ts-nocheck
import { Col, Row, Container } from "react-bootstrap";
import Demand from "../components/Demand.tsx";
import { collection, DocumentData, getDocs, query } from "firebase/firestore";
import { SetStateAction, useEffect, useState } from "react";
import CreateSupplier from '../components/CreateSupplier.tsx';
import { db } from "../firebase1.ts";

const SuperAdmin = () => {
  const [data, setData] = useState<DocumentData[]>([]);

  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "demand"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      await setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="font-josefin">
      <Container className="py-2 h-30 lg:px-2">
      <CreateSupplier/>
      </Container>
    </div>
  );
};

export default SuperAdmin;
