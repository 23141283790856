// @ts-nocheck
import {
  collection,
  DocumentData,
  getDocs,
  getDoc,
  query,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { SetStateAction, useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container } from "react-bootstrap";
import ModalComp from "../components/ModalComp.tsx";
import { db } from "../firebase1.ts";

import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase1.ts";
import SupplierCard from "../components/SupplierCard.tsx";
import { useLocation } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import { BsMusicNote } from "react-icons/bs";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const AdminSupplier = () => {
  const supplierId = useState(localStorage.getItem("supplierIdPL") || "");

  const [data, setData] = useState<DocumentData[]>([]);

  const location = useLocation();

  const [sortedOrders, setSortedOrders] = useState<DocumentData[]>([]);

  const [hidden, setHidden] = useState("hidden");

  // Firebase functions
  const functions = getFunctions(app, 'europe-west1');
  const cancel = httpsCallable(functions, "cancelSupplierOrder");
  const sendConfimation = httpsCallable(functions, "sendSupplierConfirmation");
  const alertSupplierChat = httpsCallable(functions, "alertSupplierChat");

  // Actions on order
  const [reasonCancel, setReasonCancel] = useState("");
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [tasks, setTasks] = useState<Array<any>>([]);

  const [commentOrder, setCommentOrder] = useState("");
  const [commentOrderID, setCommentOrderID] = useState("");
  const [showComment, setShowComment] = useState(false);

  const [newExternalComment, setExternalNewComment] = useState("");
  const [showCommentToCustomer, setShowCommentToCustomer] = useState(false);
  const [comment, setComment] = useState([]);

  const [supplierName, setSupplierName] = useState("");

  const [orderCount, setOrderCount] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);

  // Internal comment for supplier
  const [commentInternOrderID, setCommentInternOrderID] = useState("");
  const [showCommentIntern, setShowCommentIntern] = useState(false);
  const [commentIntern, setCommentIntern] = useState("");
  const handleChangeCommentIntern = (e: any) => {
    setCommentIntern(e.target.value);
  };

  const handleExternalChangeComment = (e: any) => {
    setExternalNewComment(e.target.value);
  };

  const [
    disableConfirmOrderButtonComponent,
    setDisableConfirmOrderButtonComponent,
  ] = useState(false);

  const [idCanceledOrder, setIdCanceledOrder] = useState("");
  const [showCancel, setShowCancel] = useState(false);
  const [showCancelReason, setShowCancelReason] = useState(false);

  const handleChangeReason = (e: any) => {
    setReasonCancel(e.target.value);
  };

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  function handleCancelation() {
    setIsCancelLoading(true);
    let data = {
      client_reference_id: idCanceledOrder,
      reason: reasonCancel,
    };

    cancel(data)
      .then((response) => {
        setShowCancelReason(false);
        setShowCancel(false);
      })
      .finally(() => {
        window.location.reload();
      });
  }

  function sendConfirmationOnOrder(
    number: string,
    ordreId: string,
    callback: () => void
  ) {
    let phonenumber = number;
    let data = {
      client_reference_id: ordreId,
      phonenumber: phonenumber,
    };
    console.log(data);
    setHidden("");
    sendConfimation(data)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  }

  async function fetchSupplier() {
    let docRef = doc(db, "suppliers", supplierId[0]);
    try {
      const doc = await getDoc(docRef);
      if (doc.exists()) {
        let data = doc.data();
        setSupplierName(data.name);
      }
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  async function fetchData() {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "supplierSales"));
    try {
      const doc = await getDocs(q);

      doc.forEach((doc) => {
        const orderData = doc.data();

        if (orderData.supplierId === supplierId[0]) {
          list.push({ id: doc.id, ...orderData });
        }
      });

      const confirmedOrders = list.filter(
        (order) => order.status === "Bekreftet"
      );

      const earnings = confirmedOrders.reduce(
        (sum, order) => sum + order.totalPris,
        0
      );

      await setData([...list]);
      setOrderCount(confirmedOrders.length);
      console.log(earnings)
      setTotalEarnings(earnings);
    } catch (e) {
      console.log(e);
    }
  }

  const markTaskAsDone = async (taskToUpdate: Task) => {
    const docRef = doc(db, "suppliers", supplierId[0]);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const tasksObject = data.tasks || {};

      const tasksArray = Object.entries(tasksObject).map(([_id, task]) => task);

      const updatedTasksArray = tasksArray.map((task) =>
        task.task === taskToUpdate.task &&
        task.category === taskToUpdate.category
          ? { ...task, done: !task.done }
          : task
      );

      const newTasksObject = updatedTasksArray.reduce((obj, task, index) => {
        obj[index] = task;
        return obj;
      }, {});

      await updateDoc(docRef, { tasks: newTasksObject });

      setTasks(updatedTasksArray);
    }
  };

  const deleteTask = async (taskToDelete: Task) => {
    const docRef = doc(db, "suppliers", supplierId[0]);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const tasksObject = data.tasks || {};

      const tasksArray = Object.entries(tasksObject).map(([_id, task]) => task);

      const updatedTasksArray = tasksArray.filter(
        (task) =>
          task.task !== taskToDelete.task ||
          task.category !== taskToDelete.category
      );

      const newTasksObject = updatedTasksArray.reduce((obj, task, index) => {
        obj[index] = task;
        return obj;
      }, {});

      await updateDoc(docRef, { tasks: newTasksObject });

      setTasks(updatedTasksArray);
    }
  };

  async function addCommentToCostumerToOrder(orderId: string) {
    let comments = {
      kommentar: newExternalComment,
      tidspunkt: new Date(),
      kommentator: "leverandor",
    };

    console.log(orderId)

    let data = {
      client_reference_id: orderId,
      message: comments.kommentar,
      sentBy: comments.kommentator,
    };

    const orderUpdate = doc(db, "supplierSales", orderId);

    await updateDoc(orderUpdate, {
      kommentarKunde: arrayUnion(comments),
    })
      .then(async (e) => {
        

        let updatedOrder = await getDoc(orderUpdate)
        let updatedComments = updatedOrder.data().kommentarKunde

        setComment(updatedComments)
        setExternalNewComment("")

        alertSupplierChat(data)

        console.log(e);
      })
      .catch((e) => {
        console.log("Error getting document:", e);
      });
  }

  async function changeCommentIntern(comment: string, orderId: string) {
    const updateObject = {
      [`kommentarLeverandor`]: comment,
    };
    const docRef = doc(db, "supplierSales", orderId);

    updateDoc(docRef, updateObject)
      .then((e) => {
        setShowCommentIntern(false);
        window.location.reload();
      })
      .catch((e) => {
        handleShowFailed();
      });
  }

  async function changeCommentDB(kommentar: string, orderId: string) {
    const updateObject = {
      [`commentInternal`]: kommentar,
    };
    const orderUpdate = doc(db, "supplierSales", orderId);

    updateDoc(orderUpdate, updateObject)
      .then((e) => {
        setShowComment(false);
        window.location.reload(false);
      })
      .catch((e) => {
        handleShowFailed();
      });
  }

  useEffect(() => {
    const fetchTasks = async () => {
      const docRef = doc(db, "suppliers", supplierId[0]);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const tasksArray = Object.values(docSnap.data()?.tasks || {});
        setTasks(tasksArray);
      }
    };

    fetchTasks();
  }, [supplierId[0]]);

  useEffect(() => {
    fetchData();
    fetchSupplier();
  }, []);

  useEffect(() => {
    setSortedOrders(
      [...data].sort(
        (a, b) => a.tid.startDate.seconds - b.tid.startDate.seconds
      )
    );
  }, [data]);

  useEffect(() => {
    if (supplierId === "") {
      window.location.href = "/logg-inn";
    }
  }, [supplierId]);

  useEffect(() => {
    if (sortedOrders) {
      console.log(sortedOrders);
    }
  }, [sortedOrders]);

  const sortedTasks = [...tasks].sort(
    (a, b) => Number(a.done) - Number(b.done)
  );

  return (
    <div className="mb-10  font-josefin">
      <Container>
        <Row>
          <div className="font-medium text-3xl tracking-wider pt-10 lg:pl-20 lg:ml-0">
            Hei {supplierName}! <div className="text-lg text-blue-500">La oss hjelpe deg å leie ut tingene dine</div>
          </div>
          <Row className="mt-8 mb-8 lg:pl-20 lg:ml-0">
            {/* Antall bestillinger */}
            <Col md={{ span: 4 }} className="g-4">
              <div className="font-medium text-xl tracking-wider pb-2 text-gray-700">
                Bestillinger
              </div>
              <div className=" mx-auto text-blue-500 font-bold text-4xl mb-2">
                {orderCount || 0}
              </div>
            </Col>

            {/* Total inntjening */}
            <Col md={{ span: 4 }} className="g-4 lg:-ml-10">
              <div className="font-medium text-xl tracking-wider pb-4 text-gray-700">
                Total Inntjening
              </div>
              <div className="bg-gray-200 rounded-full h-8 relative mr-10">
                <div
                  className="bg-blue-500 text-white text-center py-2 absolute font-bold text-xl rounded-2xl"
                  style={{
                    width: "70%",
                    left: "0%",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  {totalEarnings || 0} kr
                </div>
              </div>
            </Col>
            {/* Snittverdi per bestilling */}
            <Col md={{ span: 4 }} className="g-4 lg:ml-10">
              <div className="font-medium text-xl tracking-wider pb-3 text-gray-700">
                Snittverdi per bestilling
              </div>
              <div className="text-blue-500 font-bold text-4xl mb-2">
                {Number(totalEarnings) !== 0 ? (Number(totalEarnings) / Number(orderCount)).toFixed(2)+" kr" : "0.00 kr"}
              </div>
            </Col>
          </Row>

          <Col>
            <div className="font-medium text-3xl tracking-wider pb-2 pt-10 lg:pl-20 lg:ml-0">
              Bestillinger
            </div>
            <Row className="justify-content-md-center">
              {sortedOrders.map((item) => {
                let today = new Date();
                return item ? (
                  <>
                    {item.tid.endDate.seconds >= today.getTime() / 1000 ? (
                      <Col md={{ span: 5 }} className="g-4 ">
                        <SupplierCard
                          startDate={capitalize(
                            new Date(
                              item.tid.startDate.seconds * 1000
                            ).toLocaleString(`no-NO`, options)
                          )}
                          endDate={capitalize(
                            new Date(
                              item.tid.endDate.seconds * 1000
                            ).toLocaleString(`no-NO`, options)
                          )}
                          packageType={capitalize(item.kategori)}
                          orderIdLink={item.id}
                          status={item.status}
                          orderId={item.id.slice(0, 6)}
                          phonenumber={item.tlf.slice(3, 11)}
                          totalPrice={item.totalPris}
                          visibilietyComment={
                            item.kommentarKunde ? "" : "hidden"
                          }
                          visibilietyNoComment={
                            item.kommentarKunde ? "hidden" : ""
                          }
                          hidden={hidden}
                          onClickCancelOrder={() => {
                            setIdCanceledOrder(item.id);
                            setShowCancel(true);
                          }}
                          disableConfirmOrderButton={
                            disableConfirmOrderButtonComponent
                          }
                          onClickConfirmOrder={() => {
                            sendConfirmationOnOrder(item.tlf, item.id, () =>
                              setIsLoading(false)
                            );
                          }}
                          onClickComment={() => {
                            setComment([]);
                            if (
                              item.kommentarKunde &&
                              item.kommentarKunde.length > 0
                            ) {
                              const list: SetStateAction<DocumentData[]> = [];
                              item.kommentarKunde.forEach((comment) => {
                                list.push(comment);
                              });
                              setComment(list);
                            }
                            setCommentOrderID(item.id);
                            setShowCommentToCustomer(true);
                          }}
                          onClickCommentInternal={() => {
                            if (item.kommentarLeverandor) {
                              setCommentIntern(item.kommentarLeverandor);
                            }
                            setCommentInternOrderID(item.id);
                            setShowCommentIntern(true);
                          }}
                        />
                      </Col>
                    ) : null}
                  </>
                ) : null;
              })}
            </Row>

            <div className="font-medium text-xl tracking-wider pb-2 pt-12 lg:pl-28 pl-0">
              Tidligere bestillinger
            </div>
            <Row className="justify-content-md-center opacity-60">
              {sortedOrders.map((item) => {
                let today = new Date();

                return item ? (
                  <>
                    {item.tid.endDate.seconds < today.getTime() / 1000 ? (
                      <Col md={{ span: 5 }} className="g-4 ">
                        <SupplierCard
                          startDate={capitalize(
                            new Date(
                              item.tid.startDate.seconds * 1000
                            ).toLocaleString(`no-NO`, options)
                          )}
                          endDate={capitalize(
                            new Date(
                              item.tid.endDate.seconds * 1000
                            ).toLocaleString(`no-NO`, options)
                          )}
                          packageType={capitalize(item.kategori)}
                          orderIdLink={item.id}
                          status={item.status}
                          orderId={item.id.slice(0, 6)}
                          phonenumber={item.tlf.slice(3, 11)}
                          totalPrice={item.totalPris}
                          visibilietyComment={item.comment ? "" : "hidden"}
                          visibilietyNoComment={item.comment ? "hidden" : ""}
                          hidden={hidden}
                          done={true}
                        />
                      </Col>
                    ) : null}
                  </>
                ) : null;
              })}
            </Row>
          </Col>
          {tasks.length > 0 ? (
            <Col lg={4}>
              <div className="font-medium text-2xl tracking-wider pb-2 pt-10">
                Oppgaver
              </div>

              {Array.isArray(sortedTasks) &&
                sortedTasks.map((task, taskId) => (
                  <div
                    key={taskId}
                    className={`my-2.5 p-2 rounded-lg ${
                      task.done ? "bg-gray-200" : "bg-white"
                    } shadow-sm flex items-center`}
                  >
                    {/* Checkbox */}
                    <div className="w-6">
                      <input
                        type="checkbox"
                        id={`task${taskId}`}
                        checked={task.done}
                        onChange={() => markTaskAsDone(task)}
                      />
                      <label htmlFor={`task${taskId}`}></label>
                    </div>

                    {/* Task text */}
                    <span
                      className={`flex-grow ${
                        task.done ? "line-through text-gray-500" : ""
                      } ml-3`}
                    >
                      {task.task}
                    </span>

                    {/* Category */}
                    {task.category && (
                      <div className="text-sm text-gray-500 italic ml-4 mr-2">
                        Kategori: {task.category}
                      </div>
                    )}

                    {/* Close icon */}
                    <div
                      onClick={() => deleteTask(task)}
                      className="text-gray-800 cursor-pointer p-2"
                    >
                      <GrClose />
                    </div>
                  </div>
                ))}
            </Col>
          ) : null}
        </Row>
      </Container>
      {/* Modal for å kansellere bestilling*/}
      <ModalComp
        show={showCancel}
        hide={() => setShowCancel(false)}
        title="Er du sikker på at du vil kansellere ordren?"
        closeModal={() => setShowCancel(false)}
        body={
          <>
            <div className="mt-2 tracking-wide" style={{ color: "#6b7280" }}>
              Å kansellere en ordre kan ikke angres. Er du sikker på at du
              ønsker å kansellere orderen?
            </div>
            <Row lg={6} className="flex justify-center">
              <Button
                variant="primary"
                className="text-white bg-pl_orange hover:bg-darker_pl_orange border-none mt-3 px-4 py-2"
                style={{
                  fontWeight: 500,
                  borderRadius: "0.375rem",
                }}
                onClick={() => {
                  setShowCancelReason(true);
                  setShowCancel(false);
                }}
              >
                Ja
              </Button>
            </Row>

            <Row lg={6} className="flex justify-center">
              <Button
                variant="secondary"
                className="text-pl_orange hover:text-darker_pl_orange hover:bg-white hover:font-bold border-none mt-3 px-4 py-2"
                style={{
                  fontWeight: 500,
                  borderRadius: "0.375rem",
                }}
                onClick={() => setShowCancel(false)}
              >
                Avbryt
              </Button>
            </Row>
          </>
        }
      />

      {/* Modal for å skrive årsak til kansellering*/}
      <ModalComp
        show={showCancelReason}
        title="Hvorfor skal ordren kanselleres?"
        closeModal={() => setShowCancelReason(false)}
        hide={() => setShowCancelReason(false)}
        body={
          <>
            <div
              className="mt-2 text-sm tracking-wide"
              style={{ color: "#6b7280" }}
            >
              For å kansellere ordren må du skrive en kort begrunnelse. Dette
              er:
            </div>
            <Form>
              <Form.Group
                className="mt-2 mb-1"
                controlId="form.CancelReason"
                onChange={handleChangeReason}
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </Form.Group>
            </Form>
            <Row className="mt-2 mb-2 float-left">
              <Col>
                <Button
                  variant="primary"
                  className={`flex justify-center items-center text-white border-none mt-3 px-4 py-2 whitespace-nowrap w-40 h-10`} // Legg til flex utilities her
                  style={{
                    fontWeight: 500,
                    borderRadius: "0.375rem",
                    backgroundColor: isCancelLoading ? "#d56b0f" : "#e57e22",
                  }}
                  onClick={() => {
                    handleCancelation();
                    setReasonCancel();
                  }}
                  disabled={isCancelLoading}
                >
                  {isCancelLoading ? (
                    <BsMusicNote className="animate-bounce" />
                  ) : (
                    "Kanseller ordre"
                  )}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  className="text-pl_orange hover:text-darker_pl_orange hover:bg-white hover:font-bold border-none mt-3 px-4 py-2 -ml-4"
                  style={{
                    fontWeight: 500,
                    borderRadius: "0.375rem",
                  }}
                  onClick={() => setShowCancelReason(false)}
                >
                  Avbryt
                </Button>
              </Col>
            </Row>
          </>
        }
      />

      {/* Modal for å legge inn kommentar internt på ordre*/}
      <ModalComp
        show={showCommentIntern}
        closeModal={() => setShowCommentIntern(false)}
        title={`Internkommentar på ordre ${commentInternOrderID.slice(0, 6)}`}
        hide={() => setShowCommentIntern(false)}
        body={
          <>
            Se og endre kommentar på bestilling.{" "}
            <em className="font-bold">Ikke synlig for kunde.</em>
            <Form>
              <Form.Group
                className="mt-2 mb-1"
                controlId="form.CommentIntern"
                onChange={handleChangeCommentIntern}
              >
                <Form.Control
                  className="font-medium tracking-widers text-black"
                  value={`${commentIntern}`}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Form>
            <Row className="mt-2 mb-2 float-left">
              <Col>
                <Button
                  variant="primary"
                  className="text-white bg-pl_orange hover:bg-darker_pl_orange border-none mt-3 px-4 py-2 whitespace-nowrap"
                  style={{
                    fontWeight: 500,
                    borderRadius: "0.375rem",
                  }}
                  onClick={() => {
                    changeCommentIntern(commentIntern, commentInternOrderID);
                  }}
                >
                  Legg til kommentar
                </Button>
                <Button
                  variant="secondary"
                  className="text-pl_orange hover:text-darker_pl_orange hover:bg-white hover:font-bold border-none mt-3 px-4 py-2"
                  style={{
                    fontWeight: 500,
                    borderRadius: "0.375rem",
                  }}
                  onClick={() => setShowCommentIntern(false)}
                >
                  Avbryt
                </Button>
              </Col>
            </Row>
          </>
        }
      />

      {/* Modal for å legge inn kommentar til kunde på ordre*/}
      <ModalComp
        show={showCommentToCustomer}
        closeModal={() => setShowCommentToCustomer(false)}
        title={`Kundechat på ordre ${commentOrderID.slice(0, 6)}`}
        hide={() => setShowCommentToCustomer(false)}
        body={
          <>
            {comment.length > 0 ? (
              <div>
                {comment.map((comment) => {
                  return (
                    <>
                      {comment.comment !== "" ? (
                        <>
                          {comment.kommentator === "leverandor" ? (
                            <div className="w-8/12 float-right p-2 border-pl_orange text-white rounded-xl mb-2 bg-pl_orange">
                              {" "}
                              <div className="float-left">
                                {comment.kommentar}
                              </div>
                              <div className="text-sm float-right">
                                {new Date(
                                  comment.tidspunkt.seconds * 1000
                                ).toLocaleDateString(`no-NO`)}
                                ,{" "}
                                {new Date(
                                  comment.tidspunkt.seconds * 1000
                                ).toLocaleTimeString(`no-NO`, {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </div>
                            </div>
                          ) : (
                            <div className="p-2 border-black text-black border-2 rounded-xl mb-2 bg-white w-8/12 float-left">
                              {" "}
                              <div className="float-left font-bold">
                                {comment.kommentar}
                              </div>
                              <div className="text-sm float-right">
                                {new Date(
                                  comment.tidspunkt.seconds * 1000
                                ).toLocaleDateString(`no-NO`)}
                                ,{" "}
                                {new Date(
                                  comment.tidspunkt.seconds * 1000
                                ).toLocaleTimeString(`no-NO`, {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </div>
                            </div>
                          )}
                        </>
                      ) : null}
                    </>
                  );
                })}
              </div>
            ) : null}
            <Form>
              <Form.Group className="mt-2 mb-1" controlId="form.Comment">
                <Form.Control
                  className="font-medium tracking-widers text-black"
                  onChange={handleExternalChangeComment}
                  value={`${newExternalComment}`}
                  placeholder="Ny melding"
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Form>

            <Row className="mt-2 mb-2 float-left">
              <Col>
                <Button
                  variant="primary"
                  className="text-white bg-pl_orange hover:bg-darker_pl_orange border-none mt-3 px-4 py-2 whitespace-nowrap"
                  style={{
                    fontWeight: 500,
                    borderRadius: "0.375rem",
                  }}
                  onClick={() => {
                    addCommentToCostumerToOrder(commentOrderID);
                  }}
                >
                  Send melding
                </Button>
                <Button
                  variant="secondary"
                  className="text-pl_orange hover:text-darker_pl_orange hover:bg-white hover:font-bold border-none mt-3 px-4 py-2"
                  style={{
                    fontWeight: 500,
                    borderRadius: "0.375rem",
                  }}
                  onClick={() => setShowCommentToCustomer(false)}
                >
                  Avbryt
                </Button>
              </Col>
            </Row>
          </>
        }
      />
    </div>
  );
};

export default AdminSupplier;
