//@ts-nocheck
import { FC } from "react";
import React from "react";
import { Card, Col, Form, Row, Container } from "react-bootstrap";

interface AddExtraEquiptmentCardProps {
  name?: string;
  image?: string;
  desc?: string;
  count?: number;
  max?: number;
  priceFrom?: number;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddExtraEquiptmentCard: FC<AddExtraEquiptmentCardProps> = ({
  name = "",
  image = "",
  desc = "",
  count = 0,
  max = 1,  // Sett default verdi til 1 hvis max er udefinert
  priceFrom,
  onClick,
  onChange,
}) => {
  // Sjekk om count er NaN, og sett det til 0 hvis det er tilfellet
  const validCount = isNaN(count) ? 0 : count;

  // Dynamisk generering av antall objekter basert på max
  const objects = Array.from({ length: max }, (_, i) => i + 1);

  return (
    <div>
      <Container className="py-2 pb-0 h-30">
        <Row>
          <Col xs={3} md={2}>
            <img
              src={image}
              alt=""
              style={{
                width: "40vh",
                height: "10vh",
                objectFit: "contain",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Col>
          <Col xs={7} lg={8}>
            <div className="pt-2">
              <div className="font-semibold">{name}</div>
              <div className="font-light">
                {validCount === 0 ? (
                  <strong>fra {priceFrom},-</strong>
                ) : (
                  <strong>
                    + {priceFrom / validCount} x {validCount}
                  </strong>
                )}
              </div>
              <div className="font-normal	">{desc}</div>
            </div>
          </Col>
          <Col xs={2} lg={2}>
            <Form.Control
              as="select"
              aria-label="Legg til"
              onClick={onClick}
              onChange={onChange}
              className="mt-2 px-2 font-bold"
              style={{ backgroundColor: "#2289e5", color: "white" }}
            >
              <option value="">+ Velg</option>
              {objects.map((object) => (
                <option key={object} value={object}>
                  {object}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <hr
          className="float-right object-right mt-3"
          style={{
            height: "1px",
            borderTop: "2px solid #ccc",
            width: "83%",
            float: "right",
          }}
        ></hr>
      </Container>
    </div>
  );
};

export default AddExtraEquiptmentCard;