//@ts-nocheck
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ModalComp from "./ModalComp.tsx";
import {
  Col,
  Row,
  Container,
  Form,
  FormLabel,
  FormSelect,
} from "react-bootstrap";

import { MdOutlineWhereToVote } from "react-icons/md";

import {
  collection,
  DocumentData,
  getDocs,
  query,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase1.ts";

import SalesSupplierCard from "./SalesSupplierCard.tsx";


function CreateSupplier() {
    const [categories, setCategories] = useState(["soundboks", "popcorn", "slushmaskin", "hoppeslott", "partytelt", "sukkerspinnmaskin"]);

  const [formData, setFormData] = useState({
    address: "",
    city: "",
    name: "",
    contacts: [
      {
        alertEmail: true,
        alertSms: true,
        email: "",
        name: "",
        phone: "",
      },
    ],
    lat: 0,
    long: 0,
    messageQuota: 50,
    messageRules: [],
    packages: [],
    tasks: [
      {
        category: '',
        done: false,
        task: 'Verifiser prisreglene i "Mine pakker"'
    },
    {
      category: '',
      done: false,
      task: 'Opprett automatiske SMS-regler til kunder i "Meldingstjeneste"'
  },
  {
    category: '',
    done: false,
    task: 'Husk å bekrefte eller kansellere bestillinger innen 24 timer'
},
{
  category: '',
  done: false,
  task: 'Legg til dine egne oppgaver i "Sjekkliste"'
},
{
  category: '',
  done: false,
  task: 'Meld inn ønsker til funksjonalitet her ved å ta kontakt'
}
    ],
    tillegg: [],
    zipcode: "",
    categories: [],
  });

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...formData.contacts];
    updatedContacts[index][field] = value;
    setFormData((prev) => ({ ...prev, contacts: updatedContacts }));
  };

  const [docId, setDocId] = useState(null);

  const [addSupplierModal, setAddSupplierModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      alert("Vennligst fyll ut alle felter før du sender inn.");
      return; // Avslutter funksjonen hvis skjemaet ikke er gyldig
    }

    try {
      const collectionRef = collection(db, "suppliers");
      const docRef = await addDoc(collectionRef, formData);
      setDocId(docRef.id); // Sett docId tilstanden til ID-en av det nyopprettede dokumentet
      console.log("Dokumentet ble skrevet vellykket med ID:", docRef.id);
    } catch (error) {
      console.error("Feil ved skriving av dokument: ", error);
    }
  };

  const addContact = () => {
    setFormData((prev) => ({
      ...prev,
      contacts: [
        ...prev.contacts,
        {
          alertEmail: false,
          alertSms: false,
          email: "",
          name: "",
          phone: "",
          lat: 0,
          long: 0,
          messageQuota: 50,
          messageRules: [],
          category: "",
          packages: [],
          tasks: [],
          tillegg: [],
          zipcode: "",
          categories: ["partytelt"],
        },
      ],
    }));
  };

  const validateForm = () => {
    for (let key in formData) {
      // For enkelthets skyld sjekker vi kun første nivå av objektet.
      // For dybere objekter (som "contacts"), kan du inkludere ytterligere sjekker.
      if (typeof formData[key] === "string" && formData[key].trim() === "") {
        return false;
      }
    }
    return true;
  };

  const [salesData, setSalesData] = useState([]);
  const [sortedOrders, setSortedOrders] = useState<DocumentData[]>([]);

  const [chosenSupplier, setChosenSupplier] = useState("all");
  const [supplierIDs, setSupplierIDs] = useState([]);

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    console.log("TEST First seconds place")
    for(let i = 0; i<salesData.length; i++){
      
      console.log("Id: ", salesData[i].id)
      console.log("Typeof ", (salesData[i].tid.endDate === null))


      if(salesData[i].tid.startDate === null || salesData[i].tid.endDate === null){
        salesData.splice(i, 1)
        console.log("Spliced")
      }
    }
    setSortedOrders(
      [...salesData].sort(
        (a, b) => a.tid.startDate.seconds - b.tid.startDate.seconds
      )
    );  
  }, [salesData]);  

  useEffect(() => {
    fetchSuppliers();
    fetchOrders();
  }, []);

  const fetchSuppliers = async () => {
    const suppliers = [];
    const q = query(collection(db, "suppliers"));
    try {
      const querySnapshot = await getDocs(q);
  
      querySnapshot.forEach((doc) => {
        suppliers.push({
          id: doc.id,
          name: doc.data().name,
          city: doc.data().city,
        });
      });
  
      suppliers.sort((a, b) => {
        const nameA = a.name.toUpperCase(); 
        const nameB = b.name.toUpperCase(); 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  
      setSupplierIDs(suppliers);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchOrders = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "supplierSales"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      await setSalesData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  const filteredOrders = sortedOrders.filter((order) => {
    if (chosenSupplier === "all") return true;
    return order.supplierId === chosenSupplier;
  });

  return (
    <>
      <Container>
        <Row>
          <Col lg={8} className="pr-14">
            {" "}
            <div className="font-medium text-3xl tracking-wider pb-2 pt-10 lg:ml-0">
              Alle bestillinger
            </div>
            <Form>
              <Form.Group
                controlId="formSelectDestination"
                className="w-5/12 mb-4"
              >
                <FormSelect
                  value={chosenSupplier}
                  onChange={(e) => setChosenSupplier(e.target.value)}
                  className="h-12 font-josefin"
                >
                  <option value="all">Alle leverandører</option>
                  {supplierIDs.map((obj) => (
                    <option key={obj.id} value={obj.id}>
                      {obj.name}, {obj.city}
                    </option>
                  ))}
                </FormSelect>
              </Form.Group>
            </Form>
            <Col>
              <Row className="justify-content-md-center">
                {filteredOrders.map((item) => {
                  let today = new Date();
                  if (item.tid.endDate.seconds >= today.getTime() / 1000) {
                    const supplier = supplierIDs.find(
                      (s) => s.id === item.supplierId
                    );

                    const supplierString = supplier
                      ? `${supplier.name}, ${supplier.city}`
                      : "Ukjent leverandør";
                    return (
                      <Col md={{ span: 6 }} className="g-4">
                        <SalesSupplierCard
                          startDate={capitalize(
                            new Date(
                              item.tid.startDate.seconds * 1000
                            ).toLocaleString(`no-NO`, options)
                          )}
                          endDate={capitalize(
                            new Date(
                              item.tid.endDate.seconds * 1000
                            ).toLocaleString(`no-NO`, options)
                          )}
                          packageType={capitalize(item.kategori)}
                          orderIdLink={item.id}
                          status={item.status}
                          orderId={item.id.slice(0, 6)}
                          phonenumber={item.tlf.slice(3, 11)}
                          totalPrice={item.totalPris}
                          done={false}
                          supplier={supplierString}
                          onClickToSupplier={() => {
                            localStorage.setItem(
                              "supplierIdPL",
                              item.supplierId
                            );
                            window.location.href =
                              "/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631";
                          }}
                        />
                      </Col>
                    );
                  }
                  return null;
                })}
              </Row>
              <div className="font-medium text-xl tracking-wider pb-2 pt-12 pl-0">
                Tidligere bestillinger
              </div>
              <Row className="justify-content-md-center opacity-60">
                {filteredOrders.map((item) => {
                  let today = new Date();
                  if (
                    item &&
                    item.tid.endDate.seconds < today.getTime() / 1000
                  ) {
                    const supplier = supplierIDs.find(
                      (s) => s.id === item.supplierId
                    );

                    const supplierString = supplier
                      ? `${supplier.name}, ${supplier.city}`
                      : "Ukjent leverandør";
                    return (
                      <Col md={{ span: 6 }} className="g-4">
                        <SalesSupplierCard
                          startDate={capitalize(
                            new Date(
                              item.tid.startDate.seconds * 1000
                            ).toLocaleString(`no-NO`, options)
                          )}
                          endDate={capitalize(
                            new Date(
                              item.tid.endDate.seconds * 1000
                            ).toLocaleString(`no-NO`, options)
                          )}
                          packageType={capitalize(item.kategori)}
                          orderIdLink={item.id}
                          status={item.status}
                          orderId={item.id.slice(0, 6)}
                          phonenumber={item.tlf.slice(3, 11)}
                          totalPrice={item.totalPris}
                          done={false}
                          supplier={supplierString}
                          onClickToSupplier={() => {
                            localStorage.setItem(
                              "supplierIdPL",
                              item.supplierId
                            );
                            window.location.href =
                              "/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631";
                          }}
                        />
                      </Col>
                    );
                  }
                  return null;
                })}
              </Row>
            </Col>
          </Col>

          <Col lg={4} className="pt-8">
            <div className="flex justify-between pb-2">
              <div className="font-medium text-2xl tracking-wider pb-2 pt-10">
                Leverandører
              </div>

              <Button
                variant="secondary"
                size="sm"
                className="bg-pl_orange border-none text-white tracking-wider mt-8 rounded-full pt-2.5 pb-2.5 mb-3"
                onClick={() => setAddSupplierModal(true)}
              >
                + Opprett leverandør
              </Button>
            </div>
            {supplierIDs.map((obj) => {
              return (
                <div className="flex pt-2">
                  <div className="">{obj.name}, </div>
                  <div className="italic pl-2">{obj.city} - {obj.id.slice(0,6)}</div>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>

      {/** Modal for å opprette leverandør */}
      <ModalComp
        title="Opprett leverandør"
        show={addSupplierModal}
        hide={() => setAddSupplierModal(false)}
        size="md"
        closeModal={() => setAddSupplierModal(false)}
        body={
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block font-medium mb-2">Navn:</label>
              <input
                className="w-full p-2 border rounded focus:border-indigo-500"
                value={formData.name}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </div>

            <div className="mb-4">
              <label className="block font-medium mb-2">Adresse:</label>
              <input
                className="w-full p-2 border rounded focus:border-indigo-500"
                value={formData.address}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, address: e.target.value }))
                }
              />
            </div>

            <div className="mb-4">
              <label className="block font-medium mb-2">By:</label>
              <input
                className="w-full p-2 border rounded focus:border-indigo-500"
                value={formData.city}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, city: e.target.value }))
                }
              />
            </div>

            <div className="mb-4">
              <label className="block font-medium mb-2">Postnummer:</label>
              <input
                type="number"
                className="w-full p-2 border rounded focus:border-indigo-500"
                value={formData.zipcode}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, zipcode: e.target.value }))
                }
              />
            </div>

            <div className="mb-4">
              <label className="block font-medium mb-2">
                Breddegrad (Lat):
              </label>
              <input
                type="number"
                className="w-full p-2 border rounded focus:border-indigo-500"
                value={formData.lat}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    lat: parseFloat(e.target.value),
                  }))
                }
              />
            </div>

            <div className="mb-8">
              <label className="block font-medium mb-2">
                Lengdegrad (Long):
              </label>
              <input
                type="number"
                className="w-full p-2 border rounded focus:border-indigo-500"
                value={formData.long}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    long: parseFloat(e.target.value),
                  }))
                }
              />
            </div>

            <div className="mb-4">
    <label className="block font-medium mb-2">Kategorier:</label>
    <select multiple={true} onChange={(e) => {
        const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
        setFormData((prev) => ({ ...prev, categories: selectedOptions }));
    }}>
        {categories.map((category) => (
            <option key={category} value={category}>
                {category}
            </option>
        ))}
    </select>
</div>

            <div className="mt-8">
              <h2 className="text-lg mb-4 font-semibold">Kontakter:</h2>
              {formData.contacts.map((contact, index) => (
                <div key={index} className="bg-gray-100 p-4 mb-4 rounded">
                  <h3 className="text-lg mb-4 font-medium">
                    Kontakt {index + 1}
                  </h3>

                  <div className="mb-4">
                    <label className="block font-medium mb-2">Navn:</label>
                    <input
                      className="w-full p-2 border rounded focus:border-indigo-500"
                      value={contact.name}
                      onChange={(e) =>
                        handleContactChange(index, "name", e.target.value)
                      }
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block font-medium mb-2">E-post:</label>
                    <input
                      className="w-full p-2 border rounded focus:border-indigo-500"
                      value={contact.email}
                      onChange={(e) =>
                        handleContactChange(index, "email", e.target.value)
                      }
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block font-medium mb-2">Telefon:</label>
                    <input
                      className="w-full p-2 border rounded focus:border-indigo-500"
                      value={contact.phone}
                      onChange={(e) =>
                        handleContactChange(index, "phone", e.target.value)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-between items-center mt-8">
              <button
                type="button"
                onClick={addContact}
                className="bg-pl_orange text-white rounded py-2 px-4 hover:bg-pl_yellow focus:outline-none focus:ring-2 focus:ring-indigo-400"
              >
                Legg til Kontakt
              </button>
              <button
                type="submit"
                className="bg-pl_yellow text-white rounded py-2 px-6 hover:bg-pl_orange focus:outline-none focus:ring-2 focus:ring-indigo-600"
              >
                Send
              </button>
              
            </div>
            {docId && (
  <div className="mt-4 mb-4 text-green-500">
    Leverandør ble vellykket opprettet med ID: {docId}
  </div>
)}
          </form>
        }
      />
    </>
  );
}

export default CreateSupplier;