// @ts-nocheck

import { useEffect, useState } from "react";
import { Button, FormLabel, FormSelect, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import nb from "date-fns/locale/nb";

import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AiFillCalendar } from "react-icons/ai";
import { MdWhereToVote } from "react-icons/md";
import { GiPartyPopper } from "react-icons/gi";
import { TbPackage } from "react-icons/tb";
import { BiParty } from "react-icons/bi";

// import for carousel
import CustomCarousel from "../components/Carousel.tsx";

import "react-multi-carousel/lib/styles.css";

import pic from "../img/pl_forside.jpg";
import carousel_card3 from "../img/carousel_images/eksempelbilde_trondheim.webp";
import Location from "./Location.tsx";

import ikon_asap from "../img/carousel_icons/ikon_asap.png";
import ikon_billig from "../img/carousel_icons/ikon_billig.png";
import ikon_enkelt from "../img/carousel_icons/ikon_enkelt.png";
import ikon_frakt from "../img/carousel_icons/ikon_frakt.png";
import ikon_her from "../img/carousel_icons/ikon_her.png";
import ikon_selvbetjent from "../img/carousel_icons/ikon_selvbetjent.png";

import UnavailableDatePicker from "../components/UnavailableDatesPicker.js"

import "bootstrap/dist/css/bootstrap.css";

const lydpakker = [
  {
    img_src:
      "https://storage.googleapis.com/partylyd2022.appspot.com/images/kraftigNY5%20(1)-min.png",
    title: "Kraftig lydpakke",
    link: "3",
    subtitle: "Lei fra 949,-",
    text: "Vår bestselger, helt enkelt. Dekker alle formål med inntil 100 personer. Brukervennlig med mulighet for ekstra utstyr. Spill fra mobil, PC eller mikrofon.",
  },
  {
    img_src:
      "https://firebasestorage.googleapis.com/v0/b/partylyd2022.appspot.com/o/website-images%2FShowpakke%20(1).jpg?alt=media&token=ed1a130b-a3ec-4224-bfc1-b8784ed4e75c",
    title: "Effektpakke",
    link: "2",
    subtitle: "Lei fra 849,-",
    text: "Lys opp festen med vår effekt-pakke! Derbylys som kan synkroniseres, legg til røykmaskin og mulighet for å skifte farge i takt med musikken. La oss gjøre kvelden uforglemmelig!",
  },
  {
    img_src:
      "https://storage.googleapis.com/partylyd2022.appspot.com/images/storpartyNY%20(2)-min.png",
    title: "Stor partypakke",
    link: "1",
    subtitle: "Lei fra 1899,-",
    text: "Legg opp til den beste festen med vår ultimate lyd, lys og bass-pakke! Passer for opptil 150 personer og gir god lydgjengivelse og kraftig bass. Enkel i bruk og kompatibel med ekstra utstyr. Bruk med mobil eller PC. Synkroniser lysene med musikken for en fullendt opplevelse!",
  },
];

const categories = [
  {
    img_src:
      "https://www.bruksanvisningpdf.no/thumbs/products/l/1546738-soundboks-3.jpg",
    title: "Soundboks",
    id: "soundboks",
    bg: "#E3826F",
    description1: "God lyd og bass for en mindre og praktisk fest. Enkel booking over hele landet.",
    description2: "Oppdag Soundboks 3 – et konsept som definerer festlyd. Bærbar, kraftig, og designet for lettvint gjennomføring. Med enkel booking over hele Norge, kan du nå skape den perfekte stemningen for ditt arrangement. Ikke la musikken være en ettertanke; la den være høydepunktet.",
  },
  {
    img_src:
      "https://www.sirkusagio.no/uploads/MdC2suTL/242x0_242x0/Slushmaskin_leie_trekammer.jpg",
    title: "Slushmaskin",
    id: "slushmaskin",
    description1: "Forfriskende slush for alle anledninger. Perfekt for sommerfester og events.",
    description2: "Ta festen eller arrangementet ditt til neste nivå med vår premium slushmaskin utleie i Norge. Perfekt for sommerfester, barnebursdager eller bedriftseventer, gir vår slushmaskin deilige, kjølige forfriskninger som gjestene dine vil elske. Enkel booking, konkurransedyktige priser og landsdekkende levering. Reserver din slushmaskin nå og server smaken av kjølig glede!"
  },
  {
    img_src:
      "https://www.sirkusagio.no/uploads/snDni9EQ/737x0_360x0/vognogsuper.png",
    title: "Popcornmaskin",
    id: "popcorn",
    description1: "Server ferskt, velsmakende popcorn på minutter. En autentisk kinoopplevelse.",
    description2: "Gjør filmkvelden, bursdagen eller eventet ditt ekstra spesielt med vår popcornmaskin utleie i Norge. Med bare et trykk på en knapp kan du servere nydelig, ferskt popcorn som smelter i munnen. Våre maskiner er brukervennlige, rene og klare til bruk. Uansett anledning, gir popcornmaskinen en ekstra touch av moro og nostalgi. Book din popcornmaskin i dag og nyt den autentiske kinoopplevelsen!"
  },
  {
    img_src:
      "https://showup.no/wp-content/uploads/2015/10/344227017_909292860335349_2579018455381374044_n-600x469.jpg",
    title: "Hoppeslott",
    id: "hoppeslott",
    description1: "Timesvis med moro for barn og voksne. Sikker underholdning for alle anledninger.",
    description2:"Ser du etter den ultimate underholdningen for barna? Vår hoppeslott utleie i Norge er svaret! Med fargerike design og sikre materialer, gir våre hoppeslott timesvis med hopp og glede. Perfekt for bursdager, skolearrangementer eller bare en dag i hagen. Med enkel online booking og levering over hele landet, har det aldri vært enklere å gi barna en dag de aldri vil glemme. Lei ditt hoppeslott nå og se smilene lyse opp!"
  },
];

function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 2000));
}

const Home = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [chosenLocation, setLocation] = useState("Lørenskog");
  const [typePackage, setTypePackage] = useState("lydoglyspakke");

  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  const navigate = useNavigate();

  const navigateToOtherSite = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  const navigateToCategory = (site: string) => {
    navigate("leverandor/" + site);
    window.scrollTo(0, 0);
  };

  const navigateToPackage = (site: string) => {
    if (site === "Ordinær lydpakke") {
      navigate(`/pakke/ordinarlydpakke`);
    } else {
      let url = site.replace(/\s/g, "").toLowerCase();
      navigate(`/pakke/${url}`);
    }
    window.scrollTo(0, 0);
  };

  const handleButtonSearch = () => {
    if (chosenLocation === "Lørenskog") {
      return navigate(
        `/pakker/${typePackage}/lorenskog/${startDate}/${endDate}`
      );
    }
    navigate(
      `/pakker/${typePackage}/${chosenLocation}/${startDate}/${endDate}`
    );
    window.scrollTo(0, 0);
  };

  registerLocale("nb", nb);

  return (
    <>
      <div className="bg-[#f39c12] pb-2">
        <Container>
          <Row>
            <Col xl={{ span: 5, offset: 0 }} xs={{ span: 12 }}>
              <div>
                <div className="flex">
                  <h1 className="text-white lg:pl-28 xl:pt-36 lg:pt-16 pt-12 font-bold lg:text-5xl text-[44px] ml-2 tracking-wide font-josefin">
                    {" "}
                    Lei lyd og lys,
                  </h1>{" "}
                  {/* <ToggleSimple /> */}
                </div>

                <h1 className="text-white lg:pl-28 font-bold lg:text-5xl text-[44px] ml-2 tracking-wide pt-3 font-josefin">
                  helt enkelt.
                </h1>
              </div>
              <div className=" ml-2 text-white lg:pl-28 lg:pt-10 pt-10 font-normal text-xl tracking-wider font-josefin">
                Hent på et <strong>selvbetjent</strong> lager på Lørenskog, Asker, Vinterbro, Bergen,
                Trondheim, Stavanger, Kristiansand{/*  eller få pakken levert til deg */}.
              </div>
            </Col>
            <Col xl={{ span: 5, offset: 0 }} lg={{ span: 9, offset: 1 }}>
              <img
                alt=""
                className="rounded-3xl xl:mt-20 mt-8 xl:w-90 xl:ml-24 lg:ml-10 "
                src={pic}
              />
            </Col>
          </Row>

          <div className="lg:ml-28 lg:mr-28 lg:mb-10 mb-4 lg:mt-16 mt-7 lg:pt-12 pt-6 lg:pb-10 pb-6 rounded-3xl  bg-white">
            <Form>
              <Container>
                <Row className="xl:ml-12 lg:mx-4">
                  <Col
                    xs={{ span: 12, order: 2 }}
                    sm={{ span: 12, order: 2 }}
                    md={{ span: 12, order: 2 }}
                    xl={{ span: 3, order: 0 }}
                    lg={{ span: 12, order: 0 }}
                  >
                    <Form.Group
                      controlId="formSelectedDestination"
                      className="mb-3"
                    >
                      <FormLabel className="font-medium antialiased tracking-wide mb-3">
                        <div className="justify-between flex">
                          <div>
                            <GiPartyPopper size={28} className="ml-2" />
                          </div>
                          <div className="ml-3 mt-1 font-josefin">
                            Hva trenger du?
                          </div>
                        </div>
                      </FormLabel>
                      <FormSelect
                        value={typePackage}
                        onChange={(e) => setTypePackage(e.target.value)}
                        className="h-12 font-josefin"
                      >
                        <option value="lydoglyspakke">Lyd og lys</option>
                        <option value="lydpakke">Lyd</option>
                        <option value="lyspakke">Lys</option>
                        <option value="bordStolerTelt">
                          Bord, stoler og partytelt
                        </option>
                      </FormSelect>
                    </Form.Group>
                  </Col>
                  <Col
                    xs={{ span: 12, order: 2 }}
                    sm={{ span: 12, order: 2 }}
                    md={{ span: 12, order: 2 }}
                    xl={{ span: 3, order: 0 }}
                    lg={{ span: 12, order: 0 }}
                  >
                    <Form.Group
                      controlId="formSelectedDestination"
                      className="mb-3"
                    >
                      <FormLabel className="font-medium antialiased tracking-wide mb-3">
                        <div className="justify-between flex">
                          <div>
                            <MdWhereToVote size={28} className="ml-2" />
                          </div>
                          <div className="ml-2 mt-1 font-josefin">Hvor?</div>
                        </div>
                      </FormLabel>
                      <FormSelect
                        value={chosenLocation}
                        onChange={(e) => setLocation(e.target.value)}
                        className="h-12 font-josefin"
                      >
                        <option value="trondheim">Trondheim</option>
                        <option value="lorenskog">Lørenskog</option>
                        <option value="asker">Asker</option>
                        <option value="bergen">Bergen</option>
                        <option value="vinterbro">Vinterbro</option>
                        <option value="stavanger">Stavanger</option>
                        <option value="kristiansand">Kristiansand</option>
                      </FormSelect>
                    </Form.Group>
                  </Col>
                  <Col
                    xs={{ span: 12, order: 2 }}
                    sm={{ span: 12, order: 2 }}
                    md={{ span: 12, order: 2 }}
                    xl={{ span: 3, order: 0 }}
                    lg={{ span: 12, order: 0 }}
                  >
                    <Form.Group controlId="formSelectedDate">
                      <FormLabel className="font-medium antialiased tracking-wide mb-3">
                        <div className="justify-between flex">
                          <div>
                            <AiFillCalendar size={28} className="ml-2" />
                          </div>
                          <div className="ml-3 mt-1 font-josefin">Når?</div>
                        </div>
                      </FormLabel>
                      <DatePicker
                        selectsRange={true}
                        locale="nb"
                        dateFormat="dd/MM/yyyy"
                        startDate={startDate}
                        minDate={new Date()}
                        endDate={endDate}
                        className="form-control h-12 font-josefin"
                        onChange={(update) => {
                          // @ts-ignore
                          setDateRange(update);
                        }}
                        style={{
                          width: "120%",
                        }}
                        isClearable={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    xs={{ span: 12, order: 2 }}
                    xl={{ span: 3, order: 0 }}
                    lg={{ span: 12, order: 0 }}
                  >
                    <Button
                      className="lg:mt-11 mt-6 tracking-wide h-12"
                      onClick={!isLoading ? handleButtonSearch : null}
                      disabled={!endDate}
                      style={{
                        backgroundColor: "#e57e22",
                        border: "none",
                        fontWeight: 500,
                      }}
                    >
                      <div className="justify-between flex">
                        <div>
                          <TbPackage size={28} />
                        </div>
                        <div className="ml-2 font-josefin mt-1">
                          FINN PAKKE{" "}
                        </div>
                      </div>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </div>

          <Row className="justify-content-md-center font-josefin">
  {[
    { onClick: () => navigate("/leverandor/soundboks"), text: "Lei soundboks" },
    { onClick: () => navigate("/leverandor/slushmaskin"), text: "Lei slushmaskin" },
    { onClick: () => navigate("/pakke/bord"), text: "Lei bord" },
    { onClick: () => navigate("/leverandor/popcorn"), text: "Lei popcornmaskin" },
    { onClick: () => navigate("/pakke/stoler"), text: "Lei stoler" },
    { onClick: () => navigate("/pakke/effektpakke"), text: "Lei discolys" },
    { onClick: () => navigate("/pakke/partytelt"), text: "Lei partytelt" },
    { onClick: () => navigate("/pakker"), text: "Lei lydanlegg" },
  ].map(button => (
    <Col xl="auto" sm="auto" xs="auto" key={button.text}>
      <div className="mb-4">
        <Button
          onClick={button.onClick}
          className="rounded-2xl text-gray-500 bg-white border-white py-2.5 px-3 font-bold hover:bg-pl_orange hover:border-white hover:text-black focus:bg-pl_orange focus:text-white focus:ring focus:ring-white-300 transform transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg"
        >
          {button.text}
        </Button>
      </div>
    </Col>
  ))}
</Row>
        </Container>
      </div>

      <Container>
        <div className="mx-auto bg-white overflow-hidden max-w-6xl mt-12 mb-6">
          <Row>
            <CustomCarousel
              numberOfCards={6}
              CardTitles={[
                "Plug and play",
                "Enkelt",
                "Rimelig",
                "Fleksibelt",
                "Lokalt",
                "Selvbetjent",
              ]}
              CardImages={[
                ikon_enkelt,
                ikon_asap,
                ikon_billig,
                ikon_frakt,
                ikon_her,
                ikon_selvbetjent,
              ]}
              CardIcons={[
                <BiParty></BiParty>,
                <BiParty></BiParty>,
                <BiParty></BiParty>,
              ]}
              CardBodyText={[
                "masse masse masse masse tekst på card0",
                "masse mindre mindre mindre teskt på card1",
                "masse mindre mindre mindre teskt på card1",
                "masse mindre mindre mindre teskt på card1",
              ]}
              CardSubTitles={[
                "Montert på 1-2-3",
                "Veldig, veldig enkelt",
                "Så du får mer til overs",
                "Tilgjengelig 365 dager",
                "Utleie over hele Norge",
                "Du kan fikse alt selv",
              ]}
            ></CustomCarousel>
          </Row>
        </div>
      </Container>

      <Container>
        <div className="mx-auto bg-white overflow-hidden max-w-6xl mb-10">
          <div className="md:flex md:shrink-0">
            <Row md={3} className="g-2">
              <Col md={{ span: 12, offset: 0 }} xs={{ span: 10, offset: 0 }}>
                <div className="lg:pt-8 pt-6 font-medium text-3xl tracking-wide lg:pb-4 pb-2 font-josefin">
                  Noen av våre lyd og lyspakker
                </div>
              </Col>

              {lydpakker.map(
                (object: {
                  img_src: string;
                  title: string;
                  link: string;
                  subtitle: string;
                  text: string;
                }) => (
                  <Col md={{ span: 4, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                    <a
                      href="#!"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      <Card
                        className="rounded-xl p-2 m-1 hover:drop-shadow-xl hover:text-black shadow-md border-gray font-josefin"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateToPackage(object.title)}
                      >
                        <Card.Img
                          variant="top"
                          src={object.img_src}
                          style={{
                            width: "100%",
                            height: "38vh",
                            objectFit: "contain",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                        <Card.Body>
                          <Card.Title className="text-3xl tracking-wide font-normal">
                            {object.title}
                          </Card.Title>
                          <Card.Subtitle className="font-bold pb-2 text-base">
                            {object.subtitle}
                          </Card.Subtitle>
                          <Card.Text className="line-clamp-4">
                            {object.text}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </a>
                  </Col>
                )
              )}
            </Row>
          </div>
          <div className="flex justify-center">
          <Button
  variant="secondary"
  className="focus:ring-4 font-josefin shadow-sm transform active:scale-y-75 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg"
  onClick={(e) => navigateToOtherSite("/pakker")}
  style={{
    fontWeight: 500,
    backgroundColor: "#e57e22",
    borderColor: "#e57e22",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "1.0rem",
    paddingBottom: "1.0rem",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
  }}
>
  SE ALLE PAKKER
</Button>
          </div>
        </div>
      </Container>

      <Container>
        <div className="mx-auto bg-white overflow-hidden max-w-6xl mb-10">
          <Col md={{ span: 12, offset: 0 }} xs={{ span: 10, offset: 0 }}>
            <div className="font-normal text-lg tracking-wider font-josefin ml-2 text-center">
              {" "}
              Bestill utstyr over hele Norge
            </div>
            <div className="font-normal text-4xl tracking-wider pb-8 ml-2 text-center">
              {" "}
              Levert av våre leverandører
            </div>
          </Col>

          <div>
            <Row className="justify-content-md-center">
              {categories.map(
                (object: { img_src: string; title: string; id: string }) => (
                  <Col md={{ span: 3, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                    <a
                      href="#!"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      <Card
                        className="rounded-[15px] m-1 hover:drop-shadow-xl hover:text-black shadow-md border-gray font-josefin bg"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateToCategory(object.id)}
                      >
                        <Card.Img
                          variant="top"
                          className="mt-2"
                          src={object.img_src}
                          style={{
                            width: "100%",
                            height: "24vh",
                            objectFit: "contain",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRadius: "20px 20px 20px 20px",
                          }}
                        />
                        <Card.Body className="text-center">
                          <Card.Title className="text-2xl font-bold ">
                            Leie {object.title}
                          </Card.Title>
                          <Card.Text className="text-md font-normal mt-2">
                                {object.description1}
                              </Card.Text>
                        </Card.Body>
                      </Card>
                    </a>
                  </Col>
                )
              )}
            </Row>
          </div>
        </div>
      </Container>


      <Container>
        <div className="mx-auto  bg-white max-w-6xl mb-10 font-josefin">
          <div className="md:flex md:shrink-0">
            <Row lg={2} xs={1}>
              <Col lg={{ span: 12, offset: 0 }}>
                <div className="lg:pt-10 pt-6 lg:pb-10 pb-6 font-medium text-3xl tracking-wide font-josefinDU">
                  Usikker på hvilken pakke du skal velge?
                </div>
              </Col>
              <Col lg={{ span: 6, offset: 0 }}>
                <Card
                  className="px-10 py-12 mb-4 border-2 rounded-xl font-josefin hover:drop-shadow-xl hover:text-black shadow-md border-gray"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigateToOtherSite("/finn-riktig-pakke")}
                >
                  <Card.Title className="font-bold pb-2 tracking-widest text-3xl text-[#e57e22] ">
                    Finn riktig pakke
                  </Card.Title>
                  <Card.Text className="font-normal tracking-wide text-md">
                    Trykk her for å få hjelp til å finne en pakke som passer
                    perfekt til ditt arrangement!
                  </Card.Text>
                </Card>
              </Col>
              <Col md={{ span: 6, offset: 0 }}>
                <Card
                  className="px-10 py-12 mb-4 border-2 rounded-xl font-josefin hover:drop-shadow-xl hover:text-black shadow-md border-gray"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigateToOtherSite("/pakker")}
                >
                  <Card.Title className="font-bold pb-2 tracking-widest text-3xl  text-[#e57e22] ">
                    Se alle pakker
                  </Card.Title>
                  <Card.Text className="font-normal tracking-wide text-md">
                    Trykk her for å se alle de ulike pakketilbudene og
                    kategoriene vi har tilgjengelig!
                  </Card.Text>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      <div className="bg-[#e57e22] pb-12 pt-2">
        <Container>
          <Location color={"white"} />
        </Container>
      </div>

      <Container>
        <Row>
          <div id="dib-posts"></div>
        </Row>
      </Container>

    </>
  );
};

export default Home;
