// @ts-nocheck
import {
  collection,
  DocumentData,
  getDocs,
  query,
  doc,
  updateDoc,
} from "firebase/firestore";
import { SetStateAction, useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container } from "react-bootstrap";
// @ts-nocheck
import ModalComp from "../components/ModalComp.tsx";
// @ts-ignore
import AdminCard from "../components/AdminCard.tsx";
// @ts-ignore
import { db } from "../firebase1.ts";
import { useNavigate } from "react-router-dom";

import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase1.ts";

const Admin = () => {
  const [data, setData] = useState<DocumentData[]>([]);
  const [numberOfDeliveries, setNumberOfDeliveries] = useState(0);
  const [numberOfActiveOrders, setNumberOfActiveOrders] = useState(0);
  const [numberOfOrdersMadeToday, setNumberOfOrdersMadeToday] = useState(0);

  const [sortedOrders, setSortedOrders] = useState<DocumentData[]>([]);
  const [cancelOrder, setCancelOrder] = useState(false);
  const [accountingDone, setAccountingDone] = useState("");
  const [reasonCancel, setReasonCancel] = useState("");

  const [commentOrder, setCommentOrder] = useState("");
  const [commentOrderID, setCommentOrderID] = useState("");
  const [showComment, setShowComment] = useState(false);
  const handleShowCommentCancel = () => setShowComment(false);

  const [idCanceledOrder, setIdCanceledOrder] = useState("");
  const [showCancel, setShowCancel] = useState(false);
  const handleShowCancel = () => setShowCancel(false);

  const [showCancelReason, setShowCancelReason] = useState(false);
  const handleShowCancelReason = () => setShowCancelReason(false);

  const [showFailed, setShowFailed] = useState(false);
  const handleCloseFailed = () => setShowFailed(false);
  const handleShowFailed = () => setShowFailed(true);

  const functions = getFunctions(app, 'europe-west1');
  const accounting = httpsCallable(functions, "autoAccounting");
  const cancel = httpsCallable(functions, "cancelOrder");

  const handleCancelation = () => {
    let data = {
      client_reference_id: idCanceledOrder,
      reason: reasonCancel,
    };

    cancel(data).then((response) => {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
      handleShowCancelReason();
      handleShowCancel();
    });
  };

  const handleAccountingButtonVisibility = (order: any) => {
    let hiddenState = "hidden";
    if (order.addonOrders) {
      order.addonOrders.map((addon: any) => {
        if (addon.paid === true && !addon.invoiceID) {
          hiddenState = "";
        }
      });
    } else if (!order.invoiceID) {
      hiddenState = "";
    }
    return hiddenState;
  };

  const handleAutoAccounting = (id: string) => {
    let data = {
      client_reference_id: id,
    };
    setAccountingDone(id);
    accounting(data).then((response) => {
      console.log(response.data);
      window.location.reload();
    });
  };

  const navigate = useNavigate();

  const navigateToPackage = (site: string) => {
    navigate(site);
    window.scrollTo(0, 0);
  };

  const handleChangeReason = (e: any) => {
    setReasonCancel(e.target.value);
  };

  const handleChangeComment = (e: any) => {
    setCommentOrder(e.target.value);
  };

  async function changeCommentDB(kommentar: string, orderId: string) {
    const updateObject = {
      [`comment`]: kommentar,
    };
    const gearUpdate = doc(db, "sales", orderId);

    updateDoc(gearUpdate, updateObject)
      .then((e) => {
        setShowComment(false);
        window.location.reload(false);
      })
      .catch((e) => {
        handleShowFailed();
      });
  }

  useEffect(() => {
    if (cancelOrder) {
      handleShowCancel();
    }
  }, [cancelOrder]);

  //HENTE DOKUMENT FRA DATABASE
  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "sales"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        if (doc.data().paymentStatus === "paid") {
          list.push({ id: doc.id, ...doc.data() });
        }
      });
      await setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    countDelivery();
    countActiveDeliveries();
    countOrdersMadeToday();
    setSortedOrders(
      [...data].sort(
        (a, b) => b.tid.startDate.seconds - a.tid.startDate.seconds
      )
    );
  }, [data]);

  const countOrdersMadeToday = async () => {
    let count = 0;
    let today = new Date();

    data.map((doc) => {
      const orderPlaced = new Date(doc.tid.orderPlaced.seconds * 1000);
      if (
        today.getFullYear() === orderPlaced.getFullYear() &&
        today.getMonth() === orderPlaced.getMonth() &&
        today.getDate() === orderPlaced.getDate()
      ) {
        count++;
      }
    });

    return setNumberOfOrdersMadeToday(count);
  };
  const countDelivery = async () => {
    let count = 0;
    let today = new Date();
    data.map((doc) => {
      if (
        doc.deliveryService[0].order === true &&
        doc.tid.startDate.seconds > today.getTime() / 1000
      ) {
        count++;
      }
    });
    return setNumberOfDeliveries(count);
  };

  const countActiveDeliveries = async () => {
    let count = 0;
    let today = new Date();

    data.map((doc) => {
      const start = new Date(doc.tid.startDate.seconds * 1000);
      const end = new Date(doc.tid.endDate.seconds * 1000);

      if (today >= start && today <= end) {
        count++;
      }
    });

    return setNumberOfActiveOrders(count);
  };

  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (password === 'admin123') {
      localStorage.setItem('adminPass', 'admin123');
      window.location.reload();
    } else {
      setError("Ugyldig passord. Prøv igjen.");
    }
  };

  if (localStorage.getItem('adminPass') !== 'admin123') {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
          <h2 className="text-2xl font-bold mb-4">Begrenset område</h2>
          <input
            type="password"
            value={password}
            onChange={handleInputChange}
            placeholder="Skriv inn passord"
            className="w-full p-2 mb-4 border border-gray-300 rounded"
          />
          <button
            onClick={handleSubmit}
            className="w-full bg-[#F39C12] text-white p-2 rounded hover:bg-orange-700 transition duration-200"
          >
            Bekreft
          </button>
          {error && <p className="mt-4 text-red-500">{error}</p>}
        </div>
      </div>
    );
  }

  return (
    <div className="mb-10 font-josefin">
      {/* Modal for å sjekke om du vil kansellere ordre*/}
      <ModalComp
        show={showCancel}
        closeModal={handleShowCancel}
        title="Er du sikker på at du vil kansellere ordren?"
        hide={handleShowCancel}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16"
            onClick={handleShowCancel}
          >
            Lukk
          </Button>
        }
        body={
          <>
            Å kansellere en ordre kan ikke angres. Er du sikker på at du ønsker
            å kansellere orderen?
            <Row className="mt-2 mb-2">
              <Col lg={{ span: 11, offset: 3 }} xs={{ offset: 2, span: 12 }}>
                <Button
                  variant="primary"
                  className="text-black border-black hover:border-white hover:text-white hover:bg-gray mt-3 w-3/12 ml-2"
                  onClick={() => setShowCancelReason(true)}
                >
                  Ja
                </Button>

                <Button
                  variant="secondary"
                  className="text-white bg-[#24292e] mt-3 w-3/12 ml-2"
                  onClick={handleShowCancel}
                >
                  Nei
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      {/* Modal for å skrive årsak til kansellering*/}
      <ModalComp
        show={showCancelReason}
        closeModal={handleShowCancelReason}
        title="Hvorfor skal ordren kanselleres?"
        hide={handleShowCancelReason}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16"
            onClick={handleShowCancelReason}
          >
            Lukk
          </Button>
        }
        body={
          <>
            For å kansellere ordren må du skrive en kort begrunnelse. Dette er:
            <Form>
              <Form.Group
                className="mt-2 mb-1"
                controlId="form.CancelReason"
                onChange={handleChangeReason}
              >
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
            </Form>
            <Row className="mt-2 mb-2 float-right">
              <Col>
                <Button
                  variant="secondary"
                  className="text-white bg-[#24292e]"
                  onClick={() => {
                    handleCancelation();
                    setReasonCancel();
                  }}
                >
                  Kanseller ordre
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      {/* Modal for å legge inn kommentar på ordre*/}
      <ModalComp
        show={showComment}
        closeModal={handleShowCommentCancel}
        title={`Kommentar på ordre ${commentOrderID.slice(0, 6)}`}
        hide={handleShowCommentCancel}
        body={
          <>
            Se og endre kommentar på bestilling.
            <Form>
              <Form.Group
                className="mt-2 mb-1"
                controlId="form.Comment"
                onChange={handleChangeComment}
              >
                <Form.Control
                  className="font-medium tracking-widers text-black"
                  value={`${commentOrder}`}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Form>
            <Row className="mt-2 mb-2 float-right">
              <Col>
                <Button
                  variant="secondary"
                  className="text-white bg-[#24292e]"
                  onClick={() => {
                    changeCommentDB(commentOrder, commentOrderID);
                  }}
                >
                  Legg til kommentar
                </Button>
                <Button
                  variant="secondary"
                  className="text-[#24292e] border-[#24292e] text-left ml-2"
                  onClick={handleShowCommentCancel}
                >
                  Lukk
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      {/* Modal for å legge inn kommentar på ordre*/}
      <ModalComp
        show={showFailed}
        closeModal={handleCloseFailed}
        hide={handleCloseFailed}
        body={
          <>
            <p>
              Kunne ikke oppdatere ønsket verdi akkurat nå. Vennligst prøv igjen
              senere, eller ta kontakt med teknisk ansvarlig.
            </p>
          </>
        }
      />
      <Row className="justify-content-md-center">
        <Col md={{ span: 3 }} className="lg:g-4">
          <Card
            className="text-left mt-4 shadow-sm border-gray hover:bg-[#618540] mx-2 bg-[#84ad5e] "
            onClick={() =>
              navigateToPackage(
                "/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/dagens-bestillinger"
              )
            }
          >
            <Card.Body>
              <Row>
                <Col md={9} xs={9} className="py-2 lg:px-4">
                  <div className="group-hover:text-white text-white text-md font-medium tracking-widest py-2">
                    Nye bestillinger
                  </div>
                </Col>
                <Col md={3} xs={3} className="py-2">
                  <div className="text-xl text-[#84ad5e] bg-white font-medium text-center tracking-widest py-2 group-hover:text-white rounded-full">
                    {numberOfOrdersMadeToday}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={{ span: 3, offset: 0 }} className="lg:g-4 ">
          <Card
            className="text-left mt-4 shadow-sm border-gray hover:bg-[#d66b0b] mx-2 bg-pl_orange"
            onClick={() =>
              navigateToPackage(
                "/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/aktive-bestillinger"
              )
            }
          >
            <Card.Body>
              <Row>
                <Col md={9} xs={9} className="py-2 lg:px-4">
                  <div className="group-hover:text-white text-md font-medium tracking-widest py-2 text-white">
                    Aktive bestillinger
                  </div>
                </Col>
                <Col md={3} xs={3} className="py-2">
                  <div className="text-xl text-pl_orange bg-white font-medium text-center tracking-widest py-2 group-hover:text-white rounded-full">
                    {numberOfActiveOrders}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={{ span: 3, offset: 0 }} className="lg:g-4">
          <Card
            className="text-left mt-4 shadow-sm border-gray hover:bg-[#2a87b5] mx-2 bg-[#40ace3]"
            onClick={() => navigateToPackage("/admin-levering")}
          >
            <Card.Body>
              <Row>
                <Col md={9} xs={9} className="py-2 md:px-4 ">
                  <div className="group-hover:text-white text-md font-medium tracking-widest py-2 text-white">
                    Leveringer
                  </div>
                </Col>
                <Col md={3} xs={3} className="py-2">
                  <div className="text-xl font-medium text-center tracking-widest py-2 group-hover:text-white bg-white rounded-full text-[#40ace3] ">
                    {numberOfDeliveries}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Container>
        <div className="font-medium text-3xl tracking-wider pb-2 pt-10 lg:pl-28 lg:ml-0">
          Alle bestillinger
        </div>
        <Row className="justify-content-md-center">
          {sortedOrders.map((item) => {
            let today = new Date();
            return item.paymentStatus === "paid" ? (
              <>
                {item.tid.endDate.seconds >= today.getTime() / 1000 ? (
                  <Col md={{ span: 5 }} className="g-4 ">
                    <AdminCard
                      startDate={capitalize(
                        new Date(
                          item.tid.startDate.seconds * 1000
                        ).toLocaleString(`no-NO`, options)
                      )}
                      endDate={capitalize(
                        new Date(
                          item.tid.endDate.seconds * 1000
                        ).toLocaleString(`no-NO`, options)
                      )}
                      packageType={item.pakkeType}
                      orderId={item.id.slice(0, 6)}
                      orderIdLink={item.id}
                      phoneNUmber={item.tlf.slice(3, 11)}
                      totalPrice={item.totalPris}
                      storage={capitalize(item.lager)}
                      status={item.status}
                      onClick={() => {
                        setIdCanceledOrder(item.id);
                        setShowCancel(true);
                      }}
                      visibilityComment={item.comment ? "" : "hidden"}
                      visibilityNoComment={item.comment ? "hidden" : ""}
                      onClickRegnskap={() => handleAutoAccounting(item.id)}
                      visibility={handleAccountingButtonVisibility(item)}
                      onClickComment={() => {
                        if (item.comment) {
                          setCommentOrder(item.comment);
                        } else {
                          setCommentOrder("");
                        }

                        setCommentOrderID(item.id);
                        setShowComment(true);
                      }}
                    />
                  </Col>
                ) : null}
              </>
            ) : null;
          })}
        </Row>
      </Container>
      <Container>
        <div className="font-medium text-xl tracking-wider pb-2 pt-12 lg:pl-28 pl-0">
          Tidligere bestillinger
        </div>
        <Row className="justify-content-md-center opacity-60">
          {sortedOrders.map((item) => {
            let today = new Date();
            return item.paymentStatus === "paid" ? (
              <>
                {item.tid.endDate.seconds < today.getTime() / 1000 ? (
                  <Col md={{ span: 5 }} className="g-4 ">
                    <AdminCard
                      startDate={capitalize(
                        new Date(
                          item.tid.startDate.seconds * 1000
                        ).toLocaleString(`no-NO`, options)
                      )}
                      endDate={capitalize(
                        new Date(
                          item.tid.endDate.seconds * 1000
                        ).toLocaleString(`no-NO`, options)
                      )}
                      packageType={item.pakkeType}
                      orderId={item.id.slice(0, 6)}
                      orderIdLink={item.id}
                      phoneNUmber={item.tlf.slice(3, 11)}
                      totalPrice={item.totalPris}
                      storage={capitalize(item.lager)}
                      status={item.status}
                      onClick={() => {
                        setIdCanceledOrder(item.id);
                        setShowCancel(true);
                      }}
                      visibilityComment={item.comment ? "" : "hidden"}
                      visibilityNoComment={item.comment ? "hidden" : ""}
                      onClickRegnskap={() => handleAutoAccounting(item.id)}
                      visibility={
                        item.invoiceID || accountingDone === item.id
                          ? "hidden"
                          : ""
                      }
                      onClickComment={() => {
                        if (item.comment) {
                          setCommentOrder(item.comment);
                        } else {
                          setCommentOrder("");
                        }

                        setCommentOrderID(item.id);
                        setShowComment(true);
                      }}
                    />
                  </Col>
                ) : null}
              </>
            ) : null;
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Admin;
