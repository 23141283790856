import { FC } from "react";

interface LoadingProps {
  color?: string;
}

const Loading: FC<LoadingProps> = ({ color = "red" }) => {
  return (
    <div>
      <div className="space-x-2">
        <div className={`spinner-grow text-[${color}]`} role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className={`spinner-grow text-[${color}]`} role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className={`spinner-grow text-[${color}]`} role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className={`spinner-grow text-[${color}]`} role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className={`spinner-grow text-[${color}]`} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
