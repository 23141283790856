// @ts-ignore
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  query,
} from "firebase/firestore";
import { useEffect, useState, SetStateAction } from "react";
import { Card, Col, Row, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { getExtras, getEquipment } from "../components/Backend.tsx";
// @ts-ignore
import { db } from "../firebase1.ts";

import "./style.css";

const FindPackage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<DocumentData[]>([]);
  const [isActiveSmall, setIsActiveSmall] = useState(false);
  const [isActiveMedium, setIsActiveMedium] = useState(false);
  const [isActiveLarge, setIsActiveLarge] = useState(false);

  const [isOutdoors, setIsOutdoors] = useState(false);
  const [isIndoors, setIsIndoors] = useState(false);

  const handleClickSmall = () => {
    setIsActiveSmall(true);
    setIsActiveMedium(false);
    setIsActiveLarge(false);
  };

  const handleClickMedium = () => {
    setIsActiveMedium(true);
    setIsActiveSmall(false);
    setIsActiveLarge(false);
  };

  const handleClickLarge = () => {
    setIsActiveSmall(false);
    setIsActiveLarge(true);
    setIsActiveMedium(false);
  };

  const handleClickOutdoors = () => {
    setIsOutdoors(true);
    setIsIndoors(false);
  };

  const handleClickIndoors = () => {
    setIsOutdoors(false);
    setIsIndoors(true);
  };

  //HENTE DOKUMENT FRA DATABASE
  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "packages"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log(data)
  }, [data])

  useEffect(() => {
    fetchData();
  }, []);

  const navigateToPackage = (site: string) => {
    if (site === "Ordinær lydpakke") {
      navigate(`/pakke/ordinarlydpakke`);
    } else {
      let url = site.replace(/\s/g, "").toLowerCase();
      navigate(`/${url}`);
    }
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Container>
        <Row className="justify-content-md-center mt-10 md:w-11/12 px-1">
          <Col md={{ span: 6, offset: 1 }} className="md:px-4">
            <div className="font-normal text-md tracking-wider pb-3 font-josefin">
              Vi hjelper deg å finne en pakke som passer perfekt for ditt
              arrangement! Svar på spørsmålene under, så vil vi foreslå den
              pakken som passer best.
            </div>
            <div className="font-bold text-xl tracking-wider pb-4 font-josefin">
              Hvor stort er ditt arrangement?
              <Container>
                <Row className="g-0 mt-2 justify-content-md-center">
                  <Col>
                    <Card
                      className="border-none rounded-none rounded-l-xl hover:bg-neutral-300"
                      onClick={handleClickSmall}
                      style={{
                        backgroundColor: isActiveSmall ? "#e57e22" : "#f5f5f5",
                        color: isActiveSmall ? "white" : "black",
                      }}
                    >
                      <Card.Body>
                        <Card.Img
                           className="h-20 lg:px-6 object-contain"
                          src="https://pl.iwd.no/www/img/home/s-group.svg"
                        ></Card.Img>
                        <Card.Title className="text-xl tracking-wide font-medium text-center pt-2">
                          Lite
                          <br></br>
                          <div className="text-sm font-light">
                            Mindre enn 30
                          </div>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      className="border-none rounded-none hover:bg-neutral-300"
                      onClick={handleClickMedium}
                      style={{
                        backgroundColor: isActiveMedium ? "#e57e22" : "#f5f5f5",
                        color: isActiveMedium ? "white" : "black",
                      }}
                    >
                      <Card.Body>
                        <Card.Img
                          className="h-20 lg:px-6 object-contain"
                          src="https://pl.iwd.no/www/img/home/m-group.svg"
                        ></Card.Img>
                        <Card.Title className="text-xl tracking-wide font-medium text-center pt-2">
                          Medium
                          <br></br>
                          <div className="text-sm font-light">Rundt 60</div>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      className="border-none rounded-none hover:bg-neutral-400 rounded-r-xl"
                      onClick={handleClickLarge}
                      style={{
                        backgroundColor: isActiveLarge ? "#e57e22" : "#f5f5f5",
                        color: isActiveLarge ? "white" : "black",
                      }}
                    >
                      <Card.Body>
                        <Card.Img
                           className="h-20 lg:px-6 object-contain"
                          src="https://pl.iwd.no/www/img/home/l-group.svg"
                        ></Card.Img>
                        <Card.Title className="text-xl tracking-wide font-medium text-center pt-2">
                          Stort
                          <br></br>
                          <div className="text-sm font-light">Mer enn 80</div>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="font-bold text-xl tracking-wider pb-2 font-josefin">
              Hvor er ditt arrangement?
              <Container>
                <Row className="g-0 mt-2 justify-content-md-center md:px-20 px-8">
                  <Col>
                    <Card
                      className="border-none rounded-l-xl hover:bg-neutral-300"
                      onClick={handleClickOutdoors}
                      style={{
                        backgroundColor: isOutdoors ? "#e57e22" : "#f5f5f5",
                        color: isOutdoors ? "white" : "black",
                      }}
                    >
                      <Card.Body>
                        <Card.Img
                           className="h-20 lg:px-10 object-contain"
                          src="https://pl.iwd.no/www/img/home/tree.svg"
                        ></Card.Img>
                        <Card.Title className="text-xl tracking-wide font-medium text-center pt-2">
                          Utendørs
                          <br></br>
                          <div className="text-sm font-light">
                            Ditt arrangement skal være utendørs
                          </div>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      className="border-none rounded-none hover:bg-neutral-300 rounded-r-xl"
                      onClick={handleClickIndoors}
                      style={{
                        backgroundColor: isIndoors ? "#e57e22" : "#f5f5f5",
                        color: isIndoors ? "white" : "black",
                      }}
                    >
                      <Card.Body>
                        <Card.Img
      className="h-20 lg:px-10 object-contain"
      src="https://pl.iwd.no/www/img/home/house.png"
  ></Card.Img>
                        <Card.Title className="text-xl tracking-wide font-medium text-center pt-2">
                          Innendørs
                          <br></br>
                          <div className="text-sm font-light">
                            Ditt arrangement skal være innendørs
                          </div>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>

            <div>
              {data ? (
                <>
                  <div>
                    {isActiveSmall && isIndoors ? (
                      <>
                        <div className="font-bold text-2xl font-josefin tracking-wider pb-2 pt-6 text-center">
                          Vi anbefaler følgende pakker
                        </div>
                        <Row md={2} className="g-2 pb-10">

                        {data ? (
  <>
    {data.map((object) => {
      if (object.id === "Kraftig lydpakke") {
        return (
          <a
            href="#!"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
          >
            <Card
              className="rounded-xl p-2 m-1"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigateToPackage("pakke/"+object.id)
              }
            >
              <Card.Img
                variant="top"
                src={object.bilde}
                style={{
                  width: "100%",
                  height: "30vh",
                  objectFit: "cover",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <Card.Body>
                <Card.Title className="text-3xl tracking-wide font-normal line-clamp-1">
                  {object.id}
                </Card.Title>
                <Card.Subtitle className="font-bold pb-2 text-base">
                  Pris fra {object.startPris+object.dagsPris},-
                </Card.Subtitle>
                <Card.Text className="line-clamp-5">
                  {object.beskrivelse}
                </Card.Text>
              </Card.Body>
            </Card>
          </a>
        );
      }
      return null;
    })}
    <a
      href="#!"
      data-mdb-ripple="true"
      data-mdb-ripple-color="light"
    >
      <Card
        className="rounded-xl p-2 m-1"
        style={{ cursor: "pointer" }}
        onClick={() => navigateToPackage("leverandor/soundboks")}
      >
        <Card.Img
          variant="top"
          src="https://www.bruksanvisningpdf.no/thumbs/products/l/1546738-soundboks-3.jpg" // Set the direct URL to the Soundboks 3 image here
          style={{
            width: "100%",
            height: "30vh",
            objectFit: "cover",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Card.Body>
          <Card.Title className="text-3xl tracking-wide font-normal line-clamp-1">
            Soundboks 3
          </Card.Title>
          <Card.Subtitle className="font-bold pb-2 text-base">
            Pris fra 249,- 
          </Card.Subtitle>
          <Card.Text className="line-clamp-5">
          God lyd og bass for en uforglemmelig og praktisk fest. Enkel booking over hele landet. Fungerer fint for mindre arrangementer og når du har behov for batteri på din høyttaler. {/* Set the description for Soundboks 3 here */}
          </Card.Text>
        </Card.Body>
      </Card>
    </a>
  </>
) : null}
                          {data ? (
                            <>
                              {data.map((object) => {
                                if (
                                  object.id === "Kraftig lydpakke"
                                ) {
                                  
                                }
                                return null;
                              })}
                            </>
                          ) : null}
                        </Row>
                      </>
                    ) : (
                      <div></div>
                    )}
                    {isActiveMedium && isIndoors ? (
                      <>
                        <div className="font-bold text-2xl font-josefin tracking-wider pb-2 pt-6 text-center">
                          Vi anbefaler følgende pakker
                        </div>
                        <Row md={2} className="g-2 pb-10">
                          {data ? (
                            <>
                              {data.map((object) => {
                                if (
                                  object.id === "Kraftig lydpakke" ||
                                  object.id === "Medium Partypakke"
                                ) {
                                  return (
                                    <a
                                      href="#!"
                                      data-mdb-ripple="true"
                                      data-mdb-ripple-color="light"
                                    >
                                      <Card
                                        className="rounded-xl p-2 m-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          navigateToPackage("pakke/"+object.id)
                                        }
                                      >
                                        <Card.Img
                                          variant="top"
                                          src={object.bilde}
                                          style={{
                                            width: "100%",
                                            height: "30vh",
                                            objectFit: "cover",
                                            display: "block",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                          }}
                                        />
                                        <Card.Body>
                                          <Card.Title className="text-3xl tracking-wide font-normal line-clamp-1">
                                            {object.id}
                                          </Card.Title>
                                          <Card.Subtitle className="font-bold pb-2 text-base">
                                            Pris fra {object.startPris+object.dagsPris},-
                                          </Card.Subtitle>
                                          <Card.Text className="line-clamp-5">
                                            {object.beskrivelse}
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </a>
                                  );
                                }
                                return null;
                              })}
                            </>
                          ) : null}
                        </Row>
                      </>
                    ) : (
                      <div></div>
                    )}
                    {isActiveLarge && isIndoors ? (
                      <>
                        <div className="font-bold text-2xl font-josefin tracking-wider pb-2 pt-6 text-center">
                          Vi anbefaler følgende pakker
                        </div>
                        <Row md={2} className="g-2 pb-10">
                          {data ? (
                            <>
                              {data.map((object) => {
                                if (
                                  object.id ===
                                    "Kraftig lydpakke-ekstra bass" ||
                                  object.id === "Stor Partypakke"
                                ) {
                                  return (
                                    <a
                                      href="#!"
                                      data-mdb-ripple="true"
                                      data-mdb-ripple-color="light"
                                    >
                                      <Card
                                        className="rounded-xl p-2 m-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          navigateToPackage("pakke/"+object.id)
                                        }
                                      >
                                        <Card.Img
                                          variant="top"
                                          src={object.bilde}
                                          style={{
                                            width: "100%",
                                            height: "30vh",
                                            objectFit: "cover",
                                            display: "block",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                          }}
                                        />
                                        <Card.Body>
                                          <Card.Title className="text-3xl tracking-wide font-normal line-clamp-1">
                                            {object.id}
                                          </Card.Title>
                                          <Card.Subtitle className="font-bold pb-2 text-base">
                                            Pris fra {object.startPris+object.dagsPris},-
                                          </Card.Subtitle>
                                          <Card.Text className="line-clamp-5">
                                            {object.beskrivelse}
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </a>
                                  );
                                }
                                return null;
                              })}
                              
                            </>
                          ) : null}
                        </Row>
                      </>
                    ) : (
                      <div></div>
                    )}
                    {isActiveSmall && isOutdoors ? (
                      <>
                        <div className="font-bold text-2xl font-josefin tracking-wider pb-2 pt-6 text-center">
                          Vi anbefaler følgende pakker
                        </div>
                        <Row md={2} className="g-2 pb-10">
                          {data ? (
                            <>
                              {data.map((object) => {
                                if (
                                  object.id === "Kraftig lydpakke"
                                ) {
                                  return (
                                    <a
                                      href="#!"
                                      data-mdb-ripple="true"
                                      data-mdb-ripple-color="light"
                                    >
                                      <Card
                                        className="rounded-xl p-2 m-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          navigateToPackage("pakke/"+object.id)
                                        }
                                      >
                                        <Card.Img
                                          variant="top"
                                          src={object.bilde}
                                          style={{
                                            width: "100%",
                                            height: "30vh",
                                            objectFit: "cover",
                                            display: "block",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                          }}
                                        />
                                        <Card.Body>
                                          <Card.Title className="text-3xl tracking-wide font-normal line-clamp-1">
                                            {object.id}
                                          </Card.Title>
                                          <Card.Subtitle className="font-bold pb-2 text-base">
                                            Pris fra {object.startPris+object.dagsPris},-
                                          </Card.Subtitle>
                                          <Card.Text className="line-clamp-5">
                                            {object.beskrivelse}
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </a>
                                  );
                                }
                                return null;
                              })}
                              <a
      href="#!"
      data-mdb-ripple="true"
      data-mdb-ripple-color="light"
    >
      <Card
        className="rounded-xl p-2 m-1"
        style={{ cursor: "pointer" }}
        onClick={() => navigateToPackage("leverandor/soundboks")}
      >
        <Card.Img
          variant="top"
          src="https://www.bruksanvisningpdf.no/thumbs/products/l/1546738-soundboks-3.jpg" // Set the direct URL to the Soundboks 3 image here
          style={{
            width: "100%",
            height: "30vh",
            objectFit: "cover",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Card.Body>
          <Card.Title className="text-3xl tracking-wide font-normal line-clamp-1">
            Soundboks 3
          </Card.Title>
          <Card.Subtitle className="font-bold pb-2 text-base">
            Pris fra 249,- 
          </Card.Subtitle>
          <Card.Text className="line-clamp-5">
          God lyd og bass for en uforglemmelig og praktisk fest. Enkel booking over hele landet. Fungerer fint for mindre arrangementer og når du har behov for batteri på din høyttaler. {/* Set the description for Soundboks 3 here */}
          </Card.Text>
        </Card.Body>
      </Card>
    </a>
                            </>
                          ) : null}
                        </Row>
                      </>
                    ) : (
                      <div></div>
                    )}
                    {(isActiveMedium && isOutdoors) ||
                    (isActiveLarge && isOutdoors) ? (
                      <>
                        <div className="font-bold text-2xl font-josefin tracking-wider pb-2 pt-6 text-center">
                          Vi anbefaler følgende pakker
                        </div>
                        <Row md={2} className="g-2 pb-10">
                          {data ? (
                            <>
                              {data.map((object) => {
                                if (
                                  object.id ===
                                    "Kraftig lydpakke-ekstra bass" ||
                                  object.id === "Stor Partypakke"
                                ) {
                                  return (
                                    <a
                                      href="#!"
                                      data-mdb-ripple="true"
                                      data-mdb-ripple-color="light"
                                    >
                                      <Card
                                        className="rounded-xl p-2 m-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          navigateToPackage("pakke/"+object.id)
                                        }
                                      >
                                        <Card.Img
                                          variant="top"
                                          src={object.bilde}
                                          style={{
                                            width: "100%",
                                            height: "30vh",
                                            objectFit: "cover",
                                            display: "block",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                          }}
                                        />
                                        <Card.Body>
                                          <Card.Title className="text-3xl tracking-wide font-normal line-clamp-1">
                                            {object.id}
                                          </Card.Title>
                                          <Card.Subtitle className="font-bold pb-2 text-base">
                                            Pris fra {object.startPris+object.dagsPris},-
                                          </Card.Subtitle>
                                          <Card.Text className="line-clamp-5">
                                            {object.beskrivelse}
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </a>
                                  );
                                }
                                return null;
                              })}
                            </>
                          ) : null}
                        </Row>
                      </>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FindPackage;