// @ts-nocheck
import {
    collection,
    DocumentData,
    getDocs,
    query,
    doc,
    updateDoc,
  } from "firebase/firestore";
  import { SetStateAction, useEffect, useState } from "react";
  import { Card, Col, Row, Container, Button, Form } from "react-bootstrap";
  // @ts-ignore
  import AdminCard from "../components/AdminCard.tsx";
  // @ts-ignore
  import { db } from "../firebase1.ts";
  // @ts-nocheck
  import ModalComp from "../components/ModalComp.tsx";
  import { useNavigate } from "react-router-dom";
  
  const AdminActiveOrders = () => {
    const [data, setData] = useState<DocumentData[]>([]);
    const [sortedOrders, setSortedOrders] = useState<DocumentData[]>([]);
    const [cancelOrder, setCancelOrder] = useState(false);
    const [accountingDone, setAccountingDone] = useState("");
    const [reasonCancel, setReasonCancel] = useState("");
  
    const [commentOrder, setCommentOrder] = useState("");
    const [commentOrderID, setCommentOrderID] = useState("");
    const [showComment, setShowComment] = useState(false);
    const handleShowCommentCancel = () => setShowComment(false);
  
    const [idCanceledOrder, setIdCanceledOrder] = useState("");
    const [showCancel, setShowCancel] = useState(false);
    const handleShowCancel = () => setShowCancel(false);
  
    const [showCancelReason, setShowCancelReason] = useState(false);
    const handleShowCancelReason = () => setShowCancelReason(false);
  
    const [showFailed, setShowFailed] = useState(false);
    const handleCloseFailed = () => setShowFailed(false);
    const handleShowFailed = () => setShowFailed(true);
  
    const handleCancelation = () => {
      let data = {
        client_reference_id: idCanceledOrder,
        reason: reasonCancel,
      };
  
      cancel(data).then((response) => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        handleShowCancelReason();
        handleShowCancel();
      });
    };
  
    const handleAutoAccounting = (id: string) => {
      let data = {
        client_reference_id: id,
      };
      setAccountingDone(id);
      accounting(data).then((response) => {
        console.log(response.data);
      });
    };
  
    const navigate = useNavigate();
  
    const navigateToPackage = (site: string) => {
      navigate(site);
      window.scrollTo(0, 0);
    };
  
    const handleChangeReason = (e: any) => {
      setReasonCancel(e.target.value);
    };
  
    const handleChangeComment = (e: any) => {
      setCommentOrder(e.target.value);
    };
  
    async function changeCommentDB(kommentar: string, orderId: string) {
      const updateObject = {
        [`comment`]: kommentar,
      };
        const gearUpdate = doc(db, "sales", orderId);
  
      updateDoc(gearUpdate, updateObject)
        .then((e) => {
          setShowComment(false);
          window.location.reload(false);
        })
        .catch((e) => {
          handleShowFailed();
        });
    }
  
    useEffect(() => {
      if (cancelOrder) {
        //Create function to cancel order
        handleShowCancel();
      }
    }, [cancelOrder]);
  
    //HENTE DOKUMENT FRA DATABASE
    const fetchData = async () => {
      const list: SetStateAction<DocumentData[]> = [];
      const q = query(collection(db, "sales"));
      try {
        const doc = await getDocs(q);
        doc.forEach((doc) => {
          if (doc.data().paymentStatus === "paid") {
            list.push({ id: doc.id, ...doc.data() });
          }
        });
        setData([...list]);
      } catch (e) {
        console.log(e);
      }
    };
  
    function capitalize(s: string) {
      return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
    }
  
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    useEffect(() => {
      setSortedOrders(
        [...data].sort(
          (a, b) => b.tid.startDate.seconds - a.tid.startDate.seconds
        )
      );
    }, [data]);
  
    return (
      <div className="mb-10 font-josefin">
        {/* Modal for å sjekke om du vil kansellere ordre*/}
        <ModalComp
          show={showCancel}
          closeModal={() => setShowCancel(false)}
          title="Er du sikker på at du vil kansellere ordren?"
          hide={handleShowCancel}
          footer={
            <Button
              variant="secondary"
              className="text-white bg-[#24292e] text-left ml-16"
              onClick={handleShowCancel}
            >
              Lukk
            </Button>
          }
          body={
            <>
              Å kansellere en ordre kan ikke angres. Er du sikker på at du ønsker
              å kansellere orderen?
              <Row className="mt-2 mb-2">
                <Col lg={{ span: 11, offset: 3 }} xs={{ offset: 2, span: 12 }}>
                  <Button
                    variant="primary"
                    className="text-black border-black hover:border-white hover:text-white hover:bg-gray mt-3 w-3/12 ml-2"
                    onClick={() => setShowCancelReason(true)}
                  >
                    Ja
                  </Button>
  
                  <Button
                    variant="secondary"
                    className="text-white bg-[#24292e] mt-3 w-3/12 ml-2"
                    onClick={handleShowCancel}
                  >
                    Nei
                  </Button>
                </Col>
              </Row>
            </>
          }
        />
        {/* Modal for å skrive årsak til kansellering*/}
        <ModalComp
          show={showCancelReason}
          closeModal={() => setShowCancelReason(false)}
          title="Hvorfor skal ordren kanselleres?"
          hide={handleShowCancelReason}
          footer={
            <Button
              variant="secondary"
              className="text-white bg-[#24292e] text-left ml-16"
              onClick={handleShowCancelReason}
            >
              Lukk
            </Button>
          }
          body={
            <>
              For å kansellere ordren må du skrive en kort begrunnelse. Dette er:
              <Form>
                <Form.Group
                  className="mt-2 mb-1"
                  controlId="form.CancelReason"
                  onChange={handleChangeReason}
                >
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>
              </Form>
              <Row className="mt-2 mb-2 float-right">
                <Col>
                  <Button
                    variant="secondary"
                    className="text-white bg-[#24292e]"
                    onClick={() => {
                      handleCancelation();
                      setReasonCancel();
                    }}
                  >
                    Kanseller ordre
                  </Button>
                </Col>
              </Row>
            </>
          }
        />
        {/* Modal for å legge inn kommentar på ordre*/}
        <ModalComp
          show={showComment}
          closeModal={() => setShowComment(false)}
          title={`Kommentar på ordre ${commentOrderID.slice(0, 6)}`}
          hide={handleShowCommentCancel}
          body={
            <>
              Se og endre kommentar på bestilling.
              <Form>
                <Form.Group
                  className="mt-2 mb-1"
                  controlId="form.Comment"
                  onChange={handleChangeComment}
                >
                  <Form.Control
                    className="font-medium tracking-widers text-black"
                    value={`${commentOrder}`}
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
              </Form>
              <Row className="mt-2 mb-2 float-right">
                <Col>
                  <Button
                    variant="secondary"
                    className="text-white bg-[#24292e]"
                    onClick={() => {
                      changeCommentDB(commentOrder, commentOrderID);
                    }}
                  >
                    Legg til kommentar
                  </Button>
                  <Button
                    variant="secondary"
                    className="text-[#24292e] border-[#24292e] text-left ml-2"
                    onClick={handleShowCommentCancel}
                  >
                    Lukk
                  </Button>
                </Col>
              </Row>
            </>
          }
        />
        {/* Modal for å legge inn kommentar på ordre*/}
        <ModalComp
          show={showFailed}
          closeModal={() => setShowFailed(false)}
          hide={handleCloseFailed}
          body={
            <>
              <p>
                Kunne ikke oppdatere ønsket verdi akkurat nå. Vennligst prøv igjen
                senere, eller ta kontakt med teknisk ansvarlig.
              </p>
            </>
          }
        />
        <Container className="inline-block">
          <Row>
            <Col md={{ span: 5, offset: 1 }} xs={12}>
              <Button
                variant="secondary"
                className="bg-[#24292e] text-white tracking-wider lg:w-8/12 mt-8 rounded-md py-3"
                onClick={() =>
                  navigateToPackage("/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631")
                }
              >
                <div className="text-center">
                  {" "}
                  &#8592; Tilbake til alle bestillinger
                </div>
              </Button>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col md={{ span: 7, offset: 1 }} className="g-4">
            <div className="font-medium text-3xl tracking-wider pb-2 pt-8 lg:pl-0 pl-2">
              Bestillinger gjort idag
            </div>
          </Col>
        </Row>
        <Container>
          <Row className="justify-content-md-center">
            {sortedOrders ? (
              <>
                {sortedOrders.map((item) => {
                  let today = new Date();
                  const orderPlaced = new Date(item.tid.orderPlaced.seconds * 1000);

                  return (
                    <>
                      {today.getFullYear() === orderPlaced.getFullYear() && today.getMonth() === orderPlaced.getMonth() && today.getDate() === orderPlaced.getDate() ? (
                        <>
                          {" "}
                          <Col lg={{ span: 5 }} className="g-4">
                            <AdminCard
                              startDate={capitalize(
                                new Date(
                                  item.tid.startDate.seconds * 1000
                                ).toLocaleString(`no-NO`, options)
                              )}
                              endDate={capitalize(
                                new Date(
                                  item.tid.endDate.seconds * 1000
                                ).toLocaleString(`no-NO`, options)
                              )}
                              packageType={item.pakkeType}
                              orderId={item.id.slice(0, 6)}
                              orderIdLink={item.id}
                              phoneNUmber={item.tlf.slice(3, 11)}
                              totalPrice={item.totalPris}
                              status={item.status}
                              storage={capitalize(item.lager)}
                              onClick={() => {
                                setIdCanceledOrder(item.id);
                                setShowCancel(true);
                              }}
                              visibilityComment={item.comment ? "" : "hidden"}
                              visibilityNoComment={item.comment ? "hidden" : ""}
                              onClickRegnskap={() =>
                                handleAutoAccounting(item.id)
                              }
                              visibility={
                                item.invoiceID || accountingDone === item.id
                                  ? "hidden"
                                  : ""
                              }
                              onClickComment={() => {
                                if (item.comment) {
                                  setCommentOrder(item.comment);
                                } else {
                                  setCommentOrder("");
                                }
  
                                setCommentOrderID(item.id);
                                setShowComment(true);
                              }}
                            />
                          </Col>
                        </>
                      ) : null}
                    </>
                  );
                })}
              </>
            ) : (
              <div>
                <Col md={{ span: 7, offset: 2 }} className="g-4">
                  <Card
                    className="bg-white text-left mt-4 shadow-sm border-gray"
                    onClick={() => navigateToPackage("/admin-levering")}
                  >
                    <Card.Body>
                      <Row>
                        <Col sm={10} className="py-2 px-10">
                          <div className="text-sm font-light tracking-widest py-2 text-black">
                            Det er for tiden ingen bestillinger som har forespurt
                            levering eller henting.
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
            )}
          </Row>
        </Container>
      </div>
    );
  };
  
  export default AdminActiveOrders;