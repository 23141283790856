import { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface DemandProps {
  lager?: string;
  utstyr?: [
    {
      dateUnavailable: string;
      gearName: string;
      id: string;
    }
  ];
}

function capitalize(s: string) {
  return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
}

const Demand: FC<DemandProps> = ({ lager = "", utstyr = [] }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Container className="py-2 h-30">
        <Row>
          <Col xs={2} md={2}>
            <div className="text-md pt-2">{capitalize(lager)}</div>
          </Col>
          {utstyr.map((u) => {
            return (
              <>
                <Col xs={2} md={2}>
                  <div className="text-md pt-2">
                    <div>
                      <div>
                        <strong>{capitalize(u.dateUnavailable)}:</strong>{" "}
                        {capitalize(u.gearName)}
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
        <hr
          style={{
            height: "1px",
            borderTop: "2px solid #ccc",
            width: "100%",
            float: "right",
            marginTop: "1rem",
          }}
        ></hr>
      </Container>
    </div>
  );
};

export default Demand;
