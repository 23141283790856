import { FC } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

import { AiFillCloseCircle, AiFillEdit } from "react-icons/ai";

interface RabattkodeProps {
  rabattkode?: string;
  value?: number;
  count?: number;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
}

const Rabattkode: FC<RabattkodeProps> = ({
  rabattkode = "",
  value= 0,
  count = 0,
  onClickEdit,
  onClickDelete,
}) => {
  return (
    <div>
      <Container className="py-2 h-30">
        <Row>
          <Col xs={4} md={4}>
            <div className="text-md pt-2 ml-2">
              {rabattkode}
            </div>
          </Col>
          <Col xs={2} md={2}>
            <div className="text-md pt-2 ml-2">{value}</div>
          </Col>
          <Col xs={3} md={3}>
            <div className="text-md pt-2 ml-2">{count}</div>
          </Col>
          <Col xs={3} md={3}>
            <div className="float-right mr-2 flex mt-2.5">
              <AiFillEdit
                size={24}
                className="mr-3"
                color="#25A2E9"
                onClick={onClickEdit}
              />
              <AiFillCloseCircle
                size={24}
                color="#FF5656"
                onClick={onClickDelete}
              />
            </div>
          </Col>
        </Row>
        <hr
          style={{
            height: "1px",
            borderTop: "2px solid #ccc",
            width: "100%",
            float: "right",
            marginTop: "1rem",
          }}
        ></hr>
      </Container>
    </div>
  );
};

export default Rabattkode;
