// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
// @ts-ignore
import { db } from "../firebase1.ts";
import { doc, getDoc, updateDoc, arrayRemove } from "firebase/firestore";
import { GrClose } from "react-icons/gr";

interface Task {
  task: string;
  category: string;
  done: boolean;
  id: string;
}

const AdminSupplierTasks: React.FC = () => {
  const supplierId = localStorage.getItem("supplierIdPL") || "";

  const [task, setTask] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [tasks, setTasks] = useState<Task[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  useEffect(() => {
    const fetchTasks = async () => {
      const docRef = doc(db, "suppliers", supplierId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const tasksArray = Object.entries(data.tasks || []).map(
          ([taskId, task]) => ({
            ...task,
            id: taskId,
          })
        );
        setTasks(tasksArray);

        const categoriesList = data.packages.map((pck: any) => pck.kategori);
        setCategories(categoriesList);
      }
    };

    fetchTasks();
  }, [supplierId]);

  const addTask = async () => {
    const newTask = { task, category, done: false };
    const docRef = doc(db, "suppliers", supplierId);
    const newTaskId = String(tasks.length);

    await updateDoc(docRef, {
      [`tasks.${newTaskId}`]: newTask,
    });

    setTasks((prev) => [...prev, { ...newTask, id: newTaskId }]);
    setTask("");
    setCategory("");
  };

  const markTaskAsDone = async (taskToUpdate: Task) => {
    const docRef = doc(db, "suppliers", supplierId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const tasksObject = data.tasks || {};

      const tasksArray = Object.entries(tasksObject).map(([_id, task]) => task);

      const updatedTasksArray = tasksArray.map((task) =>
        task.task === taskToUpdate.task &&
        task.category === taskToUpdate.category
          ? { ...task, done: !task.done }
          : task
      );

      const newTasksObject = updatedTasksArray.reduce((obj, task, index) => {
        obj[index] = task;
        return obj;
      }, {});

      await updateDoc(docRef, { tasks: newTasksObject });

      setTasks(updatedTasksArray);
    }
  };

  const deleteTask = async (taskToDelete: Task) => {
    const docRef = doc(db, "suppliers", supplierId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const tasksObject = data.tasks || {};

      const tasksArray = Object.entries(tasksObject).map(([_id, task]) => task);

      const updatedTasksArray = tasksArray.filter(
        (task) =>
          task.task !== taskToDelete.task ||
          task.category !== taskToDelete.category
      );

      const newTasksObject = updatedTasksArray.reduce((obj, task, index) => {
        obj[index] = task;
        return obj;
      }, {});

      await updateDoc(docRef, { tasks: newTasksObject });

      setTasks(updatedTasksArray);
    }
  };

  const sortedTasks = [...tasks].sort(
    (a, b) => Number(a.done) - Number(b.done)
  );

  return (
    <Container>
      <div className="font-medium text-3xl tracking-wider pb-2 pt-10 lg:pl-28 lg:ml-0">
        Sjekkliste
      </div>

      <Row>
        <Col className="lg:pl-32 lg:pt-6 mb-10 " lg={5}>
          <div className="font-medium text-xl tracking-wider pb-4">
            Ny oppgave
          </div>
          <div className="italic tracking-wide text-sm pb-1">
            Opprett nye oppgaver for å holde kontroll på utleien.
          </div>
          <Form>
            <Form.Group className="mt-2 mb-1" controlId="form.Task">
              <Form.Label>Oppgave</Form.Label>
              <Form.Control
                type="text"
                placeholder="Skriv oppgave her..."
                value={task}
                onChange={(e) => setTask(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="form.Category">
              <Form.Label>Pakke</Form.Label>
              <Form.Control
                as="select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Ingen pakke</option>
                {categories.map((category, index) => (
                  <option value={category} key={index}>
                    {capitalize(category)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Button
              className="tracking-wide h-10 mt-6"
              onClick={() => addTask()}
              style={{
                backgroundColor: "#e57e22",
                border: "none",
                fontWeight: 500,
              }}
            >
              <div className="tracking-wide flex text-sm">
                <div className="">Opprett oppgave </div>
              </div>
            </Button>
          </Form>
        </Col>

        <Col className="lg:pl-32 lg:pt-6 mb-10" lg={6}>
          <div className="font-medium text-xl tracking-wider pb-2">
            Oppgaveliste
          </div>
          <div className="italic tracking-wide text-sm pb-1">
            Marker oppgaver som er utført.
          </div>

          {sortedTasks.map((task, taskId) => (
            <div
              key={taskId}
              className={`my-3 p-2 rounded-lg ${
                task.done ? "bg-gray-200" : "bg-white"
              } shadow-sm flex items-center`}
            >
              {/* Checkbox */}
              <div className="w-6">
                <input
                  type="checkbox"
                  id={`task${taskId}`}
                  checked={task.done}
                  onChange={() => markTaskAsDone(task)}
                />
                <label htmlFor={`task${taskId}`}></label>
              </div>

              {/* Task text */}
              <span
                className={`flex-grow ${
                  task.done ? "line-through text-gray-500" : ""
                } ml-3`}
              >
                {task.task}
              </span>

              {/* Category */}
              {task.category && (
                <div className="text-sm text-gray-500 italic ml-4 mr-2">
                  Kategori: {task.category}
                </div>
              )}

              {/* Close icon */}
              <div
                onClick={() => deleteTask(task)}
                className="text-gray-800 cursor-pointer p-2"
              >
                <GrClose />
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default AdminSupplierTasks;
