/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-nocheck

import {
  query,
  getDoc,
  getDocs,
  collection,
  addDoc,
  doc,
  setDoc,
} from "firebase/firestore";

import { db } from "../firebase1.ts";
import { getRandomValues } from "crypto";

export async function getGearData(utstyr: string) {
  const docRef = doc(db, "gear", utstyr);
  try {
    const doc = await getDoc(docRef);
    const data = doc.data();
    return data;
  } catch (e) {
    console.log("No such document: " + e);
  }
}

async function getData(colId: string, docId: string) {
  const docRef = doc(db, colId, docId);
  try {
    const doc = await getDoc(docRef);
    const data = doc.data();
    return data;
  } catch (e) {
    console.log("No such document! " + e);
  }
}

export async function getPackageDetails(packageType: any) {
  const docRef = doc(db, "packages", packageType);
  try {
    const doc = await getDoc(docRef);
    const data = doc.data();
    return data;
  } catch (e) {
    console.log("No such document! " + e);
  }
}

export async function getOrderDetails(orderId: any) {
  const docRef = doc(db, "sales", orderId);
  try {
    const doc = await getDoc(docRef);
    const data = doc.data();
    return data;
  } catch (e) {
    console.log("No such document! " + e);
  }
}

export async function getEquipment(packageType: string) {
  let packageData = await getData("packages", packageType);
  let equipBase = Object.keys(packageData.innhold);

  let equipmentDataList = await Promise.all(equipBase.map(equip => getData("gear", equip)));

  let equipment = equipmentDataList.map((data, index) => {
      return {
          ...data,
          count: packageData.innhold[equipBase[index]],
          id: equipBase[index]
      };
  });

  return equipment;
}

export async function getMonteringsInfo(packageType: string) { 
  let packageData = await getData("packages", packageType);
  let monteringsInfo = packageData.montering;

  return monteringsInfo;
}

export async function getGearFromOrder(extraArray: Array) {
  let addons = [];
  if (extraArray != null) {
    for (let i in extraArray) {
      let data = await getData("gear", i);
      addons.push(data);
      addons[addons.length - 1].count = extraArray[i];
    }
  }
  return addons;
}

export async function getExtras(packageType: string) {
  let packageData = await getData("packages", packageType);
  let addonBase = packageData.tillegg;

  let addonDataList = await Promise.all(addonBase.map(addon => getData("gear", addon)));

  let addons = addonDataList.map((data, index) => {
      return {
          ...data,
          count: 0,
          id: addonBase[index]
      };
  });

  return addons;
}

let deliveryServiceBooked = {
  henting: false,
  levering: false,
  montering: false,
  adresse: "",
};

export async function checkOut(
  phonenumber: string,
  packageType: string,
  storage: any,
  insurance: any,
  startDate: number,
  endDate: number,
  rabattkode: string,
  deliveryServiceBooked: any[],
  chosenAddons: any[]
 ) {
  let packageData = await getData("packages", packageType);

  let totalDays = (endDate - startDate) / 86400000;
  let time = {
    startDate: startDate,
    endDate: endDate,
    totalDays: totalDays,
    orderPlaced: new Date(),
  };

  let deliveryService = ["levering", "henting", "montering", "adresse"];
  for (let i = 0; i < deliveryService.length; i++) {
    let serviceData = await getData("deliveryService", deliveryService[i]);
    let incomingServiceData = deliveryServiceBooked[deliveryService[i]];

    if (deliveryService[i] === "levering" || deliveryService[i] === "henting") {
      serviceData.order = incomingServiceData.order;
      serviceData.tidspunkt = incomingServiceData.tidspunkt;
    } else if (deliveryService[i] === "montering") {
      serviceData.order = incomingServiceData.order;
    } else {
      serviceData.order = incomingServiceData;
    }

    deliveryService[i] = serviceData;
  }

  let allAddons = await getExtras(packageType);

  let rabattkodeValue;
  let rabattkodeCount;

  if (rabattkode !== "") {
    let rabattkoden = await getData("discount", rabattkode);
    rabattkodeValue = rabattkoden.value;
    rabattkodeCount = rabattkoden.valid;
    if (rabattkodeCount === 0) {
      rabattkode === "";
    }
  }

  let payment = "Ikke betalt";

  let packagePrice =
    packageData.startPris + packageData.dagsPris * time.totalDays;

  if (rabattkode !== "" && rabattkodeValue < 1) {
    let rabattertVerdiPackagePrice = Math.ceil(packagePrice * rabattkodeValue);
    packagePrice = packagePrice - rabattertVerdiPackagePrice;
  } else if (rabattkode !== "" && rabattkodeValue > 1) {
    packagePrice = packagePrice - rabattkodeValue;
  }

  let totalPrice = packagePrice;

  let deliveryPrice = 0;
  if (totalPrice < 500) {
    deliveryPrice = 399;
  }

  deliveryService.forEach((service) => {
    service.order ? (deliveryPrice += service.pris) : "";
  });
  totalPrice += deliveryPrice;

  let totalAddonPrice = 0;
  allAddons.forEach((addon) => {
    if (chosenAddons[addon.navn]) {
      totalAddonPrice +=
        addon.startPris * addon.count +
        addon.dagsPris * addon.count * time.totalDays;
    }
  });

  if (rabattkode !== "" && rabattkodeValue < 1) {
    let rabattertVerdiPackagePrice = Math.ceil(
      totalAddonPrice * rabattkodeValue
    );
    totalAddonPrice = totalAddonPrice - rabattertVerdiPackagePrice;
  } else if (rabattkode !== "" && rabattkodeValue > 1) {
    totalAddonPrice = totalAddonPrice - rabattkodeValue;
  }

  totalPrice += totalAddonPrice;

  insurance ? (totalPrice += packageData.forsikring) : "";

let gear = packageData.innhold;

allAddons.forEach((addon) => {
  if (chosenAddons[addon.id] > 0) {
    if (!gear[addon.id]) {
      gear[addon.id] = 0;  // Hvis gear-objektet ikke allerede inneholder dette tilleggsutstyret, sett antall til 0
    }
    gear[addon.id] += chosenAddons[addon.id];  // Legg til antall tilleggsutstyr
  } else {
    gear[addon.id] += addon.count;  // Legg til antall fra addon dersom det eksisterer i pakken
  }
});

  if (deliveryService[3].order === "") {
    deliveryService[0].order = false;
  } else {
    deliveryService[0].order = true;
  }

  let orderId;

  let totalPriceDUMMY = await localStorage.getItem("totalprice");
  let totalAddonPriceDUMMY = await localStorage.getItem("totalAddonPrice");

  await addDoc(collection(db, "sales"), {
    tlf: "+47" + phonenumber,
    lager: storage,
    tid: time,
    deliveryService: deliveryService,
    utstyr: gear,
    pakkeType: packageType,
    pakkePris: packagePrice,
    tilleggsPris: parseInt(totalAddonPriceDUMMY, 10),
    forsikring: { bestilt: insurance, pris: packageData.forsikring },
    totalPris: parseInt(totalPriceDUMMY, 10),
    betaling: payment,
    rabattkode: rabattkode,
    smsSent: false,
    status: "Ikke hentet",
    comment: "",
  })
    .then(function (docRef) {
      orderId = docRef.id;
    })
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });

  return orderId;
}

export async function checkAvaliablePackages(storage, startDate, endDate) {
  let gearUnavailable = new Array();

  let date = new Date(startDate);
  let date1 = new Date(endDate);
  let diffTime = date1.getTime() - date.getTime();
  let diffDays = diffTime / (1000 * 3600 * 24);

  let dateGov = new Array();
  let dateRefs = new Array();
  let currentDay = new Date(date);
  for (let i = 0; i < diffDays; i++) {
    let dbRef =
      "" +
      currentDay.getDate() +
      "." +
      currentDay.getMonth() +
      "." +
      currentDay.getFullYear();
    let dateRef =
      "" +
      currentDay.getDate() +
      "." +
      (currentDay.getMonth() + 1) +
      "." +
      currentDay.getFullYear();
    currentDay.setDate(currentDay.getDate() + 1);

    dateGov.push(await getData("gearManagement", dbRef));
    dateRefs.push(dateRef);
  }

  const qPackages = query(collection(db, "packages"));
  const qPackagesSnapshot = await getDocs(qPackages);
  let packagesDB = new Object();
  qPackagesSnapshot.forEach((doc) => {
    packagesDB[doc.id] = doc.data().innhold;
  });

  const q = query(collection(db, "gear"));
  const querySnapshot = await getDocs(q);
  let gearDB = new Object();
  querySnapshot.forEach((doc) => {
    gearDB[doc.id] = [doc.data()];
  });

  Object.keys(packagesDB).map(function (k) {
    for (let i = 0; i < dateGov.length; i++) {
      let gear = packagesDB[k];
      let key = Object.keys(gear)[i];

      if (gearDB[key] !== undefined) {
        let capacity = gearDB[key][0].kapasitet[storage];
        let gearDBRef = dateGov[i];
        let equipment = gearDBRef[storage];

        for (let key in gear) {
          let gearCount = gear[key];
          let gearDBRefCount = equipment[key];

          if (gearDBRefCount + gearCount >= capacity) {
            if (!gearUnavailable.includes(Object.keys(packagesDB)[i])) {
              gearUnavailable.push(Object.keys(packagesDB)[i]);
            }
          }
        }
      }
    }
  });
  let output = {
    gearUnavailable: gearUnavailable,
  };
  return output;
}

export async function checkAvailability(gear, storage, start, endDate) {
  let totalAvailable = true;
  let gearAvailable = new Object();
  let gearUnavailable = new Array();

  let totDays = (endDate - start) / 86400000 + 1;
  let dateGov = new Array();
  let dateRefs = new Array();
  let currentDay = new Date(start);
  for (let i = 0; i < totDays; i++) {
    let dbRef =
      "" +
      currentDay.getDate() +
      "." +
      currentDay.getMonth() +
      "." +
      currentDay.getFullYear();
    let dateRef =
      "" +
      currentDay.getDate() +
      "." +
      (currentDay.getMonth() + 1) +
      "." +
      currentDay.getFullYear();
    currentDay.setDate(currentDay.getDate() + 1);

    dateGov.push(await getData("gearManagement", dbRef));
    dateRefs.push(dateRef);
  }

  const q = query(collection(db, "gear"));
  const querySnapshot = await getDocs(q);
  let gearDB = new Object();
  querySnapshot.forEach((doc) => {
    gearDB[doc.id] = [doc.data()];
  });

  for (let key in Object.keys(gear)) {
    for (let z = 0; z < dateGov.length; z++) {
      let booked = dateGov[z][storage][Object.keys(gear)[key]];
      let capacity = gearDB[Object.keys(gear)[key]][0].kapasitet[storage];
      let wanted = gear[Object.keys(gear)[key]];
      if (booked + wanted > capacity) {
        totalAvailable = false;
        gearUnavailable.push({
          gearName: gearDB[Object.keys(gear)[key]][0].navn,
          gearId: Object.keys(gear)[key],
          dateUnavailable: dateRefs[z],
        });
      }
      gearAvailable[Object.keys(gear)[key]] = capacity - booked;
    }
  }

  let output = {
    available: totalAvailable,
    allGear: gearAvailable,
    gearUnavailable: gearUnavailable,
  };
  return output;
}

export async function gearManagement(gear, storage, startDateSec, endDateSec, reverse) {
  let dateGov = new Array();
  let startDate = new Date(startDateSec * 1000);
  let endDate = new Date(endDateSec * 1000);
  let totalDays = (endDate - startDate) / 86400000 + 1;

  startDate.setDate(startDate.getDate());

  for (let i = 0; i < totalDays; i++) {
    let currentDay = startDate;
    let dbRef =
      "" +
      currentDay.getDate() +
      "." +
      currentDay.getMonth() +
      "." +
      currentDay.getFullYear();
    currentDay.setDate(currentDay.getDate() + 1);

    let e = await getDoc(doc(db, "gearManagement", dbRef));

    dateGov.push(e.data());
  }

  for (let key in Object.keys(gear)) {
    for (let z = 0; z < dateGov.length; z++) {
      let booked = dateGov[z][storage][Object.keys(gear)[key]];
      let wanted = gear[Object.keys(gear)[key]];

      let newBooked = reverse ? booked - wanted : booked + wanted;
      dateGov[z][storage][Object.keys(gear)[key]] = newBooked;
    }
  }

  startDate = new Date(startDateSec * 1000);
  startDate.setDate(startDate.getDate());

  for (let i = 0; i < totalDays; i++) {
    let currentDay = startDate;
    let docRef =
      "" +
      currentDay.getDate() +
      "." +
      currentDay.getMonth() +
      "." +
      currentDay.getFullYear();
    currentDay.setDate(currentDay.getDate() + 1);

    await setDoc(doc(db, "gearManagement", docRef), dateGov[i]);
  }

  return true;
}