
// @ts-nocheck
import {
    collection,
    DocumentData,
    getDocs,
    getDoc,
    query,
    arrayRemove,
    doc,
    updateDoc,
  } from "firebase/firestore";
  import { SetStateAction, useEffect, useState } from "react";
  import { Card, Col, Row, Button, Form, Container } from "react-bootstrap";
  import ModalComp from "../components/ModalComp.tsx";
  import { db } from "../firebase1.ts";
  import { useNavigate } from "react-router-dom";
  
  import { getFunctions, httpsCallable } from "firebase/functions";
  import { app } from "../firebase1.ts";
  import SupplierCard from "../components/SupplierCard.tsx";
  import { useLocation } from "react-router-dom";
  import { GrClose } from "react-icons/gr";
  import Checklist from "../components/Checklist.tsx";
  
  
  
  const AdminChecklist = () => {
  
    var arr = new Array(); 
  
    const [tasks, setTasks] = useState(); 

    const [task, setTask] = useState(""); 
    const [category, setCategory] = useState(""); 
    const [location, setLocation] = useState("trondheim")

    const categories = ["Vask", "Reparasjon", "Oppussing", "Frakt", "Innkjøp", "Kontroll"]

    const locations = ["trondheim", "stavanger", "lorenskog", "vinterbro", "asker", "bergen", "kristiansand"]

    const fetchData = async () => {

      const l: SetStateAction<DocumentData[]> = [];
      const maintenanceRef =  query(collection(db, "storage-stamp")); // chosen-location
  
      try {
        const doc = await getDocs(maintenanceRef)
  
        doc.forEach((doc) => {
          if (doc.id == location) {
            doc.data().maintenance.forEach(task => {
              l.push({id: task.id, done: task.done, task:task.task, category:task.category})
            });
          }
        })
  
      }
      catch (error) {
        console.error("ERROR GETTING MAINTENANCE DOCS ", error)
      }
  
      setTasks(l);
    };

    const markTaskAsDone = async (taskToUpdate: Task) => {
      
      console.log("task done")
  
      const docref = doc(db, "storage-stamp", location);
  
  
      const l = tasks.map( (task) => task.id == taskToUpdate.id ? {id : task.id, done : !task.done, category: task.category, task: task.task} : {id: task.id, done: task.done, category: task.category, task: task.task} )
  
      
      setTasks(l) 
  
      const data = {
        [`maintenance`] : l
      }
      try {
  
      await updateDoc(docref, data)
      .then( await fetchData() );
      }
      catch (error) {
        console.error("ERROR UPDATING MAINTENANCE", error)
      }
      
    };
  
    const uncheckAll = async () => { 
  
      const docref = doc(db, "storage-stamp", location);
  
      const l = tasks.map( (task) =>  { return {id: task.id, done: false, category: task.category, task:task.task}} )
      
      setTasks(l) 
  
      console.log("L", l)
      console.log("TASKS" , tasks)
  
      const data = {
        [`maintenance`] : l
      }
      try {
  
      await updateDoc(docref, data)
      .then( await fetchData() );
      }
      catch (error) {
        console.error("ERROR UPDATING MAINTENANCE", error)
      }
  
    }

    const addMaintenanceTask = async () => {
        const docRef = doc(db, "storage-stamp", location);
        const newTaskId = String(tasks.length+1);
        const newTask = {id:newTaskId, task:task, category:category, done:false };
    
        var l = tasks.map((task) => {return {id : task.id, done : false, category: task.category, task: task.task}})
        l.push(newTask)
        setTasks(l)
        
        await updateDoc(docRef, {
          [`maintenance`]: l
        });
    
      };

    const deleteTask =async (taskToDelete: Task) => {

      const maintenanceRef = doc(db, 'storage-stamp', location)

      const l = tasks.filter( (task) => task.id != taskToDelete.id )
      
      setTasks(l); 

      const data = {
        [`maintenance`] : l
      }

      try {
  
        await updateDoc(maintenanceRef, data)
        .then( await fetchData() );
        }
        catch (error) {
          console.error("ERROR DELETING MAINTENANCE", error)
        }

    }
    
  useEffect( () => {
    const fetchMaintenanceTasks = async () => {

      const l: SetStateAction<DocumentData[]> = [];
      const maintenanceRef =  query(collection(db, "storage-stamp")); // chosen-location
  
      try {
        const doc = await getDocs(maintenanceRef)
  
        doc.forEach((doc) => {
          if (doc.id == location) {
            doc.data().maintenance.forEach(task => {
              l.push({id: task.id, done: task.done, task:task.task, category:task.category})
            });
          }
        })
  
      }
      catch (error) {
        console.error("ERROR GETTING MAINTENANCE DOCS ", error)
      }
  
      setTasks(l);
    };

    fetchMaintenanceTasks(); 
  
    console.log("MAINTENANCE TASKS: ", tasks)
  }, [ location ])
  
  
  const [reports, setReports] = useState([]); // Tilstand for å lagre rapporter
  const [showReports, setShowReports] = useState(false); // Tilstand for å styre modalvisning
  const handleShowReports = () => setShowReports(true);

  const fetchReports = async () => {
    const fetchedReports = [];
    const docRef = doc(db, "storage-stamp", location); // Endret til spesifikk lokasjon
  
    try {
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists() && docSnapshot.data().reports) {
        fetchedReports.push(...docSnapshot.data().reports);
      }
      setReports(fetchedReports);
    } catch (error) {
      console.error("Feil ved henting av rapporter: ", error);
    }
  };
  
  useEffect(() => {
    if (location) {
      setReports([]);
      fetchReports();
    }
  }, [location]); 

  // Håndterer lukking av rapportmodalen
  const handleCloseReports = () => {
    setShowReports(false);
  };

    return (
        <Container>
                <ModalComp
  show={showReports}
  closeModal={() => setShowReports(false)}
  hide={handleCloseReports}
  body={
    <>
      <div style={{ fontSize: '24px', fontWeight: '500', paddingBottom: '16px', paddingTop: '16px', textAlign: 'center' }}>
        Rapporter i {location}
      </div>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        {[...reports].sort((a, b) => new Date(b.date) - new Date(a.date)).map((report, index) => {
          const readableDate = new Date(report.date).toLocaleString('no-NO', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          });
          return (
            <div key={index} style={{ backgroundColor: '#f3f3f3', borderRadius: '4px', marginBottom: '10px', padding: '10px', borderBottom: '1px solid #ddd' }}>
              <div><strong>Dato:</strong> {readableDate}</div>
              <div><strong>Beskrivelse:</strong> {report.description}</div>
            </div>
          );
        })}
      </div>
    </>
  }
/>
          <div className="font-medium text-3xl tracking-wider pb-2 pt-10 lg:pl-28 lg:ml-0 font-josefin">
            Vedlikehold av lagre
          </div>
    
          <Row>
            <Col className="lg:pl-32 lg:pt-6 mb-10 " lg={5}>
              <div className="font-medium text-xl tracking-wider pb-4">
                Egendefinerte oppgaver
              </div>
              <div className="italic tracking-wide text-sm pb-1">
                Opprett ny oppgave for oppfølging av vedlikeholder.
              </div>
              <Form>
              <Form.Group controlId="form.Location">
                  <Form.Label>Lager</Form.Label>
                  <Form.Control
                    as="select"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  >
                    <option value="">Velg Lager</option>
                    {locations.map((location, index) => (
                      <option value={location} key={index}>
                        {location.charAt(0).toUpperCase() + location.slice(1)}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                
                <Form.Group className="mt-2 mb-1" controlId="form.Task">
                  <Form.Label>Oppgave</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Skriv oppgave her..."
                    value={task}
                    onChange={(e) => setTask(e.target.value)}
                  />
                </Form.Group>
    
                <Form.Group controlId="form.Category">
                  <Form.Label>Kategori</Form.Label>
                  <Form.Control
                    as="select"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="">Ingen kategori</option>
                    {categories.map((category, index) => (
                      <option value={category} key={index}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                
                
    
                <Button
                  className="tracking-wide h-10 mt-6"
                  onClick={() => addMaintenanceTask()}
                  style={{
                    backgroundColor: "#e57e22",
                    border: "none",
                    fontWeight: 500,
                  }}
                >
                  <div className="tracking-wide flex text-sm">
                    <div className="">Opprett oppgave</div>
                  </div>
                </Button>

                <Button
        className="tracking-wide h-10 mt-6 ml-4"
        onClick={handleShowReports}
        style={{
          backgroundColor: "grey",
          border: "none",
          fontWeight: 500,
        }}
      >
        Se rapporter i {location}
      </Button>
                
              </Form>
            </Col>
    
            <Col className="lg:pl-32 lg:pt-6 mb-10" lg={6}>
              
              <div className="font-medium text-xl tracking-wider pb-4">
                Status på egendefinerte oppgaver i {location.charAt(0).toUpperCase() + location.slice(1)}
              </div>
           
              <div className="italic tracking-wide text-sm pb-1">
                        Oppgaver
              </div>

              <Checklist tasks={tasks ? tasks : []} markTaskAsDone={markTaskAsDone} admin={true} deleteTask={deleteTask}></Checklist>
              
              
            </Col>
            
          </Row>
        </Container>
      );
    
  };
  
  export default AdminChecklist;