import {ReactNode, FC} from 'react';

interface CardProps {
    color?: string;
    inline?: Boolean;
    className?: String;
    children?: ReactNode;
}

const Card: FC<CardProps> = ({
    color = 'white',
    inline = true,
    className = '',
    children,
}) => {
    return (
        <div 
          className={[
            'p-4 items-center justify-center relative',
            `bg-${color}`,
            inline ? 'inline-flex' : 'flex',
            className,
          ].join(' ')}
        >
            {children}
        </div>
    );
};

export default Card;