import { Button, Card, Container, Form, Modal, Row } from "react-bootstrap";
import React, { FC } from "react";

interface InventoryControlEditProps {
  navn?: string;
  count?: any;
  bilde?: any;
  onChange?: any;
  onClick?: any;
  id?: string;
}

const InventoryControlEdit: FC<InventoryControlEditProps> = ({
  navn = "",
  count,
  onChange,
  onClick,
  bilde,
  id="",
}) => {
  return (
    <>
      <Card id={id} style={{ width: "22rem", marginBottom: "2rem" }}>
        <Card.Img
          variant="top"
          src={bilde}
          style={{
            width: "20vh",
            height: "20vh",
            objectFit: "contain",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Card.Body>
          <Card.Title>
            <div>{navn}</div>
          </Card.Title>
          <Form>
            <Form.Group controlId="formUpdatedStorageNumber">
              <Form.Control
                type="number"
                placeholder={"Nåværende antall: " + count}
                onChange={onChange}
              ></Form.Control>
            </Form.Group>
            <>

                <Button
                  className="lg:mt-0 mt-3 tracking-wide h-12 w-100"
                  onClick={onClick}
                  style={{
                    backgroundColor: "#e57e22",
                    border: "none",
                    fontWeight: 400,
                  }}
                  //type="submit"
                >
                  Oppdater antall
                </Button>
             
            </>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default InventoryControlEdit;
