import { FC, useState } from "react";
import { Card } from "react-bootstrap";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsFillTelephoneFill, BsMusicNote } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import {
  GrStatusUnknown,
  GrStatusCritical,
  GrStatusDisabled,
} from "react-icons/gr";

import { GiNotebook } from "react-icons/gi";

import { FaRegComment, FaRegCommentDots } from "react-icons/fa";

import "../style/icons.css";

interface SupplierCardProps {
  startDate?: any;
  endDate?: any;
  packageType?: string;
  orderId?: any;
  phonenumber?: number;
  totalPrice?: number;
  orderIdLink?: string;
  visibilietyComment?: string;
  visibilietyNoComment?: string;
  status?: string;
  onClickCancelOrder?: () => void;
  onClickConfirmOrder?: () => void;
  onClickComment?: () => void;
  onClickCommentInternal?: () => void;
  disableConfirmOrderButton?: boolean;
  hidden?: string;
  done?: boolean;
}

const SupplierCard: FC<SupplierCardProps> = ({
  startDate,
  endDate,
  packageType = "",
  orderId,
  orderIdLink,
  phonenumber,
  totalPrice,
  visibilietyComment,
  visibilietyNoComment,
  status = "Ikke bekreftet",
  onClickCancelOrder,
  onClickConfirmOrder,
  onClickComment,
  onClickCommentInternal,
  disableConfirmOrderButton,
  hidden,
  done,
}) => {
  const navigate = useNavigate();

  const navigateToOtherSite = (url: string) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="tracking-wide font-light ">
      <Col>
        <Card className="px-6 py-3 mb-4 border-2 rounded-xl hover:drop-shadow-xl hover:text-black shadow-md border-gray">
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigateToOtherSite(`/order-leverandor/${orderIdLink}`)
            }
          >
            <Col className="py-6 px-3">
              <div className="font-medium text-xl tracking-wider pb-4">
                {startDate} - {endDate}
              </div>
              <div>
                <strong className="font-medium">{packageType}</strong> -{" "}
                {orderId}
              </div>
            </Col>
            <hr />
            <Row>
              <Col className="py-6 px-3">
                <div className="flex">
                  <div>
                    <BsFillTelephoneFill size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {phonenumber}
                    </strong>
                  </div>
                </div>
              </Col>
              <Col className="py-6 px-3">
                <div className="flex">
                  <div>
                    <MdAttachMoney size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {totalPrice} ,-
                    </strong>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="py-6 px-3">
                <div className="flex">
                  {status === "Kansellert" && !done ? (
                    <>
                      <div className="float-right">
                        <GrStatusCritical
                          className="icon_kansellert"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {status === "Ikke bekreftet" && !done ? (
                    <>
                      <div className="float-right">
                        <GrStatusUnknown
                          className="icon_ikkebekreftet"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {status === "Bekreftet" && !done ? (
                    <>
                      <div className="float-right">
                        <GrStatusDisabled
                          className="icon_bekreftet"
                          color="[#95bb72]"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {done ? (
                    <>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          Fullført
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="flex">
            <Container>
              <Row md={1} sm={1} xs={1} lg={1}>
                <div className="float-right lg:pl-10">
                  {status === "Kansellert" ? null : (
                    <>
                      {done ? null : (
                        <Col xs={12} md={12} lg={12} sm={12}>
                          <Button
                            variant="secondary"
                            className="mt-3 text-white border-none bg-[#ff4122] hover:bg-[#e3371b] text-left float-right inline ml-2"
                            onClick={onClickCancelOrder}
                          >
                            Kanseller
                          </Button>
                        </Col>
                      )}
                    </>
                  )}

                  {status === "Ikke bekreftet" && !done ? (
                    <div>
                      {" "}
                      <Col xs={12} md={12} lg={12} sm={12}>
                        <Button
                          variant="secondary"
                          style={{
                            width: "120px",
                            backgroundColor: isLoading ? "#8DDA63" : "#94CD74",
                          }}
                          className="mt-3 border-none text-left float-right inline ml-2 hover:bg-[#7fb85f] hover:text-white"
                          onClick={() => {
                            setIsLoading(true);
                            if (onClickConfirmOrder) {
                              onClickConfirmOrder();
                            }
                          }}
                          disabled={isLoading}
                        >
                          <div className="flex justify-center">
                            {isLoading ? (
                              <BsMusicNote
                                size={22}
                                className="mr-1 animate-bounce"
                              />
                            ) : (
                              <>Bekreft ordre</>
                            )}
                          </div>
                        </Button>
                      </Col>
                    </div>
                  ) : null}
                </div>
              </Row>

              {!done ? (
                <Col xs={{ span: 12 }}>
                  <Button
                    variant="secondary"
                    className={`text-pl_orange mt-1 border-none text-left float-right inline hover:text-[#d56b0f] hover:bg-white`}
                    onClick={onClickCommentInternal}
                  >
                    <GiNotebook size={26} />
                  </Button>
                </Col>
              ) : null}

              <Col xs={12} md={12} lg={12} sm={12}>
                <Button
                  variant="secondary"
                  className={`text-[#94CD74] hover:text-[#7fb85f] hover:bg-white mt-1 border-none text-left float-right inline ${visibilietyComment}`}
                  onClick={onClickComment}
                >
                  <FaRegCommentDots size={26} />
                </Button>
              </Col>

              {!done ? (
                <Col xs={12} md={12} lg={12} sm={12}>
                  <Button
                    variant="secondary"
                    className={`mt-1 border-none text-left  hover:bg-white float-right ml-2 inline text-black hover:text-[#7fb85f] ${visibilietyNoComment}`}
                    onClick={onClickComment}
                  >
                    <FaRegComment size={26} />
                  </Button>
                </Col>
              ) : null}
            </Container>
          </div>
        </Card>
      </Col>
    </div>
  );
};

export default SupplierCard;
