// @ts-nocheck
import { collection, DocumentData, getDocs, query } from "firebase/firestore";
import { SetStateAction, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import {
  checkOut,
  getExtras,
  getEquipment,
  checkAvaliablePackages,
} from "../components/Backend.tsx";
import Loading from "../components/Loading.tsx";
import { db } from "../firebase1.ts";

const SearchPackages = () => {
  let { typePackage, location, startDate, endDate } = useParams();
  const navigate = useNavigate();

  const [packageType, setPackageType] = useState(typePackage);
  const [loc, setLocation] = useState(location);
  const [sdate, setStartDate] = useState(startDate);
  const [edate, setEndDate] = useState(endDate);

  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState<DocumentData[]>([]);
  const [availablePackages, setAvailablePackages] = useState([]);

  const navigateToPackage = (site: string) => {
    if (site === "Ordinær lydpakke") {
      navigate(`/pakke/ordinarlydpakke/${sdate}/${edate}`);
    } else {
      let url = site.replace(/\s/g, "").toLowerCase();
      navigate(`/pakke/${url}/${sdate}/${edate}`);
    }
    window.scrollTo(0, 0);
  };

  function formatDate(date: any) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }

  const [unavaliable, setUnavaliable] = useState([]);

  const fetchData = async () => {
    const list: Array<any> = [];
    const q = query(collection(db, "packages"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        if (doc.data().festPakkeType === packageType) {
          list.push({ id: doc.id, ...doc.data() });
        }
      });
      setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  async function checkWhatAvaliable() {
    let availability = await checkAvaliablePackages(loc, sdate, edate);
    if (availability && availability.gearUnavailable && availability.gearUnavailable.length !== 0) {
      setUnavaliable(availability.gearUnavailable);
    }
    return Promise.resolve();
  }

  function filterAvailablePackages() {
    const filteredPackages = data.filter(
      (object) =>
        !unavaliable.includes(object.id) &&
        object.id !== "TestPakke" &&
        object.festPakkeType === packageType
    );
    setAvailablePackages(filteredPackages);
  }
  

  useEffect(() => {
    async function fetchDataAndCheckAvailability() {
      try {
        await fetchData();
        const availability = await checkWhatAvaliable();
        if (availability && availability.gearUnavailable) {
          setUnavaliable(availability.gearUnavailable);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
        setDataLoaded(true);
      }
    }

    fetchDataAndCheckAvailability();
  }, [location, startDate, endDate]);

  useEffect(() => {
    if (dataLoaded) { 
      filterAvailablePackages();
    }
  }, [unavaliable, dataLoaded]);

  return (
    <div className="font-josefin">
      <div className="mx-auto  bg-white overflow-hidden max-w-6xl mb-8">
        <div className="md:flex md:shrink-0">
          <Row md={3} className="g-2">
            <Col md={{ span: 10, offset: 0 }}>
              <div className="pt-8 font-medium text-4xl tracking-wide mt-8">
                {" "}
                Velg pakken som passer best til ditt arrangement!
              </div>
            </Col>
            <Col md={{ span: 10, offset: 0 }}>
              <div className="pt-8 font-light text-md tracking-wide pb-6">
                {" "}
                Den <strong>{formatDate(startDate)}</strong> til{" "}
                <strong>{formatDate(endDate)}</strong> er disse pakkene
                tilgjengelig fra{" "}
                {loc === "lorenskog" ? (
                  <strong>Lørenskog</strong>
                ) : (
                  <>
                    {loc === "asker" ? (
                      <strong>Asker</strong>
                    ) : (
                      <strong>{loc.charAt(0).toUpperCase() + loc.slice(1)}</strong>
                    )}
                  </>
                )}
                .
              </div>
            </Col>
            {loading ? (
              <Col md={{ span: 8, offset: 5 }} className="mb-6 md:mt-36">
                {" "}
                <Loading color={"#e57e22"} />
              </Col>
            ) : (
              <>
                <>
                  {availablePackages.length > 0 ? (
                    availablePackages.map((object) => (
                      <Col>
                        <a
                          href="#!"
                          data-mdb-ripple="true"
                          data-mdb-ripple-color="light"
                        >
                          <Card
                            className="rounded-xl px-4 m-1 py-2 border-2  hover:drop-shadow-xl hover:text-black shadow-md border-gray "
                            style={{ cursor: "pointer" }}
                            onClick={() => navigateToPackage(object.id)}
                          >
                            <Card.Img
                              variant="top"
                              src={object.bilde}
                              style={{
                                width: "100%",
                                height: "30vh",
                                objectFit: "contain",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            />
                            <Card.Body>
                              <Card.Title className="text-3xl tracking-wide font-normal line-clamp-1">
                                {object.id}
                              </Card.Title>
                              <Card.Subtitle className="font-bold pb-2 text-base">
                                Pris fra {object.startPris},-
                              </Card.Subtitle>
                              <Card.Text className="line-clamp-5">
                                {object.beskrivelse}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </a>
                      </Col>
                    ))
                  ) : (
                    <Col md={{ span: 10, offset: 0 }}>
                      {" "}
                      <div className="pt-8 text-md tracking-wide pb-6">
                        Det er dessverre ingen tilgjengelige pakker basert på
                        dine søkekriterier.
                      </div>
                    </Col>
                  )}
                </>
              </>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SearchPackages;
