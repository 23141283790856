import { Col, Row, Container } from "react-bootstrap";
import Demand from "../components/Demand.tsx";
import { collection, DocumentData, getDocs, query } from "firebase/firestore";
import { SetStateAction, useEffect, useState } from "react";
import CreateSupplier from '../components/CreateSupplier.tsx';
import { db } from "../firebase1.ts";
import ModalComp from "../components/ModalComp.tsx";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const AdminDamage = () => {
  const [data, setData] = useState<DocumentData[]>([]);
  const [showModal1, setShowModal1] = useState(false);

  const handleCloseAllModals = () => {
    setShowModal1(false);
  };

  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "demand"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      await setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  // Helper function to convert a date string from "dd.mm.yyyy" to a Date object
  function parseDateFromString(dateString) {
    const [day, month, year] = dateString.split('.');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  // Sort your data array by date in descending order
  const sortedData = data.sort((a, b) => {
    if (a.utstyrUtilgjengelig?.[0]?.dateUnavailable && b.utstyrUtilgjengelig?.[0]?.dateUnavailable) {
      const dateA = parseDateFromString(a.utstyrUtilgjengelig[0].dateUnavailable);
      const dateB = parseDateFromString(b.utstyrUtilgjengelig[0].dateUnavailable);
      return dateB - dateA; // For descending order
    }
    return 0;
  });

  const getGearUnavailableCounts = (data) => {
    const gearCounts = {}; // Object to hold the count of each gearId

    data.forEach((doc) => {
      if (doc.utstyrUtilgjengelig) {
        doc.utstyrUtilgjengelig.forEach((item) => {
          const { gearId } = item;
          if (gearCounts[gearId]) {
            gearCounts[gearId] += 1; // Increment count if already exists
          } else {
            gearCounts[gearId] = 1; // Initialize count if gearId is new
          }
        });
      }
    });

    return gearCounts;
  };

  const gearUnavailableCounts = getGearUnavailableCounts(data);
  const sortedGearCountsArray = Object.entries(gearUnavailableCounts).sort((a, b) => b[1] - a[1]);

  return (
    <div className="font-josefin">
      <Row className="justify-content-md-center mt-10 md:w-11/12 pb-10">
        <Col md={{ span: 11, offset: 2 }} className=" px-4">
          <div className="font-normal text-4xl tracking-wider lg:pb-4">
            {" "}
            Forespørsler på utilgjengelig utstyr{" "} 
            <button onClick={() => { handleCloseAllModals(); setShowModal1(true); }}>
              <AiOutlineQuestionCircle size={20} color="gray" />
            </button>
            {showModal1 && <ModalComp 
              title="Antall forespørsler per utstyr" 
              body={
                <div>
                  {sortedGearCountsArray.map(([gearId, count]) => (
                    <div key={gearId}>
                      <p>{gearId} - <strong>{count}</strong></p>
                    </div>
                  ))}
                </div>
              } 
              closeModal={() => setShowModal1(false)} 
              show={showModal1} 
              hide={handleCloseAllModals} 
            />}
          </div>
          
          <div>
            <Container className="py-2 h-30 lg:px-2">
              <Row className="pl-3">
                <Col xs={2} md={2}>
                  <div className="text-md pt-2 font-bold">Lager</div>
                </Col>
                <Col xs={10} md={2}>
                  <div className="text-md pt-2 font-bold">Utstyr</div>
                </Col>
              </Row>
              {sortedData.map((doc) => (
                <div key={doc.id}> {/* Make sure to have a unique key for each child */}
                  <Demand lager={doc.lager} utstyr={doc.utstyrUtilgjengelig} />
                </div>
              ))}
            </Container>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminDamage;