import { FC } from "react";
import { Button, Card } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { FaWarehouse } from "react-icons/fa";
import { GrStatusUnknown } from "react-icons/gr";
import { GrStatusGood } from "react-icons/gr";
import { GrStatusDisabled } from "react-icons/gr";
import { FaRegComment } from "react-icons/fa";
import { FaTools } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import "../style/carousel.css";
import { useState, useEffect } from "react";

//@ts-ignore
import { db } from "../firebase1.ts";
import { getFunctions, httpsCallable } from "firebase/functions";
//@ts-ignore
import { app } from "../firebase1.ts";

import {
  collection,
  DocumentData,
  getDocs,
  query,
  doc,
  updateDoc,
} from "firebase/firestore";

interface DeliveryRoleCardProps {
  startDate?: any;
  endDate?: any;
  packageType?: string;
  orderId?: any;
  phonenumber?: number;
  confirmedDelivery?:boolean;
  totalPrice?: number;
  deliveryMethod?: string;
  storage?: string;
  storageLocation?: string;
  visibiliety?: string;
  visibilietyComment?: string;
  visibilietyNoComment?: string;
  deliveryServicesDelivery?: string;
  deliveryServicesDeliveryTime?: string;
  deliveryServicesPickup?: string;
  deliveryServicesPickupTime?: string;
  deliveryServicesMontering?: string;
  orderIdLink?: string;
  status?: string;
  supplierComment?: string;
  onClick?: () => void;
  onClickRegnskap?: () => void;
  onClickComment?: () => void;
  setCommentOrderID: (orderIdLink: string) => void;
  setShowComment: (show: boolean) => void;
  setCommentOrder: (supplierComment: string) => void;
}

interface StatusIconProps {
  status: string;
}

const DeliveryRoleCard: FC<DeliveryRoleCardProps> = ({
  startDate,
  endDate,
  deliveryServicesDelivery,
  deliveryServicesDeliveryTime,
  deliveryServicesPickup,
  deliveryServicesPickupTime,
  deliveryServicesMontering,
  visibiliety,
  packageType = "",
  orderId,
  orderIdLink,
  phonenumber,
  confirmedDelivery,
  totalPrice,
  storage = "",
  storageLocation,
  status = "Ikke hentet",
  visibilietyComment,
  visibilietyNoComment,
  onClick,
  onClickRegnskap,
  onClickComment,
  setCommentOrderID,
  setCommentOrder,
  setShowComment,
  supplierComment,
}) => {
  const functions = getFunctions(app, 'europe-west1');
  const deliveryConfirmation = httpsCallable(functions, "sendDeliveryConfirmation");

  const navigate = useNavigate();

  const [hideConfirm, setHideConfirm] = useState(false)
  const [disableConfirm, setDisableConfirm] = useState(false)

  const navigateToOtherSite = (url: string) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  const onOrderConfirmed = async () => {

    setDisableConfirm(true)
    let data = {
      client_reference_id: orderIdLink
    };


    var res = await deliveryConfirmation(data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(error)
    })

    if (orderIdLink) {
      var ref = doc(db, "sales", orderIdLink); 

      const updateObject = {
        [`confirmedDelivery`]: true,
      };

      updateDoc(ref, updateObject)
      .then((e) => {
        confirmedDelivery = true
      })
      .catch((e) => {
        console.log(e);
      });

    }
    

    setHideConfirm(true)
    
    console.error("ordre bekreftet")
    console.error("response" ,res)

  }

  function StatusIcon({ status }: StatusIconProps) {
    if (status === "Ikke hentet") {
      return <GrStatusUnknown className="icon_ikkehentet" size={26} />;
    } else if (status === "Hentet") {
      return <GrStatusDisabled className="icon_hentet" size={26} />;
    } else if (status === "Levert") {
      return <GrStatusGood className="icon_levert" size={26} />;
    }
    return null;
  }

  useEffect(() => {



  }, [] )

  return (
    <div className="tracking-wide font-light ">
      <Col>
        <Card className="px-6 py-3 mb-4 border-2 rounded-xl hover:drop-shadow-xl hover:text-black shadow-md border-gray">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigateToOtherSite(`/order/${orderIdLink}`)}
          >
            <Col className="py-6 px-3">
              <div className="font-medium text-xl tracking-wider pb-4">
                {startDate} - {endDate}
              </div>
              <div>
                <strong className="font-medium">{packageType}</strong> -{" "}
                {orderId} - <strong>fra {storageLocation}</strong>
              </div>
            </Col>
            <hr />
            <Row>
              <Col className="py-6 px-3">
                <div className="flex">
                  <div>
                    <TbTruckDelivery size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {deliveryServicesDelivery} {}
                      {deliveryServicesDeliveryTime ? (
                        <> mellom {deliveryServicesDeliveryTime}</>
                      ) : null}
                    </strong>
                  </div>
                </div>
              </Col>

              {deliveryServicesPickup ? (
                <Col className="py-6 px-3">
                  <div className="flex">
                    <div className="transform scale-x-[-1]">
                      <TbTruckDelivery size={26} />
                    </div>

                    <div className="lg:ml-2 ml-1">
                      <strong className="font-medium text-lg">
                        {deliveryServicesPickup}
                        {deliveryServicesPickupTime ? (
                          <> mellom {deliveryServicesPickupTime}</>
                        ) : null}
                      </strong>
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
            <hr />
      
              {deliveryServicesMontering ? (
                <>
                  <Row>
                    <Col className="py-6 px-3">
                      <div className="flex">
                        <div>
                          <FaTools size={26} />
                        </div>
                        <div className="lg:ml-2 ml-1">
                          <strong className="font-medium text-lg">
                            Inkludert {deliveryServicesMontering}
                          </strong>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                </>
              ) : null}
    
            <Row>
              <Col className="py-6 px-3">
                <div className="flex">
                  <div>
                    <FaWarehouse size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">{storage}</strong>
                  </div>
                </div>
              </Col>
              <Col className="py-6 px-3">
                <div className="flex">
                  <div>
                    <BsFillTelephoneFill size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {phonenumber}
                    </strong>
                  </div>
                </div>
              </Col>
            </Row>
            
            <Row>
              <Col>
            
                <Button
                className="pl-8 pt-2 pb-2 font-normal text-sm tracking-wider text-blue-500 pr-8"
                variant="secondary"
                onClick={ (e) => {
                  e.stopPropagation()
                  onOrderConfirmed()
                }}
                
                disabled={confirmedDelivery}
                >
                  {confirmedDelivery ? "Levering bekreftet" : "Bekreft levering"}
                </Button>
              </Col>

              <Col>
              <Button
  className={`pl-4 pr-4 ${supplierComment ? "text-blue-500" : "text-gray-500"}`}
  variant="secondary"
  onClick={(e) => {
    e.stopPropagation(); // Hindrer at klikket sprer seg
    if (orderIdLink) {
      setCommentOrderID(orderIdLink);
      setShowComment(true);
      setCommentOrder(supplierComment || ""); // Bruker tom streng hvis undefined
    }
  }}
>
  <FaRegComment size={26} />
</Button>
</Col>

            </Row>
          </div>
        </Card>
      </Col>
    </div>
  );
};

export default DeliveryRoleCard;
