// @ts-nocheck
import { Col, Row, Container } from "react-bootstrap";
import Damage from "../components/Damage.tsx";
import { collection, DocumentData, getDocs, query } from "firebase/firestore";
import { SetStateAction, useEffect, useState } from "react";

import { db } from "../firebase1.ts";

const AdminDamage = () => {
  const [data, setData] = useState<DocumentData[]>([]);
  const [sortedVisitors, setSortedVisitors] = useState<DocumentData[]>([]);

  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "damage"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      await setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setSortedVisitors([...data].sort((a, b) => b.tid.seconds - a.tid.seconds));
  }, [data]);
  return (
    <div className="font-josefin">
      <Row className="justify-content-md-center mt-10 md:w-11/12 pb-10">
        <Col md={{ span: 11, offset: 2 }} className=" px-4">
          <div className="font-normal text-4xl tracking-wider lg:pb-4">
            {" "}
            Rapporterte skader{" "}
          </div>
          <div>
            <Container className="py-2 h-30 lg:px-2">
              <Row className="pl-3">
                <Col xs={2} md={2}>
                  <div className="text-md pt-2 font-bold">Lager</div>
                </Col>
                <Col xs={2} md={2}>
                  <div className="text-md pt-2 font-bold">Utstyr</div>
                </Col>
                <Col xs={2} md={2}>
                  <div className="text-md pt-2 font-bold">Beskrivelse</div>
                </Col>
                <Col xs={1} md={1}>
                  <div className="text-md pt-2 font-bold">
                    Kan brukes
                  </div>
                </Col>
                <Col xs={2} md={2}>
                  <div className="text-md pt-2 font-bold">Mobilnummer</div>
                </Col>
                <Col xs={1} md={1}>
                  <div className="text-md pt-2 font-bold">Ordre</div>
                </Col>
                <Col xs={2} md={2}>
                  <div className="text-md pt-2 font-bold">Tidspunkt</div>
                </Col>
              </Row>
              {sortedVisitors.map((doc) => (
                <div>
                  <Damage
                    ordreId={doc.orderId}
                    tlf={doc.tlf}
                    lager={doc.lager}
                    tid={doc.tid.seconds}
                    utstyr={doc.utstyr}
                    beskrivelse={doc.beskrivelse}
                    sms={doc.brukbartUtstyr}
                  />
                </div>
              ))}
            </Container>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminDamage;
