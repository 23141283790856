import { FC } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

interface FakturaProps {
  nameMottakere?: string;
  image?: string;
  desc?: string;
  num?: number;
  totOrderAmount?: number;
  onClick?: () => void;
  orgnumber?: number;
  adress?: string;
  postnumber?: number;
  email?: string;
  phone?: number;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Faktura: FC<FakturaProps> = ({
  nameMottakere = "",
  image = "",
  desc = "",
  orgnumber,
  totOrderAmount,
  onClick,
  handleChange,
  adress = "",
  postnumber,
  num,
  phone,
  email = "",
}) => {
  return (
    <>
      <div className="pb-3 pt-2 font-normal text-2xl tracking-wider whitespace-pre">
        Faktura
      </div>
      <div className="pb-4">
        Faktura sendes på e-post, og forfaller 10 dager etter første lånedato.
        Et fakturagebyr på NOK 45,- kommer i tillegg. Totalbeløp blir{" "}
        <strong>{totOrderAmount},-</strong>.
      </div>
      <Form>
        <div className="text-xs pb-2 text-[#24292e] tracking-widest">
          PERSONALIA
        </div>
        <Row md={2}>
          <Form.Group className="mb-3" controlId="formNavnMottaker">
            <Form.Label>Navn på fakturamottaker</Form.Label>
            <Form.Control
              placeholder="Skriv inn navn "
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formOrgNummer">
            <Form.Label>Org.nr. for bedrift</Form.Label>
            <Form.Control
              placeholder="eksempel 911 111 111"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formAdresse">
            <Form.Label>Adresse</Form.Label>
            <Form.Control
              placeholder="Skriv inn adresse "
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPostNummer">
            <Form.Label>Postnummer</Form.Label>
            <Form.Control
              placeholder="eksempel, 1111"
            />
          </Form.Group>
        </Row>
        <div className="text-xs pb-2 pt-2 text-[#24292e] tracking-widest">
          KONTAKTINFORMASJON
        </div>
        <Row md={2}>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>E-post</Form.Label>
            <Form.Control
              placeholder="Skriv inn epost "
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formMobil">
            <Form.Label>Mobilnummer</Form.Label>
            <Form.Control
              placeholder="eksempel, 911 11 999"
            />
          </Form.Group>
        </Row>
        <Row>
          <Button
            className="text-white bg-[#24292e] mt-3 pt-3 pb-3 ml-2 w-80"
            variant="secondary"
            onClick={onClick}
          >
            SEND FAKTURA
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default Faktura;
