// added package 'react-responsive-carousel' for this component

import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

// import for carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface CarouselProps {
  numberOfCards: number;
  CardTitles: string[];
  CardImages: string[];
  CardIcons: object[];
  CardSubTitles: string[];
  CardBodyText: string[];
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const CustomCarousel: React.FC<CarouselProps> = ({
  numberOfCards,
  CardTitles,
  CardImages,
  CardIcons,
  CardSubTitles,
  CardBodyText,
}) => {
  const [cardList, setCardList] = useState<object[]>([]);

  useEffect(() => {
    for (let i = 0; i < numberOfCards; i++) {
      let id: string = "card" + i;
      let cardTitle: string = CardTitles[i];
      let CardImage: string = CardImages[i];
      let CardIcon: object = CardIcons[i];
      let CardSubTitle: string = CardSubTitles[i];
      let CardText: string = CardBodyText[i];

      setCardList((cardList) => [
        ...cardList,
        { id, cardTitle, CardImage, CardIcon, CardSubTitle, CardText },
      ]);
    }
  }, []);

  return (
    <>
     <Carousel
  responsive={responsive}
  swipeable={true}
  draggable={true}
  ssr={true}
  infinite={true}
  keyBoardControl={true}
  customTransition="transform 3000ms ease-in-out"
  transitionDuration={3000}
  autoPlay={true}
  autoPlaySpeed={3000}
  partialVisbile={true}
  containerClass="carousel-container"
>
  {cardList.map((element) => (
    <div key={element.id}>
      <Card className="rounded-xl pt-3 m-1 mx-2 hover:text-black shadow-md border-gray font-josefin">
        <Card.Img className="aspect-w-3 aspect-h-2 pt-0 object-contain w-full h-12" variant="top" src={element.CardImage} />
        <Card.Body>
          <Card.Title className="text-center font-normal tracking-wide lg:text-xl md:text-lg sm:text-md text-sm">
            {element.cardTitle}
          </Card.Title>
          <Card.Subtitle className="text-center font-bold lg:text-md md:text-sm sm:text-xs">
            {element.CardSubTitle}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </div>
  ))}
</Carousel>
    </>
  );
};

export default CustomCarousel;
