import firebase, { initializeApp } from "firebase/app";
import "firebase/database";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCdyVTYyCPzRwi78_iDNqV_257QsX-PR_Y",
  authDomain: "partylyd2022.firebaseapp.com",
  databaseURL:
    "https://partylyd2022-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "partylyd2022",
  storageBucket: "partylyd2022.appspot.com",
  messagingSenderId: "681089536981",
  appId: "1:681089536981:web:344fe1094124dfa851e35c",
  measurementId: "G-K9Z4JR8W4L",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);


