// @ts-ignore
import {
  collection,
  DocumentData,
  DocumentReference,
  getDocs,
  query,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { SetStateAction, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
// @ts-ignore
import InventoryControlEdit from "../components/InventoryControlEdit.tsx";

// @ts-ignore
import { db } from "../firebase1.ts";

const InventoryControl = () => {
  const [data, setData] = useState<DocumentData[]>([]);

  const [chosenLager, setChosenLager] = useState("");
  const [inventoryChange, setInventoryChange] = useState(0);
  const [inventoryNameChange, setInventoryNameChange] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showFailed, setShowFailed] = useState(false);

  const handleCloseFailed = () => setShowFailed(false);
  const handleShowFailed = () => setShowFailed(true);

  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "gear"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      await setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  async function changeInventoryCountInDB(
    lager: string,
    count: number,
    name: string
  ) {
    const lagerName = lager;
    const lagerCount: Number = count;

    const updateObject = {
      [`kapasitet.${lagerName}`]: Number(lagerCount),
    };
    const gearUpdate = doc(db, "gear", name);

    updateDoc(gearUpdate, updateObject)
      .then((e) => {
        handleShow();
      })
      .catch((e) => {
        handleShowFailed();
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="font-josefin">
      <Row className="mt-10 md:w-11/12 pb-10">
        <Col md={{ span: 11, offset: 1 }} className=" px-4">
          <div className="font-normal text-4xl tracking-wider pb-4">
            {" "}
            Lagerstyring{" "}
          </div>
        </Col>
        <Col md={{ span: 2, offset: 1 }} className="px-4">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setChosenLager(e.target.value)}
          >
            <option>Velg lager</option>
            <option value="trondheim">Trondheim</option>
            <option value="lorenskog">Lørenskog</option>
            <option value="asker">Asker</option>
            <option value="bergen">Bergen</option>
            <option value="vinterbro">Vinterbro</option>
            <option value="stavanger">Stavanger</option>
            <option value="kristiansand">Kristiansand</option>
          </Form.Select>
        </Col>

        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton  className="bg-[#24292e] text-white tracking-wider">
            <Modal.Title>Endring av lager</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Lageret ble endret</p>
          </Modal.Body>
          <Modal.Footer>
          <Button
              onClick={() => window.location.reload()}
              className="lg:mt-0 tracking-wide h-12 "
              style={{
                backgroundColor: "#d3d3d3",
                border: "none",
                fontWeight: 400,
              }}
            >
              Oppdater side for å se endring
            </Button>
            <Button
              className="lg:mt-0 tracking-wide h-12"
              onClick={handleClose}
              style={{
                backgroundColor: "#e57e22",
                border: "none",
                fontWeight: 400,
              }}
            >
              Lukk
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal centered show={showFailed} onHide={handleCloseFailed}>
          <Modal.Header closeButton  className="bg-[#24292e] text-white tracking-wider">
            <Modal.Title>Endring av lager</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Kunne ikke oppdatere ønsket verdi akkurat nå. Vennligst prøv igjen
              senere, eller ta kontakt med teknisk ansvarlig.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="lg:mt-0 mt-3 tracking-wide h-12"
              onClick={handleCloseFailed}
              style={{
                backgroundColor: "#d3d3d3",
                border: "none",
                fontWeight: 400,
              }}
            >
              Lukk
            </Button>
          </Modal.Footer>
        </Modal>

        <div>
          {chosenLager !== "" ? (
            <div className="mt-8 ml-4">
              <>
                <Row>
                  {data.map((item) => {
                    return chosenLager !== "Velg lager" ? (
                      <>
                        <Col lg={{ span: 3, offset: 1 }}>
                          <InventoryControlEdit
                            id={item.id}
                            navn={item.navn}
                            count={item.kapasitet[chosenLager]}
                            bilde={item.bilde}
                            onChange={(e: any) => (
                              setInventoryChange(e.target.value),
                              setInventoryNameChange(item.id)
                            )}
                            onClick={() =>
                              changeInventoryCountInDB(
                                chosenLager,
                                inventoryChange,
                                inventoryNameChange
                              )
                            }
                          />
                        </Col>
                      </>
                    ) : null;
                  })}
                </Row>
              </>
            </div>
          ) : null}
        </div>
      </Row>
    </div>
  );
};

export default InventoryControl;
